import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts

// components
import Page from "../components/Page";
import EstadosFenologicosForm from "../components/forms/EstadosFenologicosForm";
 
import axios from "axios";

//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function Fenologia() {
  const navigate = useNavigate();

  const currentUser= window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");


  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if ( currentUser== null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

  function enviarForm(values) {
  
    if (mySelectedVinedosCuadros.length > 0) {
      var myObjectEstadoFenologico = {
        idUnidadProductiva: selectedUP,
        fechaRegistro: theDate,
        cuadrosAplicados: mySelectedVinedosCuadros,
        estadoFenologico: values.estadoFenologico,
        kilosCosechados: values.kilosCosechados,
        observaciones: values.observaciones,      
      };      

      const request = JSON.parse(JSON.stringify(myObjectEstadoFenologico));
       ;
      axios
        .post(
          ` /api/trabajos/estadoFenologico/nuevo`,
          request
        ).then((response) => {
         if (response.status == "201"){
              setEnviadoExito(true)
              setTimeout(() => {
                setEnviadoExito(false)
              }, 3000);
            }else{
              setEnviadoExito(false)
            }
          }).catch((err) => {
            window.alert("No se pudo establecer la conexión con el servidor")
            setEnviadoExito(false)
          })


      //navigate("/dashboard/app", { replace: true });
    } else {
      // ver que hacer
    }
  }
  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  var mySelectedVinedosCuadros = new Array();


  
  return (
    <RootStyle title="Estados Fenológicos y Cosecha| Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Registrar un nuevo Estado Fenológico
            </Typography>
     
          </Box>

          <EstadosFenologicosForm  
            selectedDate={selectedDate}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}            
            enviadoExito={enviadoExito}
          />
        </ContentStyle>
      </Container>

   
    </RootStyle>
  );
}
