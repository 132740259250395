import { useNavigate } from 'react-router-dom';
import {  useEffect, useState } from "react";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
// components
import Page from "../components/Page";
import CalibracionEquiposForm from "../components/forms/CalibracionEquiposForm";
 
import axios from "axios";
//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function CalibracionEquipos() {
  
  const navigate = useNavigate();
  const currentUser= window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if ( currentUser== null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

  function enviarForm(values) {
  
    
      var myObjectCalibracion = {
        idUnidadProductiva: selectedUP,
        fechaAplicacion: theDate,
        nroTractor: values.nroTractor,
        marcaModeloAnioTractor: values.marcaModeloAnioTractor,
        nroAtomizadora: values.nroAtomizadora,
        marcaModeloAnioAtomizadora: values.marcaModeloAnioAtomizadora,
        capacidadAtomizadora: values.capacidadAtomizadora,
        velocidadKmH: values.velocidadKmH,
        velocidadMmin: values.velocidadMmin,
        presion: values.presion,
        distanciaEntreFilas: values.distanciaEntreFilas,
        volumenLHa: values.volumenLHa,
        gastoI1:values.I1,
        gastoI2:values.I2,
        gastoI3:values.I3,
        gastoI4:values.I4,
        gastoI5:values.I5,
        gastoI6:values.I6,
        gastoI7:values.I7,
        gastoI8:values.I8,

        gastoD1:values.D1,
        gastoD2:values.D2,
        gastoD3:values.D3,
        gastoD4:values.D4,
        gastoD5:values.D5,
        gastoD6:values.D6,
        gastoD7:values.D7,
        gastoD8:values.D8,
        
        observaciones: values.observaciones,
      
      };

      const request = JSON.parse(JSON.stringify(myObjectCalibracion));
       ;
      axios
        .post(
          ` /api/trabajos/CalibracionEquipo/nuevo`,
          request
        ).then((response) => {
          if (response.status == "201"){
               setEnviadoExito(true)
               setTimeout(() => {
                setEnviadoExito(false)
              }, 3000);
             }else{
               setEnviadoExito(false)
             }
            }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
 
 
 
  }
  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  var mySelectedVinedosCuadros = new Array();

  return (
    <RootStyle title="Calibracion de Equipos| Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Registrar una Calibración de Equipo
            </Typography>
     
          </Box>

          <CalibracionEquiposForm  
            selectedDate={selectedDate}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}
            enviadoExito={enviadoExito}
          />
        </ContentStyle>
      </Container>

    </RootStyle>
  );
}
