import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
// components
import Page from "../components/Page";
import MonitoreoForm from "../components/forms/MonitoreoForm";
 
import axios from "axios";
//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function Monitoreos() {
  // ;
  const navigate = useNavigate();
  const currentUser= window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if ( currentUser== null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);


  function enviarForm(values) {

    var myObjectMonitoreo = {
      idUnidadProductiva: selectedUP,
      fechaRegistro: theDate,
      cuadro: values.cuadro,
      vinedo: values.vinedo,
      plaga: values.plaga,
      numeroTrampa: values.trampaNro,     
      nroCapturas: "n/a", 
      observaciones: values.observaciones,
     
    };
    const request = JSON.parse(JSON.stringify(myObjectMonitoreo));
     ;
    axios
      .post(` /api/trabajos/monitoreo/nuevo`, request)
      .then((response) => {
        if (response.status == "201"){
             setEnviadoExito(true)
             setTimeout(() => {
              setEnviadoExito(false)
            }, 3000);
           }else{
             setEnviadoExito(false)
           }
          }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
 


  }

  function enviarForm2(values) {

    var myObjectMonitoreo = {
      idUnidadProductiva: selectedUP,
      fechaRegistro: theDate,
      cuadro: values.cuadro2,
      vinedo: values.vinedo2,
      plaga: values.plaga2,
      numeroTrampa: values.trampaNro2,      
      nroCapturas: values.nroCapturas,
      observaciones: values.observaciones2,
    
    };
    const request = JSON.parse(JSON.stringify(myObjectMonitoreo));
     ;
    axios
      .post(` /api/trabajos/monitoreo/nuevo`, request)
      .then((response) => {
        if (response.status == "201"){
             setEnviadoExito(true)
             setTimeout(() => {
              setEnviadoExito(false)
            }, 3000);
           }else{
             setEnviadoExito(false)
           }
          }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
 
  }

  

  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  var mySelectedVinedosCuadros = new Array();

  return (
    <RootStyle title="Registro de Colocación de Trampas | Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
             Colocación y Monitoreo de Trampas 
            </Typography>
            {/*<Typography sx={{ color: 'text.secondary' }}>
              Free forever. No credit card needed.
</Typography>*/}
          </Box>

          <MonitoreoForm  
            selectedDate={selectedDate}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}
            enviadoExito={enviadoExito}
            enviarForm2={enviarForm2}
          />
        </ContentStyle>
      </Container>

     
    </RootStyle>
  );
}
