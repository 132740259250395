// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import TratamientosSanitariosForm from "../components/forms/TratamientosSanitariosForm";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { Select, Stack } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function TratamientosSanitarios() {
  // ;
  const navigate = useNavigate();
  const [configurarTarea, setConfigurarTarea] = useState();
  const [elegirTarea, setElegirTarea] = useState();
  const [usarTarea, setUsarTarea] = useState([]);
  /* const [tareaActual, setTareaActual] = useState(["normal"]);*/
  var listaTrabajosTarea = [];

  const currentUser= window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if ( currentUser== null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };

  function enviarForm(values) {
    var responses = [];
    if (configurarTarea == "tareaNueva") {
      listaTrabajosTarea.map(function (unaTarea, index) {
        var myObjectTarea = {
          idUnidadProductiva: selectedUP,
          principioActivo: unaTarea.principioActivo,//values["principioActivo" + index],
          nombreComercial:unaTarea.nombreComercial, //values["nombreComercial" + index],
          esperaCosecha : unaTarea.esperaCosecha, // values["esperaCosecha" + index],
         esperaReingreso: unaTarea.esperaReingreso, //values["esperaReingreso" + index],
          plagaEnfermedad: unaTarea.plagaEnfermedad, //values["enfermedad" + index],
          dosisEnCC: unaTarea.dosisEnCC,// values["dosis" + index],
          dosisEnGr: unaTarea.dosisEnGr, //values["dosis" + index],
          idTarea: listaTrabajosTarea[0].idTarea,
          nombreTarea:  listaTrabajosTarea[0].nombreTarea,
        };/*dosisEnCC: "50"
        dosisEnGr: null
        idTarea: "7"
        nombreTarea: "tarea7"
        plagaEnfermedad: "Antracnosis"
        principioActivo: "Azoxystrobin"
        nombreComercial: "AGROSTAR 50 WDG"
        esperaCosecha: "14"*/
     /*   if (values["unidad" + index] == "cc") {
          myObjectTarea.dosisEnGr = "";
        } else {
          myObjectTarea.dosisEnCC = "";
        }*/
        const request = JSON.parse(JSON.stringify(myObjectTarea));
        axios.post(`/api/tareas/tareaTratamiento/nuevo`, request)
        .then((response) => {
          //navigate("/dashboard/app", { replace: true });
          responses.push(response.status);
          console.log(response);
          if (responses.length == listaTrabajosTarea.length) {
            console.log("terminó" + responses);
            if (
              responses.filter((response) => response == "201").length ==
              listaTrabajosTarea.length
            ) {
              setEnviadoExito(true);
              setTimeout(() => {
                setEnviadoExito(false);
              }, 3000);
            } else {
              setEnviadoExito(false);
            }
          }
        }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
 
      });
    } else {
      if (mySelectedVinedosCuadros.length > 0) {
        listaTrabajosTarea.map(function (unaTarea, index) {
          var myObjectTratamientoSanitario = {
            idUnidadProductiva: selectedUP,
            fechaAplicacion: theDate,
            cuadrosAplicados: mySelectedVinedosCuadros,

            principioActivo: unaTarea.principioActivo,
            nombreComercial: unaTarea.nombreComercial,
            esperaCosecha: unaTarea.esperaCosecha,
            esperaReingreso: unaTarea.esperaReingreso,
            plagaEnfermedad: unaTarea.plagaEnfermedad,
            dosisEnCC: unaTarea.dosisEnCC,
            dosisEnGr: unaTarea.dosisEnGr,
            idTarea: unaTarea.idTarea,
            gastoReal: values.gastoReal,
            atomizadora: values.atomizadora,
            picos: values.picos,
            observaciones: values.observaciones,
            idTarea: unaTarea.idTarea || "",
          };
          const request = JSON.parse(
            JSON.stringify(myObjectTratamientoSanitario)
          );
          axios
            .post(`/api/trabajos/tratamientoSanitario/nuevo`, request)
            .then((response) => {
              //navigate("/dashboard/app", { replace: true });
              responses.push(response.status);
              console.log(response);
              if (responses.length == listaTrabajosTarea.length) {
                console.log("terminó" + responses);
                if (
                  responses.filter((response) => response == "201").length ==
                  listaTrabajosTarea.length
                ) {
                  setEnviadoExito(true);
                  setTimeout(() => {
                    setEnviadoExito(false);
                  }, 3000);
                } else {
                  setEnviadoExito(false);
                }
              }
            }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
 
        });
      } else {
        // ver que hacer
      }
    }
  }

  var mySelectedVinedosCuadros = new Array();

  const clickTratamientoNormal = () => {
    setConfigurarTarea(null);
    setElegirTarea(null);
    setUsarTarea([]);
  };
  const clickTareaNueva = () => {
    setConfigurarTarea("tareaNueva");
    setElegirTarea(null);
    setUsarTarea([]);
  };

  const clickUsarTarea = () => {
    const requestTareasTratamientos =
      "/api/tareas/tareaTratamientoPorUP?idUP=" + selectedUP;
    axios.get(requestTareasTratamientos).then(function (response) {
      const newDataPorVinedos = response.data;
      setElegirTarea([...newDataPorVinedos]);
      setConfigurarTarea();
    }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
 
  };
  const seleccionoTarea = (e) => {
    var numTarea = e.target.value.split("-")[0];
    var nombreTarea = e.target.value.split("-")[1];
    var lasTareas = elegirTarea.filter(
      (unaTarea) =>
        unaTarea.idTarea == numTarea && unaTarea.nombreTarea == nombreTarea
    );
    /*elegirTarea=null;*/
    setUsarTarea([...lasTareas]);
    // setTareaSeleccionada(e.)
  };

  var listaNombreTareas = [];
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const armarLista = () => {
    if (elegirTarea) {
      if (elegirTarea.length > 0) {
        var arrayNombres = elegirTarea.map(
          (unaTarea) => unaTarea.idTarea + "-" + unaTarea.nombreTarea
        );
        listaNombreTareas = arrayNombres.filter(onlyUnique);
      }
    }
  };

  armarLista();

  return (
    <RootStyle title="Tratamientos Fitosanitarios| Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Tratamiento Sanitario
            </Typography>
            <Button onClick={clickTratamientoNormal}>
              Registrar Tratamiento Sanitario
            </Button>
            <Button onClick={clickUsarTarea}>Usar Tarea Existente</Button>

            <Button onClick={clickTareaNueva}>Configurar Tarea Nueva</Button>

            {elegirTarea ? (
              <Stack>
                <Select
                  label="Tareas"
                  defaultValue="1"
                  onChange={seleccionoTarea}
                >
                  <MenuItem disabled={true} value={"1"}>
                    {"Seleccione Tarea"}
                  </MenuItem>

                  {listaNombreTareas.map((unaTareaNombre, index) => (
                    <MenuItem key={index} value={unaTareaNombre}>
                      {unaTareaNombre}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            ) : null}
          </Box>
          {
            /*tareaActual.map(function (tarea) {*/
            <TratamientosSanitariosForm
              selectedDate={selectedDate}
              mySelectedVinedosCuadros={mySelectedVinedosCuadros}
              enviarForm={enviarForm}
              /*  tareaActual={tareaActual}*/
              configurarTarea={configurarTarea}
              listaTrabajosTarea={listaTrabajosTarea}
              usarTarea={usarTarea}
              enviadoExito={enviadoExito}
            />
          }
        </ContentStyle>
      </Container>

      <Card sx={{ maxWidth: "40%" }}>
        <Box sx={{ p: 5 }}>
          <Typography variant="body2">
          PERONOSPORA  <br/>
OBLIGATORIO 1:<br/>
Metiram: se permite su aplicación hasta tamaño arveja.
Mancozeb: se permite su aplicación hasta tamaño arveja.
Metalaxil/Cymoxanil/Dimetomorf+Mancozeb: se permite su aplicación hasta tamaño de arveja.
Estrobirulinas (Azoxystrobin y Piraclostrobin): se permite un solo tratamiento por temporada.
OBLIGATORIO 2
Oxicloruro de cobre, óxido cuproso, hidróxido de cobre, sulfato de cobre y caldo bordelés: se permite su aplicación a partir de cuajado.
RECOMENDADO:
Se recomienda su uso, productos sin restricción: Folpet, Folpet+Fosetil aluminio, Cymoxanil, Captan, Dimetomorf+Folpet, Polisulfuro de Calcio, Ametoctradin+Dimetomorf.
<br/><br/>
ANTRACNOSIS <br/>
OBLIGATORIO 1<br/>
Ziram: se permite su aplicación hasta tamaño arveja.
Azoxystrobin: un solo tratamiento por temporada.
Metil tiofanato: único tratamiento hasta la floración inclusive.
RECOMENDADO:
Se recomienda su uso, productos sin restricción: Folpet y Captan.

<br/><br/>
EXCORIOSIS<br/>
OBLIGATORIO 1<br/>
Productos hasta tamaño arveja: Metiram
Estrobirulinas: Azoxystrobin. Se permite una sola aplicación por temporada.

RECOMENDADO:
Se recomienda su uso, productos sin restricción:  Folpet, Captan y Polisulfuro de Ca.
<br/><br/>
OIDIO <br/>
OBLIGATORIO 1<br/>
Medida preventiva: se debe alternar el uso de fungicidas de sitio de acción específica (como son los IBE) con fungicidas multisitio o Estrobirulinas.
Productos con un solo tratamiento por temporada: estrobirulinas (Azoxystrobin, Kresoxim metil, Trifloxystrobin) y SDHI (Pydiflumetofen).
Productos con dos aplicaciones por temporada: IBE (Difenoconazole, Hexaconazole, Tebuconazol, Flutriafol).
RECOMENDADO:
Se recomienda su uso, productos sin restricción: Azufre.
<br/><br/>
BOTRYTIS <br/>
OBLIGATORIO 1<br/>
No se permiten más de 3 aplicaciones de fungicidas específicos entre floración y envero.
Siempre que esté realizado el deshoje y se necesite una 4to tratamiento, se permitirá el mismo a partir de envero.
No se permite repetir el mismo principio activo.
Siempre que estén realizadas todas las prácticas de manejo cultural (deshoje) para el control de Botrytis e igualmente necesitar, se permite realizar un cuarto tratamiento a partir de envero.
Desinfectantes: únicamente Ácido Peracético.

Productos permitidos: Pirimetanil, Pydiflumetofen, Ciprodinil+Fludioxinil, Captan, Iprodione, Boscalid, Boscalid+Pyraclostrobin, Fenhexamid.
<br/><br/>
PODREDUMBRE ÁCIDA <br/>
OBLIGATORIO 1<br/>
Solo se permitirá el uso de Ácido Peracético o Metabisulfito como desinfectante.
RECOMENDADO:
Se recomienda su uso, productos sin restricción: cobre.


<br/><br/>
PLAGAS 
<br/><br/>
LAGARTITA <br/>
OBLIGATORIO 1<br/>
Spinetoram: Sólo se permite su aplicación cuando se detectan picos de captura en trampas o presencia de daño, lo que debe estar justificado en el cuaderno de campo. 
Metoxifenocide: sólo se permite su aplicación cuando se detectan picos de captura en trampas o presencia de daño, lo que debe estar justificado en el cuaderno de campo.
Novaluron: sólo se permite su aplicación cuando se detectan picos de captura en trampas o presencia de daño, lo que debe estar justificado en el cuaderno de campo.
OBLIGATORIO 2
Spinosad: Sólo se permite su aplicación cuando se detectan picos de captura en trampas o presencia de daño, lo que debe estar justificado en el cuaderno de campo. Por su corto tiempo de espera puede ser utilizado en pre-cosecha.
En caso de decidir un tratamiento debe estar justificado debidamente por el técnico asesor.
RECOMENDADOS
Bacillus thurigiensis
Matrine
Raleo de racimos
Eliminación de racimos y sus restos luego de la cosecha.
<br/><br/>
CHANCHITO BLANCO <br/>
OBLIGATORIO 2<br/>
Acetamiprid: se permite una sola aplicación en el periodo vegetativo.
Aceite: sólo se permite su aplicación cuando se detectan picos de captura en trampas o presencia de daño.
Spirotetramat: sólo se permite su aplicación cuando se detectan picos de captura en trampas o presencia de daño.
Buprofezin: sólo se permite su aplicación cuando se detectan picos de captura en trampas o presencia de daño.
En caso de decidir un tratamiento debe estar justificado debidamente por el técnico asesor.
RECOMENDADOS
Control biológico: tecnología de confusión sexual, ver en la norma.

<br/><br/>
ERINOSIS <br/>
OBLIGATORIO 2<br/>
Azufre: en caso de detectarse ataques muy severos.
RECOMENDADOS
Monitoreo visual.

<br/><br/>
MOSCA DEL VINAGRE <br/>
OBLIGATORIO 2<br/>
Spinosad: sólo se permite su aplicación cuando se detectan picos de captura en trampas o presencia de daño, lo que debe estar justificado en el cuaderno de campo
RECOMENDADOS
Utilizar trampeo masivo
Retirar uvas remanentes de cuadros ya cosechados.
<br/><br/>
PERLA DE LA TIERRA <br/>
OBLIGATORIO 1<br/>
Ante la presencia de la plaga consulta al Equipo Técnico de INAVI.
Control químico: solamente en los cuadros donde INAVI autoriza el tratamiento.
Spirotetramat: aplicación foliar en primera quincena de noviembre y en febrero/marzo poscosecha.
Imidacloprid: aplicación al suelo, limpio de malezas, momentos de aplicación iguales a Spirotetramat.








          </Typography>
        </Box>
      </Card>
    </RootStyle>
  );
}
