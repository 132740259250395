import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
// components
import Page from "../components/Page";
import HerbicidasForm from "../components/forms/HerbicidasForm";

import axios from "axios";
//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function Herbicidas() {

  const navigate = useNavigate();
  const currentUser = window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if (currentUser == null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

  function enviarForm(values) {

    if (mySelectedVinedosCuadros.length > 0) {

      var myObjectHerbicidas = []


      var myObjectHerbicida = {
        idUnidadProductiva: selectedUP,
        fechaAplicacion: theDate,
        cuadrosAplicados: mySelectedVinedosCuadros,

        principioActivo: values.principioActivo0,
        nombreComercial: values.nombreComercial0,
        dosisPorHectarea: values.dosisPorHectarea0,

        litrosAguaPorHectarea: values.gasto,
        pulverizadora: values.pulverizadora,
        tipoFicha: values.tipoFicha,
        observaciones: values.observaciones

      };

      myObjectHerbicidas.push({ ...myObjectHerbicida });

      if (values.principioActivo1 != null) {
        myObjectHerbicida.principioActivo = values.principioActivo1;
        myObjectHerbicida.nombreComercial = values.nombreComercial1;
        myObjectHerbicida.dosisPorHectarea = values.dosisPorHectarea1;
        myObjectHerbicidas.push({ ...myObjectHerbicida });
      }


      if (values.principioActivo2 != null) {
        myObjectHerbicida.principioActivo = values.principioActivo2;
        myObjectHerbicida.nombreComercial = values.nombreComercial2;
        myObjectHerbicida.dosisPorHectarea = values.dosisPorHectarea2;
        myObjectHerbicidas.push({ ...myObjectHerbicida });
      }

      if (values.principioActivo3 != null) {
        myObjectHerbicida.principioActivo = values.principioActivo3;
        myObjectHerbicida.nombreComercial = values.nombreComercial3;
        myObjectHerbicida.dosisPorHectarea = values.dosisPorHectarea3;
        myObjectHerbicidas.push({ ...myObjectHerbicida });
      }


      if (values.principioActivo4 != null) {
        myObjectHerbicida.principioActivo = values.principioActivo4;
        myObjectHerbicida.nombreComercial = values.nombreComercial4;
        myObjectHerbicida.dosisPorHectarea = values.dosisPorHectarea4;
        myObjectHerbicidas.push({ ...myObjectHerbicida });
      }

      const request = JSON.parse(JSON.stringify(myObjectHerbicida));


      axios
        .all(myObjectHerbicidas.map((data) => axios.post(
          ` /api/trabajos/Herbicida/nuevo`,
          data
        )))
        .then((arrayDeResponses) => {
          var unenviadoExito = true
          arrayDeResponses.forEach((response) => {
            if (response.status == "201") {
              unenviadoExito = true
            } else {
              throw ("Error al guardar los datos")
            }
          })

          if (unenviadoExito) {
            setEnviadoExito(true)
            setTimeout(() => {
              setEnviadoExito(false)
            }, 3000);
          } else {
            setEnviadoExito(false)
          }
        }).catch((err) => {
          window.alert("No se pudo establecer la conexión con el servidor")
          setEnviadoExito(false)
        })


    }
  }
  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  var mySelectedVinedosCuadros = new Array();

  return (
    <RootStyle title="Herbicidas| Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Registrar una nueva aplicación de Herbicida
            </Typography>

          </Box>

          <HerbicidasForm
            selectedDate={selectedDate}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}
            enviadoExito={enviadoExito}
          />
        </ContentStyle>
      </Container>
      <Card sx={{ maxWidth: "40%" }}>
        <Box sx={{ p: 5 }}>
          <Typography variant="body2">
            OBLIGATORIO 1<br/>
            Se prohíbe el uso de Paraquat
            Solo se permiten los siguientes herbicidas post-emergentes: Glufosinato de Amonio, Glifosato y graminicidas.
            Rimsulfuron: una aplicación por temporada
            MCPA o Fluroxipir: una aplicación por temporada, entre junio y agosto.
            Flazasulfuron: una aplicación por temporada.
            Malezas invasoras: ante problema de malezas invasoras (Pasto bolita, etc.), se debe solicitar recomendaciones de manejo al Comité Técnico.
            Flumioxazim: una aplicación por temporada, en pre brotación.
            Indaziflam: una aplicación por temporada.
          </Typography>
        </Box>
      </Card>

    </RootStyle>
  );
}
