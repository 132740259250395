/* global window, document, Blob */
import React from 'react';
import {Button} from '@mui/material'




export default function MyTableToExcel(props) {

    const { table, filename, sheet, id, className, buttonText } = props;

    console.log("sheet" + JSON.stringify(sheet))

    const base64 = (s) => {
        return window.btoa(unescape(encodeURIComponent(s)));
    }

    const format = (s, c) => {
        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    }




    
    const handleDownload = () => {
        if (!document) {
            if (process.env.NODE_ENV !== 'production') {
                console.error('Failed to access document object');
            }

            return null;
        }

        if (document.getElementById(table).nodeType !== 1 || document.getElementById(table).nodeName !== 'TABLE') {
            if (process.env.NODE_ENV !== 'production') {
                console.error('Provided table property is not html table element');
            }

            return null;
        }

        const tableData = document.getElementById(table).outerHTML;
        const sheetData = String(sheet);
        const filenameData = `${String(filename)}.xls`;

        const uri = 'data:application/vnd.ms-excel;base64,';
        const template =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
            'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
            'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
            'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
            '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
            'xml><![endif]--></head><body>{tableData}</body></html>';

        const context = {
            worksheet: sheetData || 'Worksheet',
            tableData,
        };

        // If IE11
        if (window.navigator.msSaveOrOpenBlob) {
            const fileData = [
                `${'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' + 'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' + 'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' + 'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' + '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' + 'xml><![endif]--></head><body>'}${tableData}</body></html>`,
            ];
            const blobObject = new Blob(fileData);
            document.getElementById('react-html-table-to-excel').click()(() => {
                window.navigator.msSaveOrOpenBlob(blobObject, filenameData);
            });

            return true;
        }

        const element = window.document.createElement('a');
        element.href =  uri ;
        console.log("element.href" + element.href)
       var auxi = (format(template, context));
        console.log("elef" + auxi)  
        auxi = base64(auxi);
        console.log("elef" + auxi)
        element.href += auxi;
        console.log("element.href" + element.href)


            
        element.download = filenameData;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        return true;
    }


    return (
        <Button
            id={id}
            className={className}
            type="button"
            onClick={handleDownload}
        >
            {buttonText}
        </Button>
    );


}

