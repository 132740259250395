import { useState } from "react";
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VinedosCuadrosSelector from "./VinedosCuadrosSelector";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Radio } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------
//React.forwardRef(({ onClick, href }, ref) => {
export default function EstadosFenologicosForm({
  vinedosUnidad,
  selectedDate,
  mySelectedVinedosCuadros,
  enviarForm,
  enviadoExito
}) {
  //vinedos unidad no está funcionando
  
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting } = useForm();
  const [result, setResult] = useState("");
  const [cosecha, setCosecha] = useState(false);

  const [enviando, setEnviando] = useState(false);


  const onSubmit = (data) => {
    if (!enviando) {
    if (!mySelectedVinedosCuadros.length > 0) {
      window.alert("debe seleccionar al menos un cuadro");
    } else {
      enviarForm(data);
      setEnviando(true);
    }
  }
  }; //setResult el que cambia el state
  const estadosFenologicos = [
    "INICIO DE BROTACIÓN",
    "PLENA FLOR",
    "CUAJADO",
    "GRANO ARVEJA",
    "CIERRE RACIMO",
    "ENVERO",
    "COSECHA",
  ];

  const checkCosecha = (e) => {
    if (e.target.dataset.value == "COSECHA") {
      setCosecha(true);
    } else {
      setCosecha(false);
    }
  };

  const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
      setTimeout(() => {
        navigate(`/dashboard/Fenologia`, { replace: true });
        reset()
      }, 3000);
    }
  };
  envio();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <DatePickerField selectedDate={selectedDate} />
          <Select
            label="EstadoFenologico"
            defaultValue="1"
            {...register("estadoFenologico")}
          >
            <MenuItem disabled={true} value={"1"}>
              {"Seleccione un Estado Fenológico"}
            </MenuItem>
            {estadosFenologicos.map((enEstado, index) => (
              <MenuItem key={index} value={enEstado} onClick={checkCosecha}>
                {enEstado}
              </MenuItem>
            ))}
          </Select>
        </Stack>

        {cosecha ? (
          <TextField
            fullWidth
            type={"number"}
            label="Kilos Cosechados"
            {...register("kilosCosechados")}
            name="kilos"
            required={true}
            // validateStatus={errors && errors.picos ? "error" : "success"}
            help={
              errors && errors.kilos ? "Este campo no puede estar vacío" : ""
            }
            hidden
          />
        ) : null}

        <TextField
          fullWidth
          label="Observaciones"
          {...register("observaciones")}
          placeholder="observaciones"
          required={false}
          // validateStatus={errors && errors.observaciones ? "error" : "success"}
          help={
            errors && errors.observaciones
              ? "Este campo no puede estar vacío"
              : ""
          }
        />
        {/* */}
        <VinedosCuadrosSelector
          vinedosUnidad={vinedosUnidad}
          mySelectedVinedosCuadros={mySelectedVinedosCuadros}
          // ref={(input) => { this.VinCuadSel = input; }}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={enviando}
        >
          {!enviadoExito ? "Guardar" : "Datos Guardados"}
        </LoadingButton>
      </Stack>
    </form>
  );
}
