
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import LoginAdminForm from "../components/authentication/login/LoginAdminForm";
import { Button } from "@mui/material";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));



// ----------------------------------------------------------------------

export default function LoginAdmin() {

  return (
    <RootStyle title="Login | ADMIN" sx={{ backgroundColor: "" }}>
      {/* componente que pregunta si tienes cuenta y te lleva a registarte
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get started
        </Link>
      </AuthLayout> */}

      {/* Componente de la imagen lateral */}
      <MHidden width="mdDown" sx={{ backgroundColor: "" }}>

        <SectionStyle sx={{ backgroundColor: "" }}>

          <img src="/static/illustrations/mariposa.gif" alt="tannat" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm" >
       
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
          <SectionStyle sx={{ display: { xl: 'none', xs: 'block' , md: 'none'} }} >

<img src="/static/illustrations/mariposa.gif" alt="tannat" />
</SectionStyle>

            <Typography variant="h4" gutterBottom align="center">
              Bienvenidas al Tablero de <br></br> Administración del Cuaderno Digital
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {'Ingresa tu usuaria y contraseña para acceder al tablero de Administrador'}
            </Typography>
          </Stack>
          {/* Barra de medios sociales}
          <AuthSocial /> */}

          <LoginAdminForm />
          {/*<Typography variant="h5" align="center" sx={{ mt: 3, fontStyle: "italic" }}>
            “Ningún país puede florecer realmente si ahoga el potencial de sus mujeres y se priva de las contribuciones de la mitad de sus ciudadanos.”
          </Typography>
          <br></br>
        <Typography align="center" variant="h6">Michelle Obama</Typography>*/}


        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
