import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";

function createData(
  nroInterno,
  nombreCuadro,
  nombreUva,
  codigoUva,
  nombrePortaInjerto,
  nombreSistema,
  superficie,
  añoSiembra,
  distanciaFila,
  distanciaPlantas,
  edadPlantas,
  nombreOrigen,
  cantidadPlantas,
  riego
) {
  return {
    nroInterno,
    nombreCuadro,
    nombreUva,
    codigoUva,
    nombrePortaInjerto,
    nombreSistema,
    superficie,
    añoSiembra,
    distanciaFila,
    distanciaPlantas,
    edadPlantas,
    nombreOrigen,
    cantidadPlantas,
    riego,
  };
}

export default function TablaVinedo({ unVinedo }) {
  const [cuadrosVinedo, setCuadrosVinedo] = useState(unVinedo.cuadros);

  const [unaTablaVinedo, setTablaVinedo] = useState(
    cuadrosVinedo.map((cuadro) =>
      createData(
        cuadro.nroInterno ? cuadro.nroInterno : cuadro.nombreCuadro,
        cuadro.nombreCuadro,
        cuadro.nombreUva,
        cuadro.codigoUva,
        cuadro.nombrePortaInjerto,
        cuadro.nombreSistema,
        cuadro.superficie,
        cuadro.añoSiembra,
        cuadro.distanciaFila,
        cuadro.distanciaPlantas,
        cuadro.edadPlantas,
        cuadro.nombreOrigen,
        cuadro.cantidadPlantas,
        cuadro.riego ? cuadro.riego : "no"
      )
    )
  );

  const [editando, setEditando] = useState(false);

  function guardarCambios() {
    if (unaTablaVinedo.length > 0) {
      var miRegistroNuevo = {
        nroInscripcion: unVinedo.numeroInscripcion,
        cuadros: unaTablaVinedo,
      };

      const request = JSON.parse(JSON.stringify(miRegistroNuevo));

      axios
        .post(` /api/vinedos/modificarNroCuadroInterno/`, request)
        .then((response) => {
          if (response.status == "201") {
          window.alert("Números Internos Guardados")
          } else {
            
          window.alert("No se pudo guardar los cambios")
          }
        }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
    } else {
      // ver que hacer
    }
  }

  const clickEditar = () => {
    if (editando) {
      console.log(unaTablaVinedo);
      setTablaVinedo(unaTablaVinedo);
      setEditando(false);
      guardarCambios();
    } else {
      setEditando(true);
    }
  };
  const changeValueInterno = (row) => (event) => {
    row.nroInterno = event.target.value;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "auto" }}>
      <LoadingButton onClick={clickEditar}>
        {" "}
        {editando ? "Guardar Nros Internos" : "Editar Nros Internos"}
      </LoadingButton>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 350 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead stickyheader={1}>
            <TableRow
              sx={{ minWidth: 350 }}
              size="small"
              aria-label="a dense table"
            >
              <TableCell align="center">Nro Cuadro Interno</TableCell>
              <TableCell align="center">Nro Cuadro INAVI</TableCell>
              <TableCell align="center">Variedad</TableCell>
              <TableCell align="center">Código Variedad</TableCell>
              <TableCell align="center">Portainjerto</TableCell>
              <TableCell align="center">Sistema Conducción</TableCell>
              <TableCell align="center">Superficie</TableCell>
              <TableCell align="center">Año Siembra</TableCell>
              <TableCell align="center">Distancia Filas</TableCell>
              <TableCell align="center">Distancia Plantas</TableCell>
              <TableCell align="center">Edad Plantas</TableCell>
              <TableCell align="center">Nombre Origen</TableCell>
              <TableCell align="center">Cantidad de Plantas</TableCell>
              <TableCell align="center">Riego</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unaTablaVinedo.map((row, index) => (
              <TableRow
                key={index + row.nombreCuadro}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">
                  {editando ? (
                    <TextField
                      name={index + row.nombreCuadro}
                      type={"text"}
                      placeholder={row.nroInterno}
                      size="small"
                      onChange={changeValueInterno(row)}
                      inputProps={{
                        style: {
                          paddingTop: 2.5,
                          paddingBottom: 2.5,
                          paddingLeft: 6.5,
                          paddingRight: 6.5,
                          fontSize: "0.8em",
                        },
                      }}
                    />
                  ) : (
                    row.nroInterno
                  )}
                </TableCell>
                <TableCell align="center">{row.nombreCuadro}</TableCell>
                <TableCell align="center">{row.nombreUva}</TableCell>
                <TableCell align="center">{row.codigoUva}</TableCell>
                <TableCell align="center">{row.nombrePortaInjerto}</TableCell>
                <TableCell align="center">{row.nombreSistema}</TableCell>
                <TableCell align="center">{row.superficie}</TableCell>
                <TableCell align="center">{row.añoSiembra}</TableCell>
                <TableCell align="center">{row.distanciaFila}</TableCell>
                <TableCell align="center">{row.distanciaPlantas}</TableCell>
                <TableCell align="center">{row.edadPlantas}</TableCell>
                <TableCell align="center">{row.nombreOrigen}</TableCell>
                <TableCell align="center">{row.cantidadPlantas}</TableCell>
                <TableCell align="center">{row.riego}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
