import { useState } from "react";
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VinedosCuadrosSelector from "./VinedosCuadrosSelector";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function FertilizantesForm({
  vinedosUnidad,
  selectedDate,
  mySelectedVinedosCuadros,
  enviarForm,
  enviadoExito
}) {
  //vinedos unidad no está funcionando
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting } = useForm();
  const [result, setResult] = useState("");
  
  const [enviando, setEnviando] = useState(false);

  const onSubmit = (data) => {
    if (!enviando) {
    if (!mySelectedVinedosCuadros.length > 0) {
      window.alert("debe seleccionar al menos un cuadro");
    } else {
      enviarForm(data);
      setEnviando(true);
    }
  }
  }; //setResult el que cambia el state


  const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
      setTimeout(() => {
        navigate(`/dashboard/Fertilizante`, { replace: true });
        reset()
      }, 3000);
    }
  };
  envio();


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <DatePickerField selectedDate={selectedDate} />

          <TextField
            fullWidth
            label="Fertilizante"
            {...register("fertilizante")}
            placeholder="Fertilizante"
            required={true}
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors && errors.fertilizante
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
          <TextField
            fullWidth
            type={"number"}
            label="Kg/Há"
            {...register("kgha")}
            placeholder="Kg/Há"
            required={true}
            // validateStatus={errors && errors.nombreComercial ? "error" : "success"}
            help={
              errors && errors.kgha ? "Este campo no puede estar vacío" : ""
            }
          />
                   <TextField
            fullWidth
            label="Método"
            {...register("metodo")}
            placeholder="Método"
            required={true}
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors && errors.metodo
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        </Stack>
        <Stack spacing={3}>
          <TextField
            multiline
            maxRows={6}
            label="Observaciones"
            {...register("observaciones")}
            placeholder="observaciones"
           
            // validateStatus={errors && errors.observaciones ? "error" : "success"}
            help={
              errors && errors.observaciones
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        </Stack>
        {/* */}
        <VinedosCuadrosSelector
          vinedosUnidad={vinedosUnidad}
          mySelectedVinedosCuadros={mySelectedVinedosCuadros}
        />
         <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={enviando}
        >
           {!enviadoExito ?
          "Guardar Fertilizantes" : "Datos Guardados"}
        </LoadingButton>  
      </Stack>
    </form>
  );
}
