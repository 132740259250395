import { useState, useEffect } from "react";
import axios from "axios";
import TablaVinedo from "./TablaVinedo";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import { Card } from "@mui/material";

const TablasVinedos = (props) => {

  const currentUser = window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");


  const [vinedos, setVinedos] = useState([]);

  const getVinedos = () =>{
    const api = axios
    .get(` /api/vinedos/vinedosPorIdUP/${selectedUP}`)
    .then((response) => {
      var vinedos = response.data;
      var nuevosVinedos = [];
      vinedos.map((unVinedo) => {
        unVinedo.cuadros.sort(function (a, b) {
          return a.nombreCuadro - b.nombreCuadro;
        });
      })

      vinedos.map((unVinedo) => {
        var nuevoVinedo = { ...unVinedo };
        nuevoVinedo.cuadros = [];
        nuevoVinedo.cuadros.push(unVinedo.cuadros[0]);
        for (let i = 1; i < unVinedo.cuadros.length; i++) {
          if (
            (unVinedo.cuadros[i].nombreCuadro !=
              nuevoVinedo.cuadros[nuevoVinedo.cuadros.length - 1].nombreCuadro) ||
            (unVinedo.cuadros[i].nombreUva !=
              nuevoVinedo.cuadros[nuevoVinedo.cuadros.length - 1].nombreUva)
          ) {
            nuevoVinedo.cuadros.push(unVinedo.cuadros[i]);
          }
        }
        nuevosVinedos.push(nuevoVinedo);
      })
      window.localStorage.setItem("vinedos", JSON.stringify([...nuevosVinedos]));
      setVinedos(nuevosVinedos)
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"));

  }
  useEffect(() => {
  getVinedos();
  })

  return (
    <Box>
      <Grid justifyContent="center" container>
        {vinedos.map((unVinedo, index) => (
          <Card sx={{ m: 2, p: 4 }} key={index + unVinedo.numeroInscripcion}>
            <div mx="auto">
              <Box sx={{ mb: 2 }}>
                <h4>
                  Viñedo Nro: {unVinedo.numeroInscripcion}, {unVinedo.ubicacion},
                  {unVinedo.departamento}
                </h4>
              </Box>
              <Box>
                <TablaVinedo unVinedo={unVinedo} />
              </Box>
            </div>
          </Card>
        ))}
      </Grid>
    </Box>
  );
};

export default TablasVinedos;
