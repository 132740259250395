import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
const Estilo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function ListaUP(props) {
  const navigate = useNavigate();
  const { unidades } = props;
  const currentUser= window.localStorage.getItem("user");

 
  const handleClick = (id) => {
    window.localStorage.setItem("UP", id);

    console.log("elige"+ id)
    const api = axios
      .get(` /api/vinedos/vinedosPorIdUP/${id}`)
      .then((response) => {
        var vinedos = response.data;
        console.log("volvió" +response.data)
        var nuevosVinedos = [];
        vinedos.map((unVinedo) => {
          unVinedo.cuadros.sort(function (a, b) {
            return a.nombreCuadro - b.nombreCuadro;
          });
        });

        vinedos.map((unVinedo) => {
          var nuevoVinedo = { ...unVinedo };
          nuevoVinedo.cuadros = [];
          nuevoVinedo.cuadros.push(unVinedo.cuadros[0]);
          for (let i = 1; i < unVinedo.cuadros.length; i++) {
            if (
              (unVinedo.cuadros[i].nombreCuadro !=
                nuevoVinedo.cuadros[nuevoVinedo.cuadros.length - 1].nombreCuadro) ||
              (unVinedo.cuadros[i].nombreUva !=
                nuevoVinedo.cuadros[nuevoVinedo.cuadros.length - 1].nombreUva)
            ) {
              nuevoVinedo.cuadros.push(unVinedo.cuadros[i]);
            }
          }
          nuevosVinedos.push(nuevoVinedo);
        })
        window.localStorage.setItem("vinedos", JSON.stringify([...nuevosVinedos]));
        navigate(`/dashboard`, { replace: true });
      }).catch((err) =>{
          window.alert("No se pudo establecer la conexión con el servidor")
        console.log(err)
        })
  };
  /*

  listaNombreTareas = arrayNombres.filter(onlyUnique);


function onlyUnique(value, index, self) {
return self.indexOf(value) === index;
}
*/


var ups;

if (unidades[0]) {
 ups= unidades[0].split(",");
}

if ( ups.length ==1){

  handleClick(ups[0])
}

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Grid item xs={12} md={6}>

      {console.log(unidades)}
        <Select label="listaUPS" defaultValue="1">
          <MenuItem disabled={true} value={"1"}>
            {"Seleccione una Unidad Productiva"}
          </MenuItem>
      
          {ups.map((id, index) => (
            <MenuItem key={index} value={id}  onClick={() => {
                  handleClick(id);
                }}>
              {id}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Box>
  );
}
