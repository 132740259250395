import { useState, useEffect } from "react";
import axios from "axios";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts

// components
import Page from "../Page";
import { MHidden } from "../@material-extend";
import ListaUP from "./ListaUp";
import { useNavigate } from 'react-router-dom';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

export default function SeleccionUP() {
  
  const [unidades, setUnidades] = useState([window.localStorage.getItem("listaUPs")]);
  const navigate = useNavigate();
  

const currentUser= window.localStorage.getItem("user");

useEffect(() => {
  console.log("useEFFECT")
  if ( currentUser== null || unidades==null) {
    navigate(`/login`, { replace: true });
  } 
}, []);
  
 /* window.localStorage.setItem("listaUPs", response.data[0]);**/

  if(unidades==null){
    navigate(`/login`, { replace: true });
  }
  return (
    <RootStyle title="Login | Cuaderno Digital Vitícola">
      {/* Componente de la imagen lateral */}
      <MHidden width="mdDown">
        <SectionStyle>
          <img src="/static/illustrations/logo.png" alt="tannat" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Bienvenido al Cuaderno Digital Vitícola 
              Seleccione una Unidad Productiva
            </Typography>
          </Stack>
          {unidades? <ListaUP unidades={unidades} /> : null}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
