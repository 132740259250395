import { useState, useEffect } from "react";
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Link as RouterLink, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
axios.defaults.timeout === 5000;
export default function LoginForm() {
  const navigate = useNavigate();
  const [titulares, setTitulares] = useState([]);
  const { register, handleSubmit, errors, isSubmitting } = useForm();
  const [result, setResult] = useState("");

  const onSubmit1 = (values) => {
    const datos = { user: values.user, password: values.password }
    /* console.log(datos)*/

    try {
      axios
        .post(
          `api/unidadProductiva/UpPorUser/`, datos
        )
        .then((response) => {

          if (response.status == "200") {
            window.localStorage.setItem("user", values.user);
            /* console.log(response)*/
            window.localStorage.setItem("listaUPs", response.data);
            console.log(response.data)
            navigate(`/unidades-productivas`, { replace: true });
          } else {

            /*console.log(response)*/
            window.alert("No existe el usuario");
          }
        }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
    } catch (error) {
      console.error(error.response.data);

     ;// NOTE - use "error.response.data` (not "error")
    }



  }; //setResult el que cambia el state

  const getTitulares = () => {
    const api = axios
      .get(`api/unidadProductiva/todos`)
      .then((response) => {
        const ruts = [];
        response.data.map((unTitularTotal) => {
          ruts.push(unTitularTotal.id);
        });
        setTitulares(ruts);
      });
  };

  useEffect(() => {
    /*  getTitulares();*/
  });

  return (
    <Stack sx={{ mb: 6 }}>
      <Box>
        <form onSubmit={handleSubmit(onSubmit1)}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type={"text"}
              label="usuario"
              {...register("user")}
              placeholder="usuario"
              required={true}
              //validateStatus={errors  ? "error" : "success"}
              help={
                errors 
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />
            { /*<Select label="titularesRUT" defaultValue="1" {...register("RUT")}>
              <MenuItem disabled={true} value={"1"}>
                {"Seleccione un RUT"}
              </MenuItem>
              {titulares.map((myRUT, index) => (
                <MenuItem key={index} value={myRUT}>
                  {myRUT}
                </MenuItem>
              ))}
              </Select> */}

            <TextField
              fullWidth
              type={"password"}
              label="contraseña"
              {...register("password")}
              placeholder="contraseña"
              required={true}
              //validateStatus={errors  ? "error" : "success"}
              help={
                errors 
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Ingresar
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
}
