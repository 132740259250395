import { Box, Grid, Container, Typography, Button } from "@mui/material";
// components
import { useState, useEffect } from "react";
import ReportesPorVinedo from "src/components/reportes/ReportesPorVinedo";
import axios from "axios";
import React from "react";
import { Card } from "@mui/material";
import { CardHeader } from "@mui/material";
import { Divider } from "@mui/material";
import { CardContent } from "@mui/material";
import { Paper } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

export default function ResumenActividades() {
  const [listaActividades, setListaActividades] = useState([]);
  const navigate = useNavigate();
  const currentUser = window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const requestTratamientosSanitarios = {
    urlGet: "/api/reportes/tratamientosSanitariosPorUP?idUP=" + selectedUP,
    tipo: "Tratamientos Fitosanitarios",
  };
  const requestHormigas = {
    urlGet: "/api/reportes/hormigasPorUP?idUP=" + selectedUP,
    tipo: "Control Hormigas",
  };
  const requestHerbicidas = {
    urlGet: "/api/reportes/herbicidasPorUP?idUP=" + selectedUP,
    tipo: "Control de Malezas",
  };
  const requestFertilizantes = {
    urlGet: "/api/reportes/fertilizantesPorUP?idUP=" + selectedUP,
    tipo: "Fertilizantes",
  };
  const requestIndiceEquilibrio = {
    urlGet: "/api/reportes/indiceEquilibrioPorUP?idUP=" + selectedUP,
    tipo: "Índice de Equilibrio",
  };
  const requestCalibracionEquipos = {
    urlGet: "/api/reportes/calibracionEquipoPorUP?idUP=" + selectedUP,
    tipo: "Calibración de Equipos",
  };
  const requestInformes = {
    urlGet: "/api/reportes/informeTecnicoPorUP?idUP=" + selectedUP,
    tipo: "Informes",
  };
  const requestFenología = {
    urlGet: "/api/reportes/fenologiasPorUP?idUP=" + selectedUP,
    tipo: "Fenología",
  };
  const requestManejos = {
    urlGet: "/api/reportes/manejosPorUP?idUP=" + selectedUP,
    tipo: "Manejos",
  };
  const requestMonitoreo = {
    urlGet: "/api/reportes/monitoreosPorUP?idUP=" + selectedUP,
    tipo: "Monitoreo",
  };
  const requestLluviayRiego = {
    urlGet: "/api/reportes/lluviaYRiegoPorUP?idUP=" + selectedUP,
    tipo: "Lluvia y Riego",
  };

  const allRequests = [
    requestTratamientosSanitarios,
    requestHormigas,
    requestHerbicidas,
    requestFertilizantes,
    requestFenología,
    requestManejos,
    requestMonitoreo,
    requestLluviayRiego,
    requestIndiceEquilibrio,
    requestCalibracionEquipos,
    requestInformes,
  ];

  useEffect(() => {
    if (currentUser == null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
    traerDatos();
  }, []);

  const traerDatos = async () => {
    var laData = [];
    axios
      .all(allRequests.map((request) => axios.get(request.urlGet)))
      .then((arrayDeResponses) => {

        arrayDeResponses.map((cadaRespDeUnReq, index) => {
          var miTipo = allRequests[index].tipo;
          console.log(miTipo)
          console.log(cadaRespDeUnReq.data)
          cadaRespDeUnReq.data.map((cadaActividad) => {
            var miActividad = {
              id: cadaActividad._id,
              estado: cadaActividad.estado ? cadaActividad.estado : "vigente",
              fecha: cadaActividad.fechaAplicacion
                ? cadaActividad.fechaAplicacion
                : cadaActividad.fechaRegistro
                  ? cadaActividad.fechaRegistro
                  : cadaActividad.createdAt ? cadaActividad.createdAt : cadaActividad.dateNow ? cadaActividad.dateNow : "1985-01-01T18:18:38.973Z",
              tipo: miTipo,
              cuadros: cadaActividad.cuadrosAplicados
                ? cadaActividad.cuadrosAplicados
                : [],
              detalles: [],
            };
            console.log(miActividad.fecha)
            var laFechaAuxQueDificilDiosMio = new Date(miActividad.fecha)
            if (laFechaAuxQueDificilDiosMio == "Invalid Date") {

              laFechaAuxQueDificilDiosMio = Number(miActividad.fecha);
              laFechaAuxQueDificilDiosMio = new Date(laFechaAuxQueDificilDiosMio)


            }
            miActividad.fecha = laFechaAuxQueDificilDiosMio //new Date(miActividad.fecha);

            Object.entries(cadaActividad).forEach(([key, value]) => {
              if (
                key != "cuadrosAplicados" &&
                key != "fechaRegistro" &&
                key != "fechaAplicacion" &&
                key != "_id" &&
                key != "__v" &&
                key != "idUnidadProductiva"
              ) {

                key = key.replace(/([a-z])([A-Z])/g, '$1 $2')
                key = key.charAt(0).toUpperCase() + key.slice(1)
                miActividad.detalles.push({
                  nombre: key,
                  valor: value ? value : "",
                });
              }
            });

            laData.push(miActividad);
          });
        });

        laData.sort((a, b) => {
          var dateA = new Date(a.fecha);
          var dateB = new Date(b.fecha);
          return dateB - dateA;
        });

        laData.forEach((miActividad) => {
          miActividad.fecha = miActividad.fecha = new Intl.DateTimeFormat(
            "es-ES",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          ).format(miActividad.fecha);
        });

        setListaActividades(laData);
      });
  };

  const anularRegistro = (tipo, id) => {

    axios
      .post(`/api/trabajos/anularRegistro`, { tipo: tipo, id: id })
      .then((res) => {
        console.log(res.data);
        traerDatos();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const clickAnularRegistro = (tipo, id) => {
    var comentaYacepta = prompt("Para anular este registro, ingrese un comentario y presione Aceptar", "Observaciones");
    if (comentaYacepta != null && comentaYacepta != "") {
      anularRegistro(tipo, id)
    } else {

    }

  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
        gap: 2,
        overflowY: "scroll",
        justifyContent: "flex-end"
      }}
    >
      {listaActividades.map((actividad, index) => (
        <Card size="lg" variant="outlined"
          key={actividad.tipo + index + Math.random()}
          sx={{ mb: 2, maxWidth: 800, padding: 2, ...((actividad.estado == "anulado")? { backgroundColor: 'lightGrey'} :{} ) }}


        >
          <CardContent>
            <PerfectScrollbar>
              <Box>


                <Typography variant="h6" sx={{ ...((actividad.estado == "anulado")? { color:'grey'} : { color: '#228936'} ) }} paragraph key={"vinedo" + index + Math.random()}>

                  {actividad.tipo + " - " + actividad.fecha}
                </Typography>

                <Divider inset="none" />

                <Stack mt={3}>
                  {actividad.cuadros?.map((cuadro, indx) => (
                    <div key={Math.random() + "vinedoCuadro" + index}>
                      <Typography variant="body1" paragraph sx={{ color: "text.secondary" }} key={Math.random() + "vinedo" + index} mb={1} >
                        <strong>{"Viñedo: "}</strong>
                        {cuadro.numeroInscripcion}
                      </Typography>
                      <Typography variant="body1" paragraph sx={{ color: "text.secondary" }} key={Math.random() + "cuadros" + index}
                        mb={1} >
                        <strong>{" Cuadros: "}</strong>
                        {cuadro?.cuadros.map(
                          (unCuadro, indx) =>

                            unCuadro.nroInterno ? unCuadro.nroInterno : unCuadro.nombreCuadro + ", "

                        )}
                      </Typography>
                    </div>

                  ))}
                </Stack>
                {actividad.detalles.map((detalle, index) => (

                  <Typography variant="body1" sx={{ color: "text.secondary" }} key={"detalle" + index + Math.random()} mb={1} >
                    <strong>{detalle.nombre}:</strong> {detalle.valor}
                  </Typography>
                ))
                }
                <Button onClick={() => { clickAnularRegistro(actividad.tipo, actividad.id) }}  disabled ={actividad.estado=="anulado"} sx={{ ...((actividad.estado == "anulado")? { } : { backgroundColor: '#228936'} ) }}>
                <Typography variant="h6" sx={{ ...((actividad.estado == "anulado")? { color:'darkred'} : { color: 'white'} ) }} >

                  {actividad.estado == "vigente" ? "ANULAR" : "ANULADO"}
                  
                  
                  
                  </Typography>
                  </Button>


              </Box>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      ))
      }
    </Box >
  )
}
