import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TablasVinedos from "../components/Viñedos/TablasVinedos";
import axios from "axios";

export default function ComposicionDeVinedos() {
  
  const navigate = useNavigate();
  const currentUser= window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if ( currentUser== null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

 
  return <TablasVinedos />;
}
