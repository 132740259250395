import { useState, useEffect } from "react";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VinedosCuadrosSelector from "./VinedosCuadrosSelector";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Radio } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
// ----------------------------------------------------------------------
//React.forwardRef(({ onClick, href }, ref) => {
export default function TratamientosSanitariosForm({
  selectedDate,
  mySelectedVinedosCuadros,
  enviarForm,
  configurarTarea,
  listaTrabajosTarea,
  usarTarea,
  enviadoExito,
}) {
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting, setValue, reset } =
    useForm();
  const [enviando, setEnviando] = useState(false);
  const [productosFitosanitarios, setProductosFitosanitarios] = useState([]);
  const [valuePrincAct, setValuePrincAct] = useState("");
  const [valueProdCom, setValueProdCom] = useState("");
  const [tareas, setTareas] = useState([
    {
      idTarea: "",
      nombreTarea: "",
      plagaEnfermedad: "",
      principioActivo: "",
      nombreComercial: "",
      esperaCosecha: "",
      esperaReingreso: "",
      dosisEnCC: "",
      dosisEnGr: "",
    },
  ]);

  const filter = createFilterOptions();

  const [tareaSeleccionada, setTareaSeleccionada] = useState();

  const unidadProductiva = window.localStorage.getItem("UP");
  useEffect(() => {
    const request = " /api/trabajos/TraerProductosFitosanitarios";
    axios.get(request).then(function (response) {

      const map = new Map(response.data.map(pos => [pos.nroRegistro, pos]));
      const uniques = [...map.values()];
      setProductosFitosanitarios(uniques);
      /*console.log(productosFitosanitarios);*/
    });
  }, []);

  const onSubmit = (data) => {
    if (!enviando) {
      if (!configurarTarea) {
        if (!mySelectedVinedosCuadros.length > 0) {
          window.alert("debe seleccionar al menos un cuadro");
        } else {
          listaTrabajosTarea.length = 0;
          listaTrabajosTarea.push(...tareas);
          enviarForm(data);
          setEnviando(true);
        }
      } else if (configurarTarea) {
        listaTrabajosTarea.length = 0;
        listaTrabajosTarea.push(...tareas); //rellena la lista trabajosTarea con las tareas ingresadas
        enviarForm(data);
        setEnviando(true);
      }
    }; //setResult el que cambia el state
  }
  const agregarCampos = () => {
    setTareas([
      ...tareas,
      {
        idTarea: "",
        nombreTarea: "",
        plagaEnfermedad: "",
        principioActivo: "",
        nombreComercial: "",
        esperaCosecha: "",
        esperaReingreso: "",
        dosisEnCC: "",
        dosisEnGr: "",
      },
    ]);
  };

  const enfermedades = [
    "Peronóspora",
    "Antracnosis",
    "Excoriosis",
    "Oidio",
    "Botrytis",
    "Podredumbre ácida",
    "Enfermedades de madera",
    "Lagartitas",
    "Chanchito blanco",
    "Erinosis",
    "Drosophila",
  ];
  const equals = (a, b) =>
    a.length === b.length && a.every((v, i) => v === b[i]);

  const asignarTareas = () => {
    //enviado
    if (enviadoExito && enviando) {
      setEnviando(false);
      setTimeout(() => {
        navigate(`/dashboard/tratamientosSanitarios`, { replace: true });
      }, 3000);
    }

    if (usarTarea.length > 0 && !equals(usarTarea, tareas)) {
      console.log("setea Tareas");
      /*  var misTareasAux = [];
        usarTarea.map((unaTarea) => {
          var miTareaAux = {
            idTarea: unaTarea.id ? unaTarea.id : "",
            nombreTarea: unaTarea.nombreTarea ? unaTarea.nombreTarea : "",
            plagaEnfermedad: unaTarea.plagaEnfermedad ? unaTarea.plagaEnfermedad : "",
            principioActivo: unaTarea.principioActivo ? unaTarea.principioActivo : "",
            nombreComercial: unaTarea.nombreComercial ? unaTarea.nombreComercial : "",
            esperaCosecha: unaTarea.esperaCosecha ? unaTarea.esperaCosecha : "",
            esperaReingreso: unaTarea.esperaReingreso ? unaTarea.esperaReingreso : "",
            dosisEnCC: unaTarea.dosisEnCC ? unaTarea.dosisEnCC : "",
            dosisEnGr: unaTarea.dosisEnGr ? unaTarea.dosisEnGr : "",
          }
          misTareasAux.push(miTareaAux)
        });*/

      usarTarea.map((unaTarea) => {
        var miOpchon;
        productosFitosanitarios.map((prod) => {
          if (prod.nombreComercial == unaTarea.nombreComercial &&
            prod.principioActivo == unaTarea.principioActivo) {
            miOpchon = prod;
          }
        });
        if (miOpchon == undefined) {
          miOpchon = { nombreComercial: unaTarea.nombreComercial, 
            principioActivo: unaTarea.principioActivo }
        }

        unaTarea.option = miOpchon;
      });

      setTareas([...usarTarea]);

    } else if (usarTarea.length == 0 && tareas.length > 1 && !configurarTarea) {

      console.log("setea Tareas");
      setTareas([
        {
          idTarea: "",
          nombreTarea: "",
          plagaEnfermedad: "",
          principioActivo: "",
          nombreComercial: "",
          esperaCosecha: "",
          esperaReingreso: "",
          dosisEnCC: "",
          dosisEnGr: "",
        },
      ]);
    }
  };
  asignarTareas();

  const handleChange = (e) => {
    var nuevaTareas = [...tareas];
    // setear el array con las ariables  name: nombreTarea: etc

    if (e.target.name == "idTarea") {
      nuevaTareas[0].idTarea = e.target.value;
    } else if (e.target.name == "nombreTarea") {
      nuevaTareas[0].nombreTarea = e.target.value;
    } else if (
      e.target.name?.includes("enfermedad") ||
      e.target.id?.includes("enfermedad")
    ) {
      var myIndex = e.target.name
        ? e.target.name.split("enfermedad")[1]
        : e.target.id.split("enfermedad")[1];
      nuevaTareas[myIndex].plagaEnfermedad = e.target.value;
    } else if (
      e.target.name?.includes("principioActivo") ||
      e.target.id?.includes("principioActivo")
    ) {
      var myIndex = e.target.name
        ? e.target.name.split("principioActivo")[0]
        : e.target.id.split("principioActivo")[0];
      nuevaTareas[myIndex].principioActivo =
        e.target.textContent.split(" - ")[0];
      nuevaTareas[myIndex].nombreComercial =
        e.target.textContent.split(" - ")[1];

    } else if (e.target.name.includes("esperaCosecha")) {
      var myIndex = e.target.name.split("esperaCosecha")[1];
      nuevaTareas[myIndex].esperaCosecha = e.target.value;
    }

    else if (e.target.name.includes("esperaReingreso")) {
      var myIndex = e.target.name.split("esperaReingreso")[1];
      nuevaTareas[myIndex].esperaReingreso = e.target.value;
    }
    else if (e.target.name.includes("dosis")) {
      var myIndex = e.target.name.split("dosis")[1];
      if (nuevaTareas[myIndex].dosisEnCC) {
        nuevaTareas[myIndex].dosisEnCC = e.target.value;
      } else {
        nuevaTareas[myIndex].dosisEnGr = e.target.value;
      }
    } else if (e.target.name.includes("unidad")) {
      var myIndex = e.target.name.split("unidad")[1];
      if (nuevaTareas[myIndex].dosisEnCC) {
        nuevaTareas[myIndex].dosisEnG = nuevaTareas[myIndex].dosisEnCC;
        nuevaTareas[myIndex].dosisEnCC = null;
      } else {
        nuevaTareas[myIndex].dosisEnCC = nuevaTareas[myIndex].dosisEnGr;
        nuevaTareas[myIndex].dosisEnGr = null;
      }
    }
    setTareas([...nuevaTareas]);
  };


  const selectFreeSolo = (e, unaOption, index) => {
    console.log("selectFreeSolo");
    var nuevasTareas = [...tareas];
    var laTarea = nuevasTareas[index];
    if (unaOption) {
      laTarea.option = unaOption;
      laTarea.principioActivo = unaOption.principioActivo
        ? unaOption.principioActivo.trim()
        : "";
      laTarea.nombreComercial = unaOption.nombreComercial ? unaOption.nombreComercial.trim() : "";
      laTarea.esperaCosecha = unaOption.esperaCosecha
        ? unaOption.esperaCosecha.trim()
        : "";

      laTarea.esperaCosecha = unaOption.esperaCosecha
        ? unaOption.esperaCosecha.trim()
        : "";

      laTarea.esperaReingreso = unaOption.esperaReingreso
        ? unaOption.esperaReingreso.trim()
        : "";
      /*  laTarea.plagaEnfermedad = unaOption.plagaEnfermedad
          ? unaOption.plagaEnfermedad.trim() //split
          : ""; */
      /*      laTarea.plagaEnfermedad = laTarea.plagaEnfermedad.split(" ")[0];
      
            var lowerEnfer2 = laTarea.plagaEnfermedad.toLowerCase();
            var laEnferIndex = "";
            /*   enfermedades.forEach((enfermedad, index) => {
              var laEnfer = enfermedad.toLowerCase();
              if (laEnfer === lowerEnfer2) {
                laTarea.plagaEnfermedad = index;
              }
            });*/
    }

    nuevasTareas[index] = laTarea;

    setTareas([...nuevasTareas]);
  };

  const setInputPrinAct = (e, newValue, index) => {
    if (e) {
      console.log("setinputprinact");
      var nuevaTareas = [...tareas];
      if (
        index != "reset" &&
        nuevaTareas[index] &&
        newValue != undefined
      ) {
        nuevaTareas[index].principioActivo = newValue;
        nuevaTareas[index].option.principioActivo = newValue;
      }
      setTareas([...nuevaTareas]);
    }
  };

  const setInputEnfer = (e, elBalue, index) => {
    console.log("setinputEnfer");
    var nuevaTareas = [...tareas];
    if (
      index != "reset" &&
      nuevaTareas[index]
    ) {
      nuevaTareas[index].plagaEnfermedad = elBalue.toString();
    }
    setTareas([...nuevaTareas]);
  }

  const setInputProd = (e, newValue, index) => {
    if (e) {
      console.log("setinputprod");
      var nuevaTareas = [...tareas];
      if (
        index != "reset" &&
        nuevaTareas[index] &&
        newValue != undefined
      ) {
        nuevaTareas[index].nombreComercial = newValue;

        nuevaTareas[index].option.nombreComercial = newValue;
      }
      setTareas([...nuevaTareas]);
    }
  };

  {/* const setInputEnfer = (e, newValue, index) => {
    console.log("");
    var nuevaTareas = [...tareas];
    if (
      index != "reset" &&
      nuevaTareas[index] &&
      nuevaTareas[index].plagaEnfermedad &&
      newValue != undefined
    ) {
      nuevaTareas[index].plagaEnfermedad = newValue;

      nuevaTareas[index].option.plagaEnfermedad = newValue;
    }
    setTareas([...nuevaTareas]);
  };*/}

  return (
    //aca hay que cargar la lista de tareas configuradas y hacer un setstate del form
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          {configurarTarea ? null : (
            <DatePickerField selectedDate={selectedDate} />
          )}
          {configurarTarea || usarTarea.length > 0 ? (
            <TextField
              fullWidth
              type={"number"}
              value={tareas[0].idTarea || ""}
              label="N° Tarea"
              {...register("idTarea")}
              name="idTarea"
              required={true}
              // validateStatus={errors && errors.picos ? "error" : "success"}
              help={
                errors && errors.picos ? "Este campo no puede estar vacío" : ""
              }
              onChange={handleChange}
            />
          ) : null}
          {configurarTarea ? (
            <TextField
              fullWidth
              label="Nombre Tarea"
              value={tareas[0].nombreTarea || ""}
              {...register("nombreTarea")}
              name="nombreTarea"
              required={true}
              // validateStatus={errors && errors.picos ? "error" : "success"}
              help={
                errors && errors.picos ? "Este campo no puede estar vacío" : ""
              }
              onChange={handleChange}
            />
          ) : null}

          {tareas.map(function (unaTarea, index) {
            return (
              <div key={index}>

                <Stack mt={2} spacing={3}>
                  <Autocomplete
                    id={index + "PlagaEnfermedad"}
                    clearOnBlur={false}
                    freeSolo
                    options={enfermedades.map((q) => q)}
                    value={unaTarea.plagaEnfermedad || ""}
                    onChange={(event, newValue) => {
                      setInputEnfer(event, newValue, index);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputEnfer(event, newInputValue, index);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Plaga ó Enfermedad" />
                    )}
                  />
                  <Autocomplete
                    id={index + "principioActivo"}
                    clearOnBlur={false}
                    freeSolo
                    options={productosFitosanitarios.map((option) => option)}
                    getOptionLabel={(option) =>
                    (option ?
                      option.principioActivo + "  (" + option.nombreComercial + ")" : "")
                    }
                    value={unaTarea.option || ""}
                    onChange={(event, newValue) => {
                      selectFreeSolo(event, newValue, index);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputPrinAct(event, newInputValue, index);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Principio Activo" />
                    )}
                  />

                  <Autocomplete
                    clearOnBlur={false}
                    value={unaTarea.option || ""}
                    id={index + "nombreComercial"}
                    onChange={(event, newValue) => {
                      selectFreeSolo(event, newValue, index);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputProd(event, newInputValue, index);
                    }}
                    freeSolo
                    options={productosFitosanitarios.map((option) => option)}
                    getOptionLabel={(option) =>
                    (option ?
                      option.nombreComercial + "  (" + option.principioActivo + ")" : "")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Nombre Comercial" />
                    )}
                  />

                  <TextField
                    {...register("esperaCosecha" + index)}
                    fullWidth
                    label="Tiempo de Espera en Días Hasta Cosecha"
                    required={true}
                    value={unaTarea.esperaCosecha || ""}
                    help={
                      errors && errors.htmlText
                        ? "Este campo no puede estar vacío"
                        : ""
                    }
                    key={"esperaCosecha" + index}
                    onChange={handleChange}
                  />

                  <TextField
                    {...register("esperaReingreso" + index)}
                    fullWidth
                    label="Tiempo de Espera en Días Hasta Reingreso"
                    required={true}
                    value={unaTarea.esperaReingreso || ""}
                    help={
                      errors && errors.htmlText
                        ? "Este campo no puede estar vacío"
                        : ""
                    }
                    key={"esperaReingreso" + index}
                    onChange={handleChange}
                  />


                </Stack>

                <Stack mt={2}>
                  <TextField
                    label="Concentración de producto cada 100 litros"
                    value={unaTarea.dosisEnCC || unaTarea.dosisEnGr || ""}
                    {...register("dosis" + index)}
                    required={true}
                    // validateStatus={errors && errors.dosis ? "error" : "success"}
                    help={
                      errors && errors.dosis
                        ? "Este campo no puede estar vacío"
                        : ""
                    }
                    key={"dosis" + index}
                    onChange={handleChange}
                  />
                </Stack>
                <Stack spacing={4} direction={"row"}>
                  <RadioGroup
                    sx={{ width: "40%" }}
                    xs={1}
                    row
                    aria-label="unidad"
                    name={"dosisRadioButtons" + index}
                    key={"dosisRadioButtons" + index}
                    value={unaTarea.dosisEnCC ? "cc" : "g"}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="cc"
                      control={<Radio />}
                      label="cc"
                      {...register("unidad" + index)}
                      name={"unidad" + index}
                      key={"unidadcc" + index}
                    />
                    <FormControlLabel
                      value="g"
                      control={<Radio />}
                      label="g"
                      name={"unidad" + index}
                      {...register("unidad" + index)}
                      key={"unidadg" + index}
                    />
                  </RadioGroup>
                </Stack>
              </div>
            );
          })}

          {configurarTarea ? (
            <Button onClick={agregarCampos}>Agregar Campos</Button>
          ) : null}

          {configurarTarea ? null : (
            <TextField
              fullWidth
              label="Atomizadora"
              {...register("atomizadora")}
              name="atomizadora"
              required={true}
              // validateStatus={errors && errors.picos ? "error" : "success"}
              help={
                errors && errors.picos ? "Este campo no puede estar vacío" : ""
              }
            />
          )}
          {configurarTarea ? null : (
            <TextField
              fullWidth
              type={"number"}
              label="Picos"
              {...register("picos")}
              name="picos"
              required={true}
              // validateStatus={errors && errors.picos ? "error" : "success"}
              help={
                errors && errors.picos ? "Este campo no puede estar vacío" : ""
              }
            />
          )}
          {configurarTarea ? null : (
            <TextField
              fullWidth
              type={"number"}
              label="Gasto Real (Gasto Total de Agua) en litros"
              {...register("gastoReal")}
              name="gastoReal"
              required={true}
              // validateStatus={errors && errors.picos ? "error" : "success"}
              help={
                errors && errors.picos ? "Este campo no puede estar vacío" : ""
              }
            />
          )}

          {configurarTarea ? null : (
            <TextField
              fullWidth
              label="Observaciones"
              {...register("observaciones")}
              placeholder="observaciones"
              required={false}
              // validateStatus={errors && errors.observaciones ? "error" : "success"}
              help={
                errors && errors.observaciones
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />
          )}
        </Stack>
        {/* */}
        {configurarTarea ? null : (
          <VinedosCuadrosSelector
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
          // ref={(input) => { this.VinCuadSel = input; }}
          />
        )}
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={enviando}
        >
          {!enviadoExito
            ? configurarTarea
              ? "Guardar Tarea"
              : "Guardar Tratamiento"
            : "Datos Guardados"}
        </LoadingButton>
      </Stack>
    </form>
  );
}
