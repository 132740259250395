import { useState, useEffect } from "react";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

export default function MonitoreoForm({
  selectedDate,
  enviarForm,
  enviarForm2,
  enviadoExito,
}) {
  //vinedos unidad no está funcionando
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting , reset} = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
    isSubmitting: isSubmitting2,
  } = useForm();
  const [result, setResult] = useState("");

  const [vinedos, setVinedos] = useState(
    JSON.parse(window.localStorage.getItem("vinedos"))
  );
  const [cuadros, setCuadros] = useState([]);

  const [enviando, setEnviando] = useState(false);

  const [enviando2, setEnviando2] = useState(false);

  const currentUser= window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  useEffect(() => {
    if ( currentUser== null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

  const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
      setTimeout(() => {
        navigate(`/dashboard/Monitoreo`, { replace: true });
        reset()
      }, 3000);
    }

    if (enviadoExito && enviando2) {
      setEnviando2(false);
      setTimeout(() => {
        navigate(`/dashboard/Monitoreo`, { replace: true });
      }, 3000);
    }
  };
  envio();

  const onSubmit1 = (data) => {
    if (!enviando) {
    enviarForm(data);
    setEnviando(true);
  };
 } //setResult el que cambia el state
  const onSubmit2 = (data) => {
    if (!enviando) {
    enviarForm2(data);
    setEnviando2(true);
  };
}

  const cargaCuadros = (e) => {
    const selectedVinedo = e.target.value;
    const daVinedo = vinedos.find(
      (iteraVinedo) => iteraVinedo.numeroInscripcion === selectedVinedo
    );

    if (daVinedo) {
      setCuadros(daVinedo.cuadros);
    }
  };

  const plagas = [
    "Chanchito blanco",
    "Bonagota salubricola",
    "Argyrotaenia sphaleropa",
    "Cryptoblades gnidiella",
  ];

  return (
    <Stack>
      <Stack sx={{ mb: 6 }}>
        <Box>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Registrar Colocación
          </Typography>
          <form onSubmit={handleSubmit(onSubmit1)}>
            <Stack spacing={3}>
              <DatePickerField selectedDate={selectedDate} />

              <Select
                label="Viñedos"
                defaultValue="1"
                {...register("vinedo", {
                  onChange: (e) => {
                    cargaCuadros(e);
                  },
                })}
              >
                <MenuItem disabled={true} value={"1"}>
                  {"Seleccione un Viñedo"}
                </MenuItem>
                {vinedos.map((myVinedo, index) => (
                  <MenuItem key={index} value={myVinedo?.numeroInscripcion}>
                    {myVinedo?.numeroInscripcion} {myVinedo?.nombre}
                  </MenuItem>
                ))}
              </Select>

              <Select label="Cuadros" defaultValue="1" {...register("cuadro")}>
                <MenuItem disabled={true} value={"1"}>
                  {"Seleccione un Cuadro"}
                </MenuItem>
                {cuadros.map((cuadroData, index) => (
                  <MenuItem
                    key={index}
                    value={cuadroData.nombreUva + cuadroData.nombreCuadro}
                  >
                    {cuadroData.nombreUva} {cuadroData.nombreCuadro}
                  </MenuItem>
                ))}
              </Select>

              <Select label="Plaga" defaultValue="1" {...register("plaga")}>
                <MenuItem disabled={true} value={"1"}>
                  {"Seleccione una Plaga"}
                </MenuItem>
                {plagas.map((plaga, index) => (
                  <MenuItem key={index} value={plaga}>
                    {plaga}
                  </MenuItem>
                ))}
              </Select>

              <TextField
                fullWidth
                type={"number"}
                label="Número de Trampa"
                {...register("trampaNro")}
                placeholder="trampaNro"
                required={true}
                //validateStatus={errors  ? "error" : "success"}
                help={
                  errors 
                    ? "Este campo no puede estar vacío"
                    : ""
                }
              />
              <Stack spacing={3}>
                <TextField
                  multiline
                  maxRows={6}
                  label="Observaciones"
                  {...register("observaciones")}
                  placeholder="observaciones"
                  required={false}
                  // validateStatus={errors && errors.observaciones ? "error" : "success"}
                  help={
                    errors && errors.observaciones
                      ? "Este campo no puede estar vacío"
                      : ""
                  }
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={enviando}
              >
                {!enviadoExito ? "Registrar Colocación" : "Datos Guardados"}
              </LoadingButton>
            </Stack>
          </form>
        </Box>
      </Stack>
      <Stack>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Registrar Monitoreo
        </Typography>

        <form onSubmit={handleSubmit2(onSubmit2)}>
          <Stack spacing={3}>
            <DatePickerField selectedDate2={selectedDate} />

            <Select
                label="Viñedos"
                defaultValue="1"
                {...register2("vinedo2", {
                  onChange: (e) => {
                    cargaCuadros(e);
                  },
                })}
              >
              <MenuItem disabled={true} value={"1"}>
                {"Seleccione un Viñedo"}
              </MenuItem>
              {vinedos.map((myVinedo, index) => (
                <MenuItem key={index} value={myVinedo?.numeroInscripcion}>
                  {myVinedo?.numeroInscripcion} {myVinedo?.nombre}
                </MenuItem>
              ))}
            </Select>

            <Select label="Cuadros" defaultValue="1" {...register2("cuadro2")}>
                <MenuItem disabled={true} value={"1"}>
                  {"Seleccione un Cuadro"}
                </MenuItem>
                {cuadros.map((cuadroData, index) => (
                  <MenuItem
                    key={index}
                    value={cuadroData.nombreUva + cuadroData.nombreCuadro}
                  >
                    {cuadroData.nombreUva} {cuadroData.nombreCuadro}
                  </MenuItem>
                ))}
              </Select>

            <Select label="Plaga2" defaultValue="1" {...register2("plaga2")}>
                <MenuItem disabled={true} value={"1"}>
                  {"Seleccione una Plaga"}
                </MenuItem>
                {plagas.map((plaga, index) => (
                  <MenuItem key={index} value={plaga}>
                    {plaga}
                  </MenuItem>
                ))}
              </Select>
            <TextField
              fullWidth
              type={"number"}
              label="Número de Trampa"
              {...register2("trampaNro2")}
              required={true}
              //validateStatus={errors  ? "error" : "success"}
              help={
                errors && errors.trampaNro
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />
            <TextField
              multiline
              maxRows={6}
              label="Cantidad de Capturas"
              {...register2("nroCapturas")}
              name="nroCapturas"
              required={true}
              // validateStatus={errors && errors.observaciones ? "error" : "success"}
              help={
                errors && errors.nroCapturas
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />

            <Stack spacing={3}>
              <TextField
                multiline
                maxRows={6}
                label="Observaciones"
                {...register2("observaciones2")}
                name="observaciones"
                required={false}
                // validateStatus={errors && errors.observaciones ? "error" : "success"}
                help={
                  errors && errors.observaciones
                    ? "Este campo no puede estar vacío"
                    : ""
                }
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={enviando2}
            >
              {!enviadoExito ? "Registrar Monitoreo" : "Datos Guardados"}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
}
