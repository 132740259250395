import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
// components
import Page from "../components/Page";
import IndiceEquilibrioForm from "../components/forms/IndiceEquilibrioForm";
 
import axios from "axios";
//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function IndiceEquilibrio() {
  // ;
  const navigate = useNavigate();
  const currentUser= window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if ( currentUser== null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);


  function enviarForm(values) {

    var myObjectIndiceEq = {
      idUnidadProductiva: selectedUP,
      vinedo: values.vinedo,
      cuadro: values.cuadro.split("-")[0],
      produccionZafraAnterior: values.produccionZafraAnterior,

      fila1 : values.fila1nro,
      largoCentro1 : values.largoCentro1,
      nroSarmientos1: values.nroSarmientosxCentro1,
      pesoPoda1: values.pesoPoda1,
      nroSarmientosPorMetro1 : values.nroSarmientosxMetro1,
      pesoPodaPorMetro1 : values.pesoPodaPorMetro1,
      pesoPorSarmiento1 : values.pesoPorSarmiento1,
      indiceRavaz1: values.indiceRavaz1,

      fila2 : values.fila2nro,
      largoCentro2 : values.largoCentro2, 
      nroSarmientos2: values.nroSarmientosxCentro2,
      pesoPoda2: values.pesoPoda2,
      nroSarmientosPorMetro2 : values.nroSarmientosxMetro2,
      pesoPodaPorMetro2 : values.pesoPodaPorMetro2,
      pesoPorSarmiento2 : values.pesoPorSarmiento2,
      indiceRavaz2: values.indiceRavaz2,

      fila3 : values.fila3nro,
      largoCentro3 : values.largoCentro3,
      nroSarmientos3: values.nroSarmientosxCentro3,
      pesoPoda3: values.pesoPoda3,
      nroSarmientosPorMetro3 : values.nroSarmientosxMetro3,
      pesoPodaPorMetro3 : values.pesoPodaPorMetro3,
      pesoPorSarmiento3 : values.pesoPorSarmiento3,
      indiceRavaz3: values.indiceRavaz3,

      fila4 : values.fila4nro,
      largoCentro4 : values.largoCentro4,
      nroSarmientos4: values.nroSarmientosxCentro4,
      pesoPoda4: values.pesoPoda4,
      nroSarmientosPorMetro4 : values.nroSarmientosxMetro4,
      pesoPodaPorMetro4 : values.pesoPodaPorMetro4,
      pesoPorSarmiento4 : values.pesoPorSarmiento4,
      indiceRavaz4: values.indiceRavaz4,

           
      observaciones: values.observaciones,
     
    };
    const request = JSON.parse(JSON.stringify(myObjectIndiceEq));
     console.log (JSON.stringify(myObjectIndiceEq) + " esto manda"); 
    axios
      .post(`/api/trabajos/indiceEquilibrio/nuevo`, request)
      .then((response) => {
        if (response.status == "201"){
             setEnviadoExito(true)
             setTimeout(() => {
              setEnviadoExito(false)
            }, 3000);
           }else{
             setEnviadoExito(false)
           }
          }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))


  }

  

  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  var mySelectedVinedosCuadros = new Array();

  return (
    <RootStyle title="Índice de Equilibrio | Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
            Índice de Equilibrio
            </Typography>
            {/*<Typography sx={{ color: 'text.secondary' }}>
              Free forever. No credit card needed.
</Typography>*/}
          </Box>

          <IndiceEquilibrioForm  
            selectedDate={selectedDate}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}
            enviadoExito={enviadoExito}
        
          />
        </ContentStyle>
      </Container>

      <Card sx={{ maxWidth: "40%" }}>
        <Box sx={{ p: 5 }}>
          <Typography variant="body2">
          LA FERTILIZACIÓN NITROGENADA SE PERMITE SOLO CUANDO SE DISPONEN DE DATOS DE DESARROLLO VEGETATIVO
          </Typography>
        </Box>
      </Card>
    </RootStyle>
  );
}
