import { useState } from "react";
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VinedosCuadrosSelector from "./VinedosCuadrosSelector";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Typography from "src/theme/overrides/Typography";
import { Box } from "@mui/system";
// ----------------------------------------------------------------------
//React.forwardRef(({ onClick, href }, ref) => {
export default function CalibracionEquiposForm({
  vinedosUnidad,
  selectedDate,
  mySelectedVinedosCuadros,
  enviarForm,
  enviadoExito,
}) {
  //vinedos unidad no está funcionando
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting } = useForm();
  const [result, setResult] = useState("");

  const [enviando, setEnviando] = useState(false);

  const onSubmit = (data) => {
    if (!enviando) {
    enviarForm(data);
    setEnviando(true)
    }
  }; //setResult el que cambia el state

  const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
      setTimeout(() => {
        navigate(`/dashboard/CalibracionEquipos`, { replace: true });
        reset();
      }, 3000);
    }
  };
  envio();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Número de Tractor"
            {...register("nroTractor")}
            placeholder="Número de Tractor"
            required={true}
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors 
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
          <TextField
            fullWidth
            label="Marca, modelo y año"
            {...register("marcaModeloAnioTractor")}
            placeholder="Marca, modelo y año"
            required={true}
            // validateStatus={errors && errors.nombreComercial ? "error" : "success"}
            help={
              errors && errors.htmlText ? "Este campo no puede estar vacío" : ""
            }
          />

          <TextField
            fullWidth
            label="Número de Atomizadora"
            {...register("nroAtomizadora")}
            placeholder="Número de Atomizadora"
            required={true}
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors 
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
          <TextField
            fullWidth
            label="Marca, modelo y año"
            {...register("marcaModeloAnioAtomizadora")}
            placeholder="Marca, modelo y año"
            required={true}
            // validateStatus={errors && errors.nombreComercial ? "error" : "success"}
            help={
              errors && errors.htmlText ? "Este campo no puede estar vacío" : ""
            }
          />
          <TextField
            fullWidth
            label="Capacidad de Atomizadora"
            {...register("capacidadAtomizadora")}
            placeholder="Capacidad de Atomizadora"
            required={true}
            // validateStatus={errors && errors.nombreComercial ? "error" : "success"}
            help={
              errors && errors.htmlText ? "Este campo no puede estar vacío" : ""
            }
          />
        </Stack>
        <Stack spacing={3}>
          <DatePickerField selectedDate={selectedDate} />
          
          <TextField
            fullWidth
            
            label="Velocidad (km/h)"
            {...register("velocidadKmH")}
            name="velocidadKmH"
            required={true}
            // validateStatus={errors && errors.dosis ? "error" : "success"}
            help={
              errors && errors.dosis ? "Este campo no puede estar vacío" : ""
            }
          />
          <TextField
            fullWidth
            
            label="Velocidad (m/min)"
            {...register("velocidadMmin")}
            name="velocidadMmin"
            required={true}
            // validateStatus={errors && errors.dosis ? "error" : "success"}
            help={
              errors && errors.dosis ? "Este campo no puede estar vacío" : ""
            }
          />
          <TextField
            fullWidth
            
            label="Presión (lb)"
            {...register("presion")}
            name="presion"
            required={true}
            // validateStatus={errors && errors.dosis ? "error" : "success"}
            help={
              errors && errors.dosis ? "Este campo no puede estar vacío" : ""
            }
          />
          <TextField
            fullWidth
            
            label="Distancia entre filas (m)"
            {...register("distanciaEntreFilas")}
            name="distanciaEntreFilas"
            required={true}
            // validateStatus={errors && errors.dosis ? "error" : "success"}
            help={
              errors && errors.dosis ? "Este campo no puede estar vacío" : ""
            }
          />
          <TextField
            fullWidth
            
            label="Volumen (l/há)"
            {...register("volumenLHa")}
            name="volumenLHa"
            required={true}
            // validateStatus={errors && errors.dosis ? "error" : "success"}
            help={
              errors && errors.dosis ? "Este campo no puede estar vacío" : ""
            }
          />
        </Stack>
        <Stack>
          Gasto de cada puntero en litros/min
          <Stack spacing={1} direction={"row"}>
            Lado Izquierdo
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="I1"
              {...register("I1")}
              name="I1"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="I2"
              {...register("I2")}
              name="I2"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="I3"
              {...register("I3")}
              name="I3"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="I4"
              {...register("I4")}
              name="I4"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="I5"
              {...register("I5")}
              name="I5"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="I6"
              {...register("I6")}
              name="I6"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="I7"
              {...register("I7")}
              name="I7"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="I8"
              {...register("I8")}
              name="I8"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
          </Stack>
          <Stack spacing={1} direction={"row"}>
            Lado Derecho
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="D1"
              {...register("D1")}
              name="D1"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="D2"
              {...register("D2")}
              name="D2"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="D3"
              {...register("D3")}
              name="D3"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="D4"
              {...register("D4")}
              name="D4"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="D5"
              {...register("D5")}
              name="D5"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="D6"
              {...register("D6")}
              name="D6"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="D7"
              {...register("D7")}
              name="D7"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
            <TextField
              sx={{ width: "12%" }}
              xs={1}
              
              label="D8"
              {...register("D8")}
              name="D8"
              required={true}
              // validateStatus={errors && errors.dosis ? "error" : "success"}
              help={
                errors && errors.dosis ? "Este campo no puede estar vacío" : ""
              }
            />
          </Stack>
          <TextField
            label="Observaciones"
            {...register("observaciones")}
            placeholder="observaciones"
            required={true}
            // validateStatus={errors && errors.observaciones ? "error" : "success"}
            help={
              errors && errors.observaciones
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        </Stack>
        {/* 
        <VinedosCuadrosSelector
          vinedosUnidad={vinedosUnidad}
          mySelectedVinedosCuadros={mySelectedVinedosCuadros}
          // ref={(input) => { this.VinCuadSel = input; }}
          />*/}
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={enviando}
        >
          {!enviadoExito ? "Guardar Calibracion Equipo" : "Datos Guardados"}
        </LoadingButton>
      </Stack>
    </form>
  );
}
