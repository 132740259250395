import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";

import { useState, useEffect } from "react";
// components
import Page from "../components/Page";
import InformeTecnicoForm from "src/components/forms/InformeTecnicoForm";
import axios from "axios";
import {useNavigate } from "react-router-dom";
//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function InformeTecnico() {
  // ;


    const navigate = useNavigate();
    const currentUser= window.localStorage.getItem("user");
    const selectedUP = window.localStorage.getItem("UP");

    const [enviadoExito, setEnviadoExito] = useState(false);
  
    useEffect(() => {
    if ( currentUser== null) {
       ;
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
       ;
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);
    

  function enviarForm(values) {
    var myObjectInformeTecnico= {
      idUnidadProductiva: selectedUP,
      autor:values.autor,
      fechaAplicacion: theDate,
      cuadrosAplicados: mySelectedVinedosCuadros,
      recomendaciones: values.recomendaciones,
      observaciones: values.observaciones
    };

    if (!mySelectedVinedosCuadros.length > 0) {
      window.alert("debe seleccionar al menos un cuadro");
    } else {
      const request = JSON.parse(JSON.stringify(myObjectInformeTecnico));
       ;
      axios
        .post(
          ` /api/trabajos/informeTecnico/nuevo`,
          request
        ).then((response) => {
          if (response.status == "201"){
               setEnviadoExito(true)
               setTimeout(() => {
                setEnviadoExito(false)
              }, 3000);
             }else{
               setEnviadoExito(false)
             }
            }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
 
 
    }
  }
  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  const mySelectedVinedosCuadros = new Array();

  return (
    <RootStyle title="Informe Técnico | Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Informe Técnico y Recomendaciones
            </Typography>
           
          </Box>

          <InformeTecnicoForm
            selectedDate={selectedDate}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}
            enviadoExito={enviadoExito}
          />
        </ContentStyle>
      </Container>

      <Card sx={{ maxWidth: "40%" }}>
        <Box sx={{ p: 5 }}>
          <Typography variant="body2">
            
          </Typography>
        </Box>
      </Card>
    </RootStyle>
  );
}
