import { useState } from "react";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VinedosCuadrosSelector from "./VinedosCuadrosSelector";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function InformeTecnicoForm({
 
  selectedDate,
  mySelectedVinedosCuadros,
  enviarForm,
  enviadoExito
}) {
  //vinedos unidad no está funcionando
  const navigate = useNavigate();
  const { register, handleSubmit, errors, reset } = useForm();
  const [result, setResult] = useState("");
  const [enviando, setEnviando] = useState(false);

  const onSubmit = (data) => {
    if (!enviando) {
    if (mySelectedVinedosCuadros.length>0){
      enviarForm(data);
      setEnviando(true);
      }else {
          window.alert("debe seleccionar al menos un cuadro");
        }
      }
    }
   //setResult el que cambia el state
   const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
    
      setTimeout(() => {
        navigate(`/dashboard/InformeTecnico`, { replace: true });
        reset()
      }, 3000);
    }
  };
  envio();


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <DatePickerField selectedDate={selectedDate} />

          <TextField               
                fullWidth
                label="Autor"
                {...register("autor")}
                placeholder="Autor"
                required={true}
                // validateStatus={errors && errors.nombreComercial ? "error" : "success"}
                help={
                  errors && errors.htmlText ? "Este campo no puede estar vacío" : ""
                }
              />
          <TextField
            
            multiline
            rows={6}
            label="Recomendaciones"
            {...register("recomendaciones")}
            placeholder="Recomendaciones"
            required={true}
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors && errors.recomendaciones
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        
        </Stack>
        <Stack spacing={3}>
          <TextField
            multiline
            rows={6}
            label="Observaciones"
            {...register("observaciones")}
            placeholder="observaciones"
            required={true}
            // validateStatus={errors && errors.observaciones ? "error" : "success"}
            help={
              errors && errors.observaciones
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        </Stack>
        {/* */}
        <VinedosCuadrosSelector
             mySelectedVinedosCuadros={mySelectedVinedosCuadros}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={enviando}
        >
           {!enviadoExito ?"Guardar Informe" : "Datos Guardados"}
        </LoadingButton>  
        </Stack>
    </form>
  );
}
