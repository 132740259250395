import { useState } from "react";
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VinedosCuadrosSelector from "./VinedosCuadrosSelector";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { Checkbox } from "@mui/material";
import { FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";

import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------
//React.forwardRef(({ onClick, href }, ref) => {
export default function ManejosCulturalesForm({
  vinedosUnidad,
  selectedDate,
  mySelectedVinedosCuadros,
  enviarForm,
  enviadoExito
}) {
  //vinedos unidad no está funcionando
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting } = useForm();
  const [result, setResult] = useState("");
  const [enviando, setEnviando] = useState(false);

  const onSubmit = (data) => {
    if (!enviando) {
    if (!mySelectedVinedosCuadros.length > 0) {
      window.alert("debe seleccionar al menos un cuadro");
    } else {
      enviarForm(data);
      setEnviando(true);
    }
  }
  }; //setResult el que cambia el state


  const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
      setTimeout(() => {
        navigate(`/dashboard/Manejo`, { replace: true });
        reset()
      }, 3000);
    }
  };
  envio();


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <DatePickerField selectedDate={selectedDate} />
          <Typography variant="h6">
            Manejos sobre la Planta
            </Typography>
          <FormGroup >
            <FormControlLabel
              control={<Checkbox  />}
              label="Poda"
              {...register("poda")}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Desbrotado Tronco"
              {...register("desbrotadoTronco")}
            />
              <FormControlLabel
              control={<Checkbox />}
              label="Deschuponado"
              {...register("deschuponado")}
            />
              <FormControlLabel
              control={<Checkbox />}
              label="Deshojado"
              {...register("deshojado")}
            />
              <FormControlLabel
              control={<Checkbox />}
              label="Roñado Lateral"
              {...register("plantaRoñadoLateral")}
            />
              <FormControlLabel
              control={<Checkbox />}
              label="Roñado Apical"
              {...register("roñadoApical")}
            />
              <FormControlLabel
              control={<Checkbox />}
              label="Raleo de Racimos"
              {...register("raleoRacimos")}
            />
          </FormGroup>
          <Typography variant="h6">
            Manejos sobre el Suelo
            </Typography>
          <FormGroup >
            <FormControlLabel
              control={<Checkbox  />}
              label="Pastera y Picarama"
              {...register("pasteraYPicarama")}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Laboreo en la Entrefila"
              {...register("laboreoEnLaEntrefila")}
            />
              <FormControlLabel
              control={<Checkbox />}
              label="Siembra"
              {...register("siembra")}
            />
              <FormControlLabel
              control={<Checkbox />}
              label="Control Mecánico de las Malezas en la fila"
              {...register("controlMecánicoDeLasMalezasEnLaFila")}
            />
           
            
          </FormGroup>
        </Stack>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Observaciones"
            {...register("observaciones")}
            placeholder="observaciones"
            required={true}
            // validateStatus={errors && errors.observaciones ? "error" : "success"}
            help={
              errors && errors.observaciones
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        </Stack>
        {/* */}
        <VinedosCuadrosSelector
          vinedosUnidad={vinedosUnidad}
          mySelectedVinedosCuadros={mySelectedVinedosCuadros}
          // ref={(input) => { this.VinCuadSel = input; }}
        />
         <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={enviando}
        >
           {!enviadoExito ?
          "Guardar Trabajo" : "Datos Guardados"}
        </LoadingButton>  
      </Stack>
    </form>
  );
}
