//import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

export default function Cuadro(props) {
  /* ;*/
  const { unCuadro } = props;
  const {meClickearon} = props;
  const {usarInterno} =props;

  /*console.log(usarInterno)
console.log(unCuadro.nroInterno) */
 /* const [cuadroEstado, setCuadroEstado] = useState(unCuadro);*/

 
  const handleClick = (oneButton) => {

  /*  cuadrosYEstados

    if (!oneButton.target.attributes.selected) {
      oneButton.target.attributes.selected = true;
      oneButton.target.style =
        'background-color:"light-blue", max-width:"30px"';
    } else {
      oneButton.target.attributes.selected = false;
      oneButton.target.style = "background-color:grey";
    }
    setSelectedVinedosCuadros(
      unVinedo,
      oneButton.target.attributes.cuadro.value
    );
  };*/
    meClickearon(unCuadro);
}

  const MyStyledButton = styled(Button)(({ theme }) => ({
    minWidth: 30,
    borderRadius: "2px",
    padding: "5px",
  }));

  const selectAllCuadros = () => {};
  return (
    <Tooltip title={unCuadro.nombreUva}>
      <MyStyledButton
        variant="contained"
        selected={unCuadro.selected}
        onClick={handleClick}
        cuadro={usarInterno? unCuadro.nroInterno? unCuadro.nroInterno:unCuadro.nombreCuadro : unCuadro.nombreCuadro}
        style={!unCuadro.selected?{
          backgroundColor: "grey",
          minWidth: "40px",
          maxWidth: "40px",
          borderRadius: "2px",
        }: {    backgroundColor: "green",
        minWidth: "40px",
        maxWidth: "40px",
        borderRadius: "2px",}}
      >
       {usarInterno? unCuadro.nroInterno? unCuadro.nroInterno:unCuadro.nombreCuadro : unCuadro.nombreCuadro}
      </MyStyledButton>
    </Tooltip>
  );
}
