
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
import { useNavigate } from 'react-router-dom';
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import FertilizantesForm from "../components/forms/FertilizantesForm";
import { useState, useEffect } from "react";
import axios from "axios";
//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function Fertilizante() {
  // ;


  const navigate = useNavigate();
  const currentUser = window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if (currentUser == null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

  function enviarForm(values) {
    if (mySelectedVinedosCuadros.length > 0) {
      var myObjectFertilizante = {
        idUnidadProductiva: selectedUP,
        fechaAplicacion: theDate,
        cuadrosAplicados: mySelectedVinedosCuadros,
        nombre: values.fertilizante,
        formaAplicacion: values.metodo,
        kilosPorHectarea: values.kgha,
        observaciones: values.observaciones,
      };

      const request = JSON.parse(JSON.stringify(myObjectFertilizante));

      ;
      axios
        .post(` /api/trabajos/fertilizante/nuevo`, request)
        .then((response) => {
          if (response.status == "201") {
            setEnviadoExito(true)
            setTimeout(() => {
              setEnviadoExito(false)
            }, 3000);
          } else {
            setEnviadoExito(false)
          }
        }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))


    } else {
      // ver que hacer
    }
  }
  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  var mySelectedVinedosCuadros = new Array();

  return (
    <RootStyle title="Fertilizante| Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Registrar un nueva aplicación de Fertilizante
            </Typography>
          </Box>

          <FertilizantesForm
            selectedDate={selectedDate}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}
            enviadoExito={enviadoExito}
          />
        </ContentStyle>
      </Container>
        <Card sx={{ maxWidth: "40%" }}>
        <Box sx={{ p: 5 }}>
          <Typography variant="body2">



            Plan de fertilización anual: se debe realizar un plan anual de fertilización, definido por el técnico asesor. <br/>

            OBLIGATORIO 1 <br/>
            LA FERTILIZACIÓN NITROGENADA SE PERMITE SOLO CUANDO SE DISPONEN DE DATOS DE DESARROLLO VEGETATIVO<br/>
            Fertilización nitrogenada: se permite un máximo de 100 kg N/Ha/año de todas las fuentes que aporten nitrógeno, excepto en cuadros que presenten síntomas de déficit (análisis foliar menor a 1,5% de N en floración).
            Abono orgánico: si aplica abono orgánico seleccionar la opción correspondiente y responder las preguntas.

          </Typography>
        </Box>
      </Card>

    </RootStyle>
  );
}
