import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";

import { useState, useEffect } from "react";
// components
import Page from "../components/Page";
import HormigasForm from "../components/forms/HormigasForm";
import axios from "axios";
import {useNavigate } from "react-router-dom";
//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function Hormiguicida() {
  // ;


    const navigate = useNavigate();
    const currentUser= window.localStorage.getItem("user");
    const selectedUP = window.localStorage.getItem("UP");

    const [enviadoExito, setEnviadoExito] = useState(false);
  
    useEffect(() => {
    if ( currentUser== null) {
       ;
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
       ;
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);
    

  function enviarForm(values) {
    var myObjectHormiga = {
      idUnidadProductiva: selectedUP,
      fechaAplicacion: theDate,
      fechaAplicacionFin: theDate2,
      cuadrosAplicados: mySelectedVinedosCuadros,
      nombreComercial: values.nombreComercial,
      principioActivo: values.principioActivo,
      periodo: "",
      observaciones: values.observaciones,
      idTarea: "",
    };

    if (!mySelectedVinedosCuadros.length > 0) {
      window.alert("debe seleccionar al menos un cuadro");
    } else {
      const request = JSON.parse(JSON.stringify(myObjectHormiga));
       ;
      axios
        .post(
          ` /api/trabajos/controlHormigas/nuevo`,
          request
        ).then((response) => {
          if (response.status == "201"){
               setEnviadoExito(true)
               setTimeout(() => {
                setEnviadoExito(false)
              }, 3000);
             }else{
               setEnviadoExito(false)
             }
            }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
 
 
    }
  }
  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  
  var theDate2 = new Date();
  const selectedDate2 = (aDate) => {
    theDate2 = aDate;
  };
  const mySelectedVinedosCuadros = new Array();

  return (
    <RootStyle title="Control Hormigas | Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Ingresar Control de Hormigas
            </Typography>
            {/*<Typography sx={{ color: 'text.secondary' }}>
              Free forever. No credit card needed.
</Typography>*/}
          </Box>

          <HormigasForm
            selectedDate={selectedDate}
            
            selectedDate2={selectedDate2}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}
            enviadoExito={enviadoExito}
          />
        </ContentStyle>
      </Container>

      <Card sx={{ maxWidth: "40%" }}>
        <Box sx={{ p: 5 }}>
          <Typography variant="body2">
          OBLIGATORIO 1<br/>
Debe demostrarse que se maneja esta plaga siguiendo la secuencia de épocas y métodos de control indicados. 
Épocas de control:
1er periodo: febrero- abril
2do periodo: última semana de agosto hasta noviembre. Es deseable realizarlo antes de los vuelos nupciales. 
3er periodo: mayo a fines de agosto y diciembre- enero.
Sólo se permite la utilización de:
Control a la olla con hormiguicidas en polvo o cebos con Fipronil o Sulfuramida.
Hongos entomopatogenos (Metarhizium robertsii cepa MVHC 1878).<br/>
<br/>
OBLIGATORIO 2<br/>
Solo en caso de no haber sido eficiente el control con cebos u hongos entomopatógenos se permite la utilización de los siguientes métodos:
Control a la olla con hormiguicidas en polvo: 
- Cipermetrina
- Fenitrotion
- Malation
- Ethion
Uso de termonebulizadoras, con aplicación de Lambda Cialotrina.
Uso de cebo con cáscara de naranja deshidratada y Spinosad al 0,03% (v/p)

          </Typography>
        </Box>
      </Card>
    </RootStyle>
  );
}
