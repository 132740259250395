import { useState, useEffect } from "react";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
// ----------------------------------------------------------------------

export default function IndiceEquilibrioForm({
  selectedDate,
  enviarForm,
  enviadoExito,
}) {
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting, reset } = useForm();

  const [result, setResult] = useState();

  const [vinedos, setVinedos] = useState(
    JSON.parse(window.localStorage.getItem("vinedos"))
  );
  const [cuadros, setCuadros] = useState([]);

  const [enviando, setEnviando] = useState(false);

  const currentUser = window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  useEffect(() => {
    if (currentUser == null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

  const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
      setTimeout(() => {
        navigate(`/dashboard/IndiceEquilibrio`, { replace: true });
        reset();
      }, 3000);
    }
  };
  envio();

  const onSubmit1 = (data) => {
    if (!enviando) {
      enviarForm(data);
      setEnviando(true);
    }
  }; //setResult el que cambia el state

  const cargaCuadros = (e) => {
    const selectedVinedo = e.target.value;
    const daVinedo = vinedos.find(
      (iteraVinedo) => iteraVinedo.numeroInscripcion === selectedVinedo
    );

    if (daVinedo) {
      setCuadros(daVinedo.cuadros);
    }
  };

  const [cuadro, setCuadro] = useState();
  const [largoCentro1, setlargoCentro1] = useState();
  const [largoCentro2, setlargoCentro2] = useState();
  const [largoCentro3, setlargoCentro3] = useState();
  const [largoCentro4, setlargoCentro4] = useState();

  const [nroSarmientosxCentro1, setnroSarmientosxCentro1] = useState();
  const [nroSarmientosxCentro2, setnroSarmientosxCentro2] = useState();
  const [nroSarmientosxCentro3, setnroSarmientosxCentro3] = useState();
  const [nroSarmientosxCentro4, setnroSarmientosxCentro4] = useState();

  const [nroSarmientosxMetro1, setnroSarmientosxMetro1] = useState();
  const [nroSarmientosxMetro2, setnroSarmientosxMetro2] = useState();
  const [nroSarmientosxMetro3, setnroSarmientosxMetro3] = useState();
  const [nroSarmientosxMetro4, setnroSarmientosxMetro4] = useState();


  const [pesoPodaxMetro1, setpesoPodaxMetro1] = useState();
  const [pesoPodaxMetro2, setpesoPodaxMetro2] = useState();
  const [pesoPodaxMetro3, setpesoPodaxMetro3] = useState();
  const [pesoPodaxMetro4, setpesoPodaxMetro4] = useState();

  const [pesoxSarmiento1, setpesoxSarmiento1] = useState();
  const [pesoxSarmiento2, setpesoxSarmiento2] = useState();
  const [pesoxSarmiento3, setpesoxSarmiento3] = useState();
  const [pesoxSarmiento4, setpesoxSarmiento4] = useState();

  const [pesoPodaCentro1, setpesoPodaCentro1] = useState();
  const [pesoPodaCentro2, setpesoPodaCentro2] = useState();
  const [pesoPodaCentro3, setpesoPodaCentro3] = useState();
  const [pesoPodaCentro4, setpesoPodaCentro4] = useState();

  const [indiceRavaz1, setindiceRavaz1] = useState();
  const [indiceRavaz2, setindiceRavaz2] = useState();
  const [indiceRavaz3, setindiceRavaz3] = useState();
  const [indiceRavaz4, setindiceRavaz4] = useState();


  const [metrosDeVid, setMetrosdeVid] = useState();
  const [kgCosecha, setKgCosecha] = useState();



  const actualizaLosCalcs = (unaFila) => {

    if (unaFila == 1 || unaFila == 0) {
      console.log("actualiza los calcs 1" + nroSarmientosxCentro1 + " , " + largoCentro1)
      setnroSarmientosxMetro1(Math.round((((nroSarmientosxCentro1 ? nroSarmientosxCentro1 : 0) / (largoCentro1 ? largoCentro1 : 1)) + Number.EPSILON) * 100) / 100)
      setpesoPodaxMetro1(Math.round((((pesoPodaCentro1 ? pesoPodaCentro1 : 0) / (largoCentro1 ? largoCentro1 : 1)) + Number.EPSILON) * 100) / 100)
      setpesoxSarmiento1(Math.round((((pesoPodaCentro1 ? pesoPodaCentro1 : 0) / (nroSarmientosxCentro1 ? nroSarmientosxCentro1 : 1)) + Number.EPSILON) * 100) / 100)
      setindiceRavaz1(Math.round((((kgCosecha ? kgCosecha : 0) * 1000 / (metrosDeVid ? metrosDeVid : 1) / ((pesoPodaCentro1 ? pesoPodaCentro1 : 1) / (largoCentro1 ? largoCentro1 : 1))) + Number.EPSILON) * 100) / 100)
    }
    if (unaFila == 2 || unaFila == 0) {
      setnroSarmientosxMetro2(Math.round((((nroSarmientosxCentro2 ? nroSarmientosxCentro2 : 0) / (largoCentro2 ? largoCentro2 : 1)) + Number.EPSILON) * 100) / 100)
      setpesoPodaxMetro2(Math.round((((pesoPodaCentro2 ? pesoPodaCentro2 : 0) / (largoCentro2 ? largoCentro2 : 1)) + Number.EPSILON) * 100) / 100)
      setpesoxSarmiento2(Math.round((((pesoPodaCentro2 ? pesoPodaCentro2 : 0) / (nroSarmientosxCentro2 ? nroSarmientosxCentro2 : 1)) + Number.EPSILON) * 100) / 100)
      setindiceRavaz2(Math.round((((kgCosecha ? kgCosecha : 0) * 1000 / (metrosDeVid ? metrosDeVid : 1) / ((pesoPodaCentro2 ? pesoPodaCentro2 : 1) / (largoCentro2 ? largoCentro2 : 1))) + Number.EPSILON) * 100) / 100)

    }
    if (unaFila == 3 || unaFila == 0) {
      setnroSarmientosxMetro3(Math.round((((nroSarmientosxCentro3 ? nroSarmientosxCentro3 : 0) / (largoCentro3 ? largoCentro3 : 1)) + Number.EPSILON) * 100) / 100)
      setpesoPodaxMetro3(Math.round((((pesoPodaCentro3 ? pesoPodaCentro3 : 0) / (largoCentro3 ? largoCentro3 : 1)) + Number.EPSILON) * 100) / 100)
      setpesoxSarmiento3(Math.round((((pesoPodaCentro3 ? pesoPodaCentro3 : 0) / (nroSarmientosxCentro3 ? nroSarmientosxCentro3 : 1)) + Number.EPSILON) * 100) / 100)
      setindiceRavaz3(Math.round((((kgCosecha ? kgCosecha : 0) * 1000 / (metrosDeVid ? metrosDeVid : 1) / ((pesoPodaCentro3 ? pesoPodaCentro3 : 1) / (largoCentro3 ? largoCentro3 : 1))) + Number.EPSILON) * 100) / 100)
    }
    if (unaFila == 4 || unaFila == 0) {
      setnroSarmientosxMetro4(Math.round((((nroSarmientosxCentro4 ? nroSarmientosxCentro4 : 0) / (largoCentro4 ? largoCentro4 : 1)) + Number.EPSILON) * 100) / 100)
      setpesoPodaxMetro4(Math.round((((pesoPodaCentro4 ? pesoPodaCentro4 : 0) / (largoCentro4 ? largoCentro4 : 1)) + Number.EPSILON) * 100) / 100)
      setpesoxSarmiento4(Math.round((((pesoPodaCentro4 ? pesoPodaCentro4 : 0) / (nroSarmientosxCentro4 ? nroSarmientosxCentro4 : 1)) + Number.EPSILON) * 100) / 100)
      setindiceRavaz4(Math.round((((kgCosecha ? kgCosecha : 0) * 1000 / (metrosDeVid ? metrosDeVid : 1) / ((pesoPodaCentro4 ? pesoPodaCentro4 : 1) / (largoCentro4 ? largoCentro4 : 1))) + Number.EPSILON) * 100) / 100)

    }
  }
  return (
    <Stack>
      <Stack sx={{ mb: 6 }}>
        <Box>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Para calcular el Índice de Equilibrio complete los campos para cuatro filas del cuadro seleccionado.
          </Typography>

          <form onSubmit={handleSubmit(onSubmit1)}>
            <Stack spacing={3}>
              <DatePickerField selectedDate={selectedDate} />

              <Select
                label="Viñedos"
                defaultValue="1"
                {...register("vinedo", {
                  onChange: (e) => {
                    cargaCuadros(e);
                  },
                })}
              >
                <MenuItem disabled={true} value={"1"}>
                  {"Seleccione un Viñedo"}
                </MenuItem>
                {vinedos.map((myVinedo, index) => (
                  <MenuItem key={index} value={myVinedo?.numeroInscripcion}>
                    {myVinedo?.numeroInscripcion} {myVinedo?.nombre}
                  </MenuItem>
                ))}
              </Select>

              <Select
                label="Cuadros"
                defaultValue="1"
                {...register("cuadro")}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  setMetrosdeVid(e.target.value.split("-")[2])
                  actualizaLosCalcs(0);
                  console.log("evento llega select cuadro:" + JSON.stringify(e) + " y el split: " + e.target.value.split("-")[2])
                }
                }
              >
                <MenuItem disabled={true} value={"1"}>
                  {"Seleccione un Cuadro"}
                </MenuItem>
                {cuadros.map((cuadroData, index) => (
                  <MenuItem
                    key={index}
                    value={
                      cuadroData.nombreCuadro + "-" +
                      cuadroData.nombreUva +
                      "-" +
                      cuadroData.superficie / cuadroData.distanciaFila
                    }
                  >
                    {cuadroData.nombreUva} {cuadroData.nombreCuadro}{" "}

                  </MenuItem>
                ))}
              </Select>

              <TextField
                fullWidth
                type={"number"}
                inputProps={{ step: "0.01", lang: "es-AR" }}
                label="Producción Zafra Anterior (Kgs totales del Cuadro)"
                {...register("produccionZafraAnterior")}
                placeholder="produccionZafraAnterior"
                required={true}
                help={
                  errors && errors.fila1nro
                    ? "Este campo no puede estar vacío"
                    : ""
                }
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  setKgCosecha(e.target.value)
                  actualizaLosCalcs(0);
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
                  gap: 2,
                }}
              >
                <Card sx={{ p: 3 }} variant="outlined">
                  <Stack mt={2} spacing={2}>
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Número de Fila"
                      {...register("fila1nro")}
                      placeholder="fila1nro"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }

                      onWheel={(e) => e.target.blur()}
                    />

                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Largo del Centro en mts"
                      {...register("largoCentro1")}
                      placeholder="largoCentro1"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setlargoCentro1(e.target.value)
                        actualizaLosCalcs(1);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Cantidad de sarmientos por centro"
                      {...register("nroSarmientosxCentro1")}
                      placeholder="nroSarmientosxCentro1"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setnroSarmientosxCentro1(e.target.value)
                        actualizaLosCalcs(1)
                        console.log("set nro sarmientos centro1 :" + e.target.value);
                      }}

                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda del Centro en gr"
                      {...register("pesoPodaCentro1")}
                      placeholder="pesoPodaCentro1"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }

                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setpesoPodaCentro1(e.target.value)
                        actualizaLosCalcs(1)
                      }}
                    />

                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Número de Sarmientos por Metro"
                      {...register("nroSarmientosxMetro1")}
                      placeholder="nroSarmientosxMetro1"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }

                      InputLabelProps={{ shrink: true }}
                      value={nroSarmientosxMetro1}
                    // Math.round((((nroSarmientosxCentro1 ? nroSarmientosxCentro1 : 0) / (largoCentro1 ? largoCentro1 : 1)) + Number.EPSILON) * 100) / 100}


                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda por Metro"
                      {...register("pesoPodaPorMetro1")}
                      placeholder={"pesoPodaPorMetro1"}
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      value={pesoPodaxMetro1}
                    //Math.round((((pesoPodaCentro1 ? pesoPodaCentro1 : 0) / (largoCentro1 ? largoCentro1 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda por Sarmiento "
                      {...register("pesoPorSarmiento1")}
                      placeholder="pesoPorSarmiento1"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      value={pesoxSarmiento1}
                    //{Math.round((((pesoPodaCentro1 ? pesoPodaCentro1 : 0) / (nroSarmientosxCentro1 ? nroSarmientosxCentro1 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Indice Ravaz "
                      {...register("indiceRavaz1")}
                      placeholder="indiceRavaz1"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      value={indiceRavaz1}
                    //{Math.round((((kgCosecha ? kgCosecha : 0) * 1000 / (metrosDeVid ? metrosDeVid : 1) / ((pesoPodaCentro1 ? pesoPodaCentro1 : 1) / (largoCentro1 ? largoCentro1 : 1))) + Number.EPSILON) * 100) / 100}
                    />
                  </Stack>
                </Card>

                <Card sx={{ p: 3 }} variant="outlined">
                  <Stack mt={2} spacing={2}>
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Número de Fila"
                      {...register("fila2nro")}
                      placeholder="fila2nro"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Largo del Centro en mts"
                      {...register("largoCentro2")}
                      placeholder="largoCentro2"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setlargoCentro2(e.target.value)
                        actualizaLosCalcs(2);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Cantidad de sarmientos por centro"
                      {...register("nroSarmientosxCentro2")}
                      placeholder="nroSarmientosxCentro2"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setnroSarmientosxCentro2(e.target.value)
                        actualizaLosCalcs(2);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda del Centro en gr"
                      {...register("pesoPodaCentro2")}
                      placeholder="pesoPodaCentro2"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setpesoPodaCentro2(e.target.value)
                        actualizaLosCalcs(2);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Número de Sarmientos por Metro"
                      {...register("nroSarmientosxMetro2")}
                      placeholder="nroSarmientosxMetro2"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={nroSarmientosxMetro2}
                    //{Math.round((((nroSarmientosxCentro2 ? nroSarmientosxCentro2 : 0) / (largoCentro2 ? largoCentro2 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda por Metro"
                      {...register("pesoPodaPorMetro2")}
                      placeholder="pesoPodaPorMetro2"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={pesoPodaxMetro2}
                    //{Math.round((((pesoPodaCentro2 ? pesoPodaCentro2 : 0) / (largoCentro2 ? largoCentro2 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda por Sarmiento"
                      {...register("pesoPorSarmiento2")}
                      placeholder="pesoPorSarmiento2"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={pesoxSarmiento2}
                    //{Math.round((((pesoPodaCentro2 ? pesoPodaCentro2 : 0) / (nroSarmientosxCentro2 ? nroSarmientosxCentro2 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Indice Ravaz "
                      {...register("indiceRavaz2")}
                      placeholder="indiceRavaz2"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={indiceRavaz2}
                    //{Math.round((((kgCosecha ? kgCosecha : 0) * 1000 / (metrosDeVid ? metrosDeVid : 1) / ((pesoPodaCentro2 ? pesoPodaCentro2 : 1) / (largoCentro2 ? largoCentro2 : 1))) + Number.EPSILON) * 100) / 100}
                    />
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }} variant="outlined">
                  <Stack mt={2} spacing={2}>
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Número de Fila"
                      {...register("fila3nro")}
                      placeholder="fila3nro"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Largo del Centro en mts"
                      {...register("largoCentro3")}
                      placeholder="largoCentro3"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setlargoCentro3(e.target.value)
                        actualizaLosCalcs(3);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Cantidad de sarmientos por centro"
                      {...register("nroSarmientosxCentro3")}
                      placeholder="nroSarmientosxCentro3"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setnroSarmientosxCentro3(e.target.value)
                        actualizaLosCalcs(3);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda del Centro en gr"
                      {...register("pesoPodaCentro3")}
                      placeholder="pesoPodaCentro3"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setpesoPodaCentro3(e.target.value)
                        actualizaLosCalcs(3);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Número de Sarmientos por Metro"
                      {...register("nroSarmientosxMetro3")}
                      placeholder="nroSarmientosxMetro3"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={nroSarmientosxMetro3}
                    //{Math.round((((nroSarmientosxCentro3 ? nroSarmientosxCentro3 : 0) / (largoCentro3 ? largoCentro3 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda por Metro"
                      {...register("pesoPodaPorMetro3")}
                      placeholder="pesoPodaPorMetro3"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={pesoPodaxMetro3}
                    //{Math.round((((pesoPodaCentro3 ? pesoPodaCentro3 : 0) / (largoCentro3 ? largoCentro3 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda por Sarmiento"
                      {...register("pesoPorSarmiento3")}
                      placeholder="pesoPorSarmiento3"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={pesoxSarmiento3}
                    //{Math.round((((pesoPodaCentro3 ? pesoPodaCentro3 : 0) / (nroSarmientosxCentro3 ? nroSarmientosxCentro3 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Indice Ravaz "
                      {...register("indiceRavaz3")}
                      placeholder="indiceRavaz3"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={indiceRavaz3}
                    //{Math.round((((kgCosecha ? kgCosecha : 0) * 1000 / (metrosDeVid ? metrosDeVid : 1) / ((pesoPodaCentro3 ? pesoPodaCentro3 : 1) / (largoCentro3 ? largoCentro3 : 1))) + Number.EPSILON) * 100) / 100}
                    />
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }} variant="outlined">

                  <Stack mt={2} spacing={2}>
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Número de Fila"
                      {...register("fila4nro")}
                      placeholder="fila4nro"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Largo del Centro en mts"
                      {...register("largoCentro4")}
                      placeholder="largoCentro4"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setlargoCentro4(e.target.value)
                        actualizaLosCalcs(4);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Cantidad de sarmientos por centro"
                      {...register("nroSarmientosxCentro4")}
                      placeholder="nroSarmientosxCentro4"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setnroSarmientosxCentro4(e.target.value)
                        actualizaLosCalcs(4);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda del Centro en gr"
                      {...register("pesoPodaCentro4")}
                      placeholder="pesoPodaCentro4"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setpesoPodaCentro4(e.target.value)

                        actualizaLosCalcs(4);
                      }}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Número de Sarmientos por Metro"
                      {...register("nroSarmientosxMetro4")}
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""}
                      InputLabelProps={{ shrink: true }}
                      value={nroSarmientosxMetro4}
                    //{Math.round((((nroSarmientosxCentro4 ? nroSarmientosxCentro4 : 0) / (largoCentro4 ? largoCentro4 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda por Metro"
                      {...register("pesoPodaPorMetro4")}
                      placeholder="pesoPodaPorMetro4"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={pesoPodaxMetro4}
                    //{Math.round((((pesoPodaCentro4 ? pesoPodaCentro4 : 0) / (largoCentro4 ? largoCentro4 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Peso de Poda por Sarmiento"
                      {...register("pesoPorSarmiento4")}
                      placeholder="pesoPorSarmiento4"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={pesoxSarmiento4}
                    //{Math.round((((pesoPodaCentro4 ? pesoPodaCentro4 : 0) / (nroSarmientosxCentro4 ? nroSarmientosxCentro4 : 1)) + Number.EPSILON) * 100) / 100}
                    />
                    <TextField
                      fullWidth
                      type={"number"}
                      inputProps={{ step: "0.01", lang: "es-AR" }}
                      label="Indice Ravaz "
                      {...register("indiceRavaz4")}
                      placeholder="indiceRavaz4"
                      required={true}
                      help={
                        errors && errors.fila1nro
                          ? "Este campo no puede estar vacío"
                          : ""
                      }
                      value={indiceRavaz4}

                    //{Math.round((((kgCosecha ? kgCosecha : 0) * 1000 / (metrosDeVid ? metrosDeVid : 1) / ((pesoPodaCentro4 ? pesoPodaCentro4 : 1) / (largoCentro4 ? largoCentro4 : 1))) + Number.EPSILON) * 100) / 100}
                    />
                  </Stack>
                </Card>
              </Box>
              <Stack mt={4} spacing={3}>
                <Stack spacing={3}>
                  <TextField
                    multiline
                    maxRows={6}
                    label="Observaciones"
                    {...register("observaciones")}
                    placeholder="observaciones"
                    required={false}
                    // validateStatus={errors && errors.observaciones ? "error" : "success"}
                    help={
                      errors && errors.observaciones
                        ? "Este campo no puede estar vacío"
                        : ""
                    }
                  />
                </Stack>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={enviando}
              >
                {!enviadoExito ? "Registrar Indice Equilibrio" : "Datos Guardados"}
              </LoadingButton>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Stack>
  );
}
