import { useState } from "react";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VinedosCuadrosSelector from "./VinedosCuadrosSelector";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Typography from "src/theme/overrides/Typography";
// ----------------------------------------------------------------------

export default function LluviayRiegoForm({
 
  selectedDate,
  mySelectedVinedosCuadros,
  enviarForm,
  enviadoExito
}) {
  //vinedos unidad no está funcionando
  const navigate = useNavigate();
  const { register, handleSubmit, errors, reset } = useForm();
  const [result, setResult] = useState("");
  const [enviando, setEnviando] = useState(false);

  const onSubmit = (data) => {
    if (!enviando) {
    if (mySelectedVinedosCuadros.length>0){
      enviarForm(data);
      setEnviando(true);
      }else {
          window.alert("debe seleccionar al menos un cuadro");
        }
      }
    }
   //setResult el que cambia el state
   const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
    
      setTimeout(() => {
        navigate(`/dashboard/LluviayRiego`, { replace: true });
        reset()
      }, 3000);
    }
  };
  envio();


  return (
    <>


    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <DatePickerField selectedDate={selectedDate} />

          <TextField
            fullWidth
            type="number"
            label="Horas de Riego"
            {...register("horasDeRiego")}
            placeholder="Horas de Riego"
            
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors && errors.horasRiego
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
            <TextField
            fullWidth
            type="number"
            label="Número de Emisores por Há"
            {...register("nroEmisoresPorHa")}
            placeholder="Número de Emisores por Há"
            
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors && errors.horasRiego
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
             <TextField
            fullWidth
            type="number"
            label="Litros por hora por Emisor"
            {...register("litrosPorMinutoPorEmisor")}
            placeholder="Litros por hora por Emisor"
            
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors && errors.horasRiego
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
             <TextField
            fullWidth
            type="number"
            label="Lluvia Registrada en mm"
            {...register("lluviaEnmm")}
            placeholder="lluviaEnmm"
            
            //validateStatus={errors  ? "error" : "success"}
            help={
              errors && errors.horasRiego
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        </Stack>
        <Stack spacing={3}>
          <TextField
            multiline
            maxRows={6}
            label="Observaciones"
            {...register("observaciones")}
            placeholder="observaciones"
            required={false}
            // validateStatus={errors && errors.observaciones ? "error" : "success"}
            help={
              errors && errors.observaciones
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        </Stack>
        {/* */}
        <VinedosCuadrosSelector
             mySelectedVinedosCuadros={mySelectedVinedosCuadros}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={enviando}
        >
           {!enviadoExito ?"Guardar Riego" : "Datos Guardados"}
        </LoadingButton>  
        </Stack>
    </form>
    </>
  );
}
