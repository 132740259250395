// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import { useState, useEffect } from "react";
import ReportesPorVinedo from "src/components/reportes/ReportesPorVinedo";
import axios from "axios";
import React from "react";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
import MyTableToExcel from "src/components/reportes/MyTableToExcel";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";

/*
import SeleccionarVinedosCuadros from "../Viñedos/SeleccionarVinedosCuadros";
import TablaReferencia from "../Viñedos/TablaReferencia";
import ListaUP from "../Viñedos/ListaUp";*/
import data from "@iconify/icons-eva/menu-2-fill";
// ----------------------------------------------------------------------

export default function ReporteGeneral() {

  const unidadProductiva = window.localStorage.getItem("UP");
  var todos = false;
  var cargando = false;
  var dataTodo = [];
  const [noHayDatos, setNoHayDatos] = useState(false);
  let selectedReporte = "";
  const [dataReporte, setDataReporte] = useState({
    data: "",
    columns: "",
    datos: false,
  });
  const columns = [];
  const reportesPosibles = [
    "Tratamientos Fitosanitarios",
    "Control de Hormigas",
    "Fertilizante",
    "Herbicida",
    "Manejos del Viñedo",
    "Monitoreo",
    "Fenología",
    "Lluvia y Riego",
    "Todos",
  ];

  const [nombreArchivo, setMuestraArchivo] = useState();
  const [nombreArchivoField, setNombreArchivoField] = useState(false);

  const resetearArchivo = () => {
    setTimeout(() => {
      setNombreArchivoField();
      setMuestraArchivo(false);
    }, 3000);
  };
  const escribeNombreArchivo = (e) => {
    setNombreArchivoField(e.target.value);
  };

  const muestraField = () => {
    setMuestraArchivo(true);
  };




  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">Todos</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const handleSelectedReporte = (e) => {
    selectedReporte = e.target.value;

    if (selectedReporte == "Tratamientos Fitosanitarios") {
      cargando = true;
      reporteTratamientosSanitarios();
    } else if (selectedReporte == "Todos") {
      cargando = true;
      reporteTodos();
    } else if (selectedReporte == "Control de Hormigas") {
      cargando = true;
      reporteControlHormigas();
    } else if (selectedReporte == "Herbicida") {
      cargando = true;
      reporteHerbicida();
    } else if (selectedReporte == "Fertilizante") {
      cargando = true;
      reporteFertilizante();
    } else if (selectedReporte == "Fenología") {
      cargando = true;
      reporteFenología();
    } else if (selectedReporte == "Fenología") {
      cargando = true;
      reporteFenología();
    } else if (selectedReporte == "Manejos del Viñedo") {
      cargando = true;
      reporteManejos();
    } else if (selectedReporte == "Monitoreo") {
      cargando = true;
      reporteMonitoreo();
    } else if (selectedReporte == "Lluvia y Riego") {
      cargando = true;
      reporteLluviaYRiego();
    }
  };

  ////////TRATAMIENTO SANITARIO
  const reporteTratamientosSanitarios = () => {
    const requestTratamientos =
      " /api/reportes/tratamientosSanitariosPorUP?idUP=" + unidadProductiva;

    axios.get(requestTratamientos).then(function (response) {
      //  ;
      const newDataPorVinedos = [];

      if (response.data.length > 0) {
        response.data.map((unTratamiento) => {
          /*
          calcular los metros de fila tratados TOTAL
          para cada cuadro hacemos sup del cuadro / distancia entre filas de ese cuadro, 
         sumar todo y obtener METROS DE FILAS TOTAL*/
          var metrosFilaTotal = 0;
          unTratamiento.cuadrosAplicados.map((unaListaVinedoCuadros) => {
            unaListaVinedoCuadros.cuadros.map((unCuadro) => {
              metrosFilaTotal += unCuadro.superficie / unCuadro.distanciaFila;
            });
          });

          var gastoAguaPorMetroFila = unTratamiento.gastoReal / metrosFilaTotal;
          console.log(metrosFilaTotal + "metros");
          console.log(gastoAguaPorMetroFila + "l/m");

          unTratamiento.cuadrosAplicados.map((unaListaVinedoCuadros) => {

            var gastosPorCuadros = [];
            unaListaVinedoCuadros.cuadros.map((unCuadro) => {

              var miGasto = ((gastoAguaPorMetroFila *
                (unCuadro.superficie / unCuadro.distanciaFila) *
                (unTratamiento.dosisEnCC
                  ? unTratamiento.dosisEnCC
                  : unTratamiento.dosisEnGr)) /
                100);

              if (unCuadro.nombreSistema == "LIRA") {
                miGasto = miGasto * 0.5;
                console.log("lira");
              }
              var nuevoGasto = unCuadro.nroInterno? unCuadro.nroInterno : unCuadro.nombreCuadro ;
              nuevoGasto += ":" + miGasto.toFixed(2) + (unTratamiento.dosisEnCC?" cc" : " gr");
              gastosPorCuadros += nuevoGasto + " - ";
            });

            const fechaAplicacion = unTratamiento.fechaAplicacion
              ? unTratamiento.fechaAplicacion.split("T")[0]
              : "";
            const nroVinedo = unaListaVinedoCuadros.numeroInscripcion;

            var cuadrosInavi = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro?.nombreCuadro)
              .map((unCuadro) => unCuadro?.nombreCuadro);

            /*   if (cuadrosInavi.length > 0) {
              cuadrosInavi = JSON.stringify(cuadrosInavi);
            }else{
              cuadrosInavi = JSON.stringify(unaListaVinedoCuadros.cuadros);
            }*/
            cuadrosInavi = JSON.stringify(cuadrosInavi);
            var cuadrosInternos = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro.nroInterno)
              .map((unCuadro) => unCuadro.nroInterno);

            cuadrosInternos = JSON.stringify(cuadrosInternos);

            /* if (cuadrosInternos.length > 0) {
              cuadrosInternos = JSON.stringify(cuadrosInternos);
            }else {              
              cuadrosInternos = cuadrosInavi;
              
            }*/
            const principioActivo = unTratamiento.principioActivo;
            const unNombreComercial = unTratamiento.nombreComercial;
            const esperaCosecha = unTratamiento.esperaCosecha;
            const unaEnfermedad = unTratamiento.plagaEnfermedad;
            const dosis = unTratamiento.dosisEnCC
              ? unTratamiento.dosisEnCC + " cc"
              : unTratamiento.dosisEnGr + " gr";
            const gastoReal = unTratamiento.gastoReal;
            const gastoPorCuadro = gastosPorCuadros;
            const unaAtomizadora = unTratamiento.atomizadora;
            const picos = unTratamiento.picos;
            const observaciones = unTratamiento.observaciones;
            const key = unTratamiento.id + Math.random();
            newDataPorVinedos.push({
              key: key? key : Math.random(),
              fechaAplicacion: fechaAplicacion? fechaAplicacion : "",
              nroVinedo: nroVinedo? nroVinedo : "",
              cuadrosInavi: cuadrosInavi
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              cuadrosInternos: cuadrosInternos
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              principioActivo: principioActivo? principioActivo : "",
              unNombreComercial: unNombreComercial? unNombreComercial : "",
              unaEnfermedad: unaEnfermedad? unaEnfermedad : "",
              esperaCosecha: esperaCosecha? esperaCosecha : "",
              dosis: dosis? dosis : "",
              unaAtomizadora: unaAtomizadora? unaAtomizadora : "",
              gastoReal: gastoReal? gastoReal : "",
              gastoPorCuadro: gastoPorCuadro? gastoPorCuadro : "",
              picos: picos? picos : "",
              observaciones: observaciones? observaciones : "",
            });
          });
        });

        const columns = [
          {
            Header: "Fecha de Aplicación",
            accessor: "fechaAplicacion",
          },
          {
            Header: "Numero de Viñedo",
            accessor: "nroVinedo",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Cuadros Inavi",
            accessor: "cuadrosInavi",
            // Use our custom `fuzzyText` filter on this column
            filter: "fuzzyText",
          },

          {
            Header: "Cuadros Internos",
            accessor: "cuadrosInternos",
            Filter: SelectColumnFilter,
            filter: "includes",
          },

          {
            Header: "Principio Activo",
            accessor: "principioActivo",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Nombre Comercial",
            accessor: "unNombreComercial",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Tiempo de Espera",
            accessor: "esperaCosecha",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Enfermedad o Plaga",
            accessor: "unaEnfermedad",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Dosis",
            accessor: "dosis",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Gasto Real",
            accessor: "gastoReal",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Gasto/Cuadro",
            accessor: "gastoPorCuadro",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Atomizadora",
            accessor: "unaAtomizadora",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Picos",
            accessor: "picos",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Observaciones",
            accessor: "observaciones",
            filter: "fuzzyText",
          },
        ];

        const newDataReporte = { data: newDataPorVinedos, columns: columns };
        if (todos) {
          dataTodo.push(newDataReporte);
          reporteControlHormigas();
        } else {
          setNoHayDatos(false);
          setDataReporte(newDataReporte);
          cargando = false;
        }
      } else {
        if (todos) {
          reporteControlHormigas();
        } else {
          setNoHayDatos(true);
        }
      }
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))
  };

  ////////CONTORL HORMIGAS
  const reporteControlHormigas = () => {
    const request = " /api/reportes/hormigasPorUP?idUP=" + unidadProductiva;

    axios.get(request).then(function (response) {
      //  ;
      const newDataPorVinedos = [];
      if (response.data.length > 0) {
        response.data.map((unControlHormiga) => {
          unControlHormiga.cuadrosAplicados.map((unaListaVinedoCuadros) => {
            const fechaAplicacion =
              unControlHormiga.fechaAplicacion.split("T")[0];
            const nroVinedo = unaListaVinedoCuadros.numeroInscripcion;
            var cuadrosInavi = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro?.nombreCuadro)
              .map((unCuadro) => unCuadro?.nombreCuadro);

            if (cuadrosInavi.length > 0) {
              cuadrosInavi = JSON.stringify(cuadrosInavi);
            } else {
              cuadrosInavi = JSON.stringify(unaListaVinedoCuadros.cuadros);
            }
            var cuadrosInternos = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro.nroInterno)
              .map((unCuadro) => unCuadro.nroInterno);

            if (cuadrosInternos.length > 0) {
              cuadrosInternos = JSON.stringify(cuadrosInternos);
            } else {
              cuadrosInternos = cuadrosInavi;
            }
            const principioActivo = unControlHormiga.principioActivo;
            const unNombreComercial = unControlHormiga.nombreComercial;
            const observaciones = unControlHormiga.observaciones;
            const key = unControlHormiga.id + Math.random();
            newDataPorVinedos.push({
              key: key?key:Math.random(),
              fechaAplicacion: fechaAplicacion?fechaAplicacion:"",
              nroVinedo: nroVinedo?nroVinedo:"",
              cuadrosInavi: cuadrosInavi
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              cuadrosInternos: cuadrosInternos
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              principioActivo: principioActivo?principioActivo:"",
              unNombreComercial: unNombreComercial?unNombreComercial:"",
              observaciones: observaciones?observaciones:"",
            });
          });
        });

        const columns = [
          {
            Header: "Fecha de Aplicación",
            accessor: "fechaAplicacion",
          },
          {
            Header: "Numero de Viñedo",
            accessor: "nroVinedo",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Cuadros Inavi",
            accessor: "cuadrosInavi",
            // Use our custom `fuzzyText` filter on this column
            filter: "fuzzyText",
          },

          {
            Header: "Cuadros Interno",
            accessor: "cuadrosInternos",
            Filter: SelectColumnFilter,
            filter: "includes",
          },

          {
            Header: "Principio Activo",
            accessor: "principioActivo",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Nombre Comercial",
            accessor: "unNombreComercial",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Observaciones",
            accessor: "observaciones",
            filter: "fuzzyText",
          },
        ];

        const newDataReporte = { data: newDataPorVinedos, columns: columns };
        if (todos) {
          dataTodo.push(newDataReporte);
          reporteFenología();
        } else {
          setNoHayDatos(false);
          setDataReporte(newDataReporte);
          cargando = false;
        }
      } else {
        if (todos) {
          reporteFenología();
        } else {
          setNoHayDatos(true);
        }
      }
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))
  };

  ////////HERBICIDA
  const reporteHerbicida = () => {
    const request = " /api/reportes/herbicidasPorUP?idUP=" + unidadProductiva;

    axios.get(request).then(function (response) {
      //  ;
      const newDataPorVinedos = [];
      if (response.data.length > 0) {
        response.data.map((unHerbicida) => {
          unHerbicida.cuadrosAplicados.map((unaListaVinedoCuadros) => {
            const fechaAplicacion = unHerbicida.fechaAplicacion
              ? unHerbicida.fechaAplicacion.split("T")[0]
              : "";
            const nroVinedo = unaListaVinedoCuadros.numeroInscripcion;
            var cuadrosInavi = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro?.nombreCuadro)
              .map((unCuadro) => unCuadro?.nombreCuadro);

            if (cuadrosInavi.length > 0) {
              cuadrosInavi = JSON.stringify(cuadrosInavi);
            } else {
              cuadrosInavi = JSON.stringify(unaListaVinedoCuadros.cuadros);
            }
            var cuadrosInternos = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro.nroInterno)
              .map((unCuadro) => unCuadro.nroInterno);

            if (cuadrosInternos.length > 0) {
              cuadrosInternos = JSON.stringify(cuadrosInternos);
            } else {
              cuadrosInternos = cuadrosInavi;
            }
            const principioActivo = unHerbicida.principioActivo;
            const unNombreComercial = unHerbicida.nombreComercial;
            const dosisPorHa = unHerbicida.dosisPorHectarea;
            const litrosPorHa = unHerbicida.litrosAguaPorHectarea;
            const pulverizadora = unHerbicida.pulverizadora;
            const tipoFicha = unHerbicida.tipoFicha;
            const observaciones = unHerbicida.observaciones;
            const key = unHerbicida.id + Math.random();
            newDataPorVinedos.push({
              key: key?key:Math.random(),
              fechaAplicacion: fechaAplicacion?fechaAplicacion:"",
              nroVinedo: nroVinedo?nroVinedo:"",
              cuadrosInavi: cuadrosInavi
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              cuadrosInternos: cuadrosInternos
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              principioActivo: principioActivo?principioActivo:"",
              unNombreComercial: unNombreComercial?unNombreComercial:"",
              dosisPorHa: dosisPorHa?dosisPorHa:"",
              litrosPorHa: litrosPorHa?litrosPorHa:"",
              pulverizadora: pulverizadora?pulverizadora:"",
              tipoFicha: tipoFicha?tipoFicha:"",
              observaciones: observaciones?observaciones:"",
            });
          });
        });

        const columns = [
          {
            Header: "Fecha de Aplicación",
            accessor: "fechaAplicacion",
          },
          {
            Header: "Numero de Viñedo",
            accessor: "nroVinedo",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Cuadros Inavi",
            accessor: "cuadrosInavi",
            // Use our custom `fuzzyText` filter on this column
            filter: "fuzzyText",
          },

          {
            Header: "Cuadros Interno",
            accessor: "cuadrosInternos",
            Filter: SelectColumnFilter,
            filter: "includes",
          },

          {
            Header: "Principio Activo",
            accessor: "principioActivo",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Nombre Comercial",
            accessor: "unNombreComercial",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Dosis por Há.",
            accessor: "dosisPorHa",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Litros por Há.",
            accessor: "litrosPorHa",
            Filter: SelectColumnFilter,
            filter: "includes",
          },

          {
            Header: "Pulverizadora",
            accessor: "pulverizadora",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Tipo de Ficha",
            accessor: "tipoFicha",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Observaciones",
            accessor: "observaciones",
            filter: "fuzzyText",
          },
        ];

        const newDataReporte = { data: newDataPorVinedos, columns: columns };
        if (todos) {
          dataTodo.push(newDataReporte);
          reporteManejos();
        } else {
          setNoHayDatos(false);
          setDataReporte(newDataReporte);
        }
        cargando = false;
      } else {
        if (todos) {
          reporteManejos();
        } else {
          setNoHayDatos(true);
        }
      }
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))
  };

  ///////FERTILIZANTE
  const reporteFertilizante = () => {
    const request = "/api/reportes/fertilizantesPorUP?idUP=" + unidadProductiva;

    axios.get(request).then(function (response) {
      //  ;
      const newDataPorVinedos = [];
      if (response.data.length > 0) {
        response.data.map((unFertilizante) => {
          unFertilizante.cuadrosAplicados.map((unaListaVinedoCuadros) => {
            const fechaAplicacion = unFertilizante.fechaAplicacion
              ? unFertilizante.fechaAplicacion.split("T")[0]
              : "";
            const nroVinedo = unaListaVinedoCuadros.numeroInscripcion;
            var cuadrosInavi = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro?.nombreCuadro)
              .map((unCuadro) => unCuadro?.nombreCuadro);

            if (cuadrosInavi.length > 0) {
              cuadrosInavi = JSON.stringify(cuadrosInavi);
            } else {
              cuadrosInavi = JSON.stringify(unaListaVinedoCuadros.cuadros);
            }
            var cuadrosInternos = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro.nroInterno)
              .map((unCuadro) => unCuadro.nroInterno);

            if (cuadrosInternos.length > 0) {
              cuadrosInternos = JSON.stringify(cuadrosInternos);
            } else {
              cuadrosInternos = cuadrosInavi;
            }
            const kilosPorHectarea = unFertilizante.kilosPorHectarea;
            const fertilizante = unFertilizante.nombre;
            const formaAplicacion = unFertilizante.formaAplicacion;
            const observaciones = unFertilizante.observaciones;
            const key = unFertilizante.id + Math.random();
            newDataPorVinedos.push({
              key: key? key:Math.random(),
              fechaAplicacion: fechaAplicacion? fechaAplicacion:"",
              nroVinedo: nroVinedo? nroVinedo:"",
              cuadrosInavi: cuadrosInavi
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              cuadrosInternos: cuadrosInternos
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              kilosPorHectarea: kilosPorHectarea? kilosPorHectarea:"",
              fertilizante: fertilizante? fertilizante:"",
              formaAplicacion: formaAplicacion?formaAplicacion:"",
              observaciones: observaciones?observaciones:"",
            });
          });
        });

        const columns = [
          {
            Header: "Fecha de Aplicación",
            accessor: "fechaAplicacion",
          },
          {
            Header: "Numero de Viñedo",
            accessor: "nroVinedo",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Cuadros Inavi",
            accessor: "cuadrosInavi",
            // Use our custom `fuzzyText` filter on this column
            filter: "fuzzyText",
          },

          {
            Header: "Cuadros Interno",
            accessor: "cuadrosInternos",
            Filter: SelectColumnFilter,
            filter: "includes",
          },

          {
            Header: "Fertilizante",
            accessor: "fertilizante",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Kilos por Hectárea",
            accessor: "kilosPorHectarea",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Forma de Aplicación",
            accessor: "formaAplicacion",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Observaciones",
            accessor: "observaciones",
            filter: "fuzzyText",
          },
        ];

        const newDataReporte = { data: newDataPorVinedos, columns: columns };
        if (todos) {
          dataTodo.push(newDataReporte);
          reporteHerbicida();
        } else {
          setNoHayDatos(false);
          setDataReporte(newDataReporte);
          cargando = false;
        }
      } else {
        if (todos) {
          reporteHerbicida();
        } else {
          setNoHayDatos(true);
        }
      }
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))
  };

  ///////ESTADO FENOLÓGICO
  const reporteFenología = () => {
    const request = " /api/reportes/fenologiasPorUP?idUP=" + unidadProductiva;

    axios.get(request).then(function (response) {
      //  ;
      const newDataPorVinedos = [];
      if (response.data.length > 0) {
        response.data.map((unEstadoFenologico) => {
          unEstadoFenologico.cuadrosAplicados.map((unaListaVinedoCuadros) => {
            const fechaRegistro = unEstadoFenologico.fechaRegistro
              ? unEstadoFenologico.fechaRegistro.split("T")[0]
              : "";
            const nroVinedo = unaListaVinedoCuadros.numeroInscripcion;
            var cuadrosInavi = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro?.nombreCuadro)
              .map((unCuadro) => unCuadro?.nombreCuadro);

            if (cuadrosInavi.length > 0) {
              cuadrosInavi = JSON.stringify(cuadrosInavi);
            } else {
              cuadrosInavi = JSON.stringify(unaListaVinedoCuadros.cuadros);
            }
            var cuadrosInternos = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro.nroInterno)
              .map((unCuadro) => unCuadro.nroInterno);

            if (cuadrosInternos.length > 0) {
              cuadrosInternos = JSON.stringify(cuadrosInternos);
            } else {
              cuadrosInternos = cuadrosInavi;
            }
            const estadoFenologico = unEstadoFenologico.estadoFenologico;
            const kilosCosechados = unEstadoFenologico.kilosCosechados
              ? unEstadoFenologico.kilosCosechados
              : "";
            const observaciones = unEstadoFenologico.observaciones;
            const key = unEstadoFenologico.id + Math.random();
            newDataPorVinedos.push({
              key: key?key:"",
              fechaRegistro: fechaRegistro?fechaRegistro:"",
              nroVinedo: nroVinedo?nroVinedo:"",
              cuadrosInavi: cuadrosInavi
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              cuadrosInternos: cuadrosInternos
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              estadoFenologico: estadoFenologico?estadoFenologico:"",
              kilosCosechados: kilosCosechados?kilosCosechados:"",
              observaciones: observaciones?observaciones:"",
            });
          });
        });

        const columns = [
          {
            Header: "Fecha de Registro",
            accessor: "fechaRegistro",
          },
          {
            Header: "Numero de Viñedo",
            accessor: "nroVinedo",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Cuadros Inavi",
            accessor: "cuadrosInavi",
            // Use our custom `fuzzyText` filter on this column
            filter: "fuzzyText",
          },

          {
            Header: "Cuadros Interno",
            accessor: "cuadrosInternos",
            Filter: SelectColumnFilter,
            filter: "includes",
          },

          {
            Header: "Kilos Cosechados",
            accessor: "kilosCosechados",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Estado Fenologico",
            accessor: "estadoFenologico",
            Filter: SelectColumnFilter,
            filter: "includes",
          },

          {
            Header: "Observaciones",
            accessor: "observaciones",
            filter: "fuzzyText",
          },
        ];

        const newDataReporte = { data: newDataPorVinedos, columns: columns };
        if (todos) {
          dataTodo.push(newDataReporte);
          reporteFertilizante();
        } else {
          setNoHayDatos(false);
          setDataReporte(newDataReporte);
          cargando = false;
        }
      } else {
        if (todos) {
          reporteFertilizante();
        } else {
          setNoHayDatos(true);
        }
      }
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))
  };

  ///////MANEJOS del Viñedo
  const reporteManejos = () => {
    const request = " /api/reportes/manejosPorUP?idUP=" + unidadProductiva;

    axios.get(request).then(function (response) {
      //  ;
      const newDataPorVinedos = [];
      if (response.data.length > 0) {
        response.data.map((unManejo) => {
          unManejo.cuadrosAplicados.map((unaListaVinedoCuadros) => {
            const fechaRegistro = unManejo.fechaRegistro
              ? unManejo.fechaRegistro.split("T")[0]
              : "";
            const nroVinedo = unaListaVinedoCuadros.numeroInscripcion;
            var cuadrosInavi = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro?.nombreCuadro)
              .map((unCuadro) => unCuadro?.nombreCuadro);

            if (cuadrosInavi.length > 0) {
              cuadrosInavi = JSON.stringify(cuadrosInavi);
            } else {
              cuadrosInavi = JSON.stringify(unaListaVinedoCuadros.cuadros);
            }
            var cuadrosInternos = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro.nroInterno)
              .map((unCuadro) => unCuadro.nroInterno);

            if (cuadrosInternos.length > 0) {
              cuadrosInternos = JSON.stringify(cuadrosInternos);
            } else {
              cuadrosInternos = cuadrosInavi;
            }
            const manejoSobrePlanta = unManejo.manejoSobrePlanta.map(
              (unManejo) => unManejo + ", "
            );
            const manejoSobreSuelo = unManejo.manejoSobreSuelo.map(
              (unManejo) => unManejo + ", "
            );
            const observaciones = unManejo.observaciones;
            const key = unManejo.id + Math.random();
            newDataPorVinedos.push({
              key: key?key:Math.random(),
              fechaRegistro: fechaRegistro?fechaRegistro:"",
              nroVinedo: nroVinedo?nroVinedo:"",
              cuadrosInavi: cuadrosInavi
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              cuadrosInternos: cuadrosInternos
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              manejoSobrePlanta: manejoSobrePlanta?manejoSobrePlanta:"",
              manejoSobreSuelo: manejoSobreSuelo?manejoSobreSuelo:"",
              observaciones: observaciones?observaciones:"",
            });
          });
        });

        const columns = [
          {
            Header: "Fecha de Registro",
            accessor: "fechaRegistro",
          },
          {
            Header: "Numero de Viñedo",
            accessor: "nroVinedo",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Cuadros Inavi",
            accessor: "cuadrosInavi",
            // Use our custom `fuzzyText` filter on this column
            filter: "fuzzyText",
          },

          {
            Header: "Cuadros Interno",
            accessor: "cuadrosInternos",
            Filter: SelectColumnFilter,
            filter: "includes",
          },

          {
            Header: "Manejo Sobre Planta",
            accessor: "manejoSobrePlanta",
            filter: "fuzzyText",
          },
          {
            Header: "Manejo Sobre Suelo",
            accessor: "manejoSobreSuelo",
            filter: "fuzzyText",
          },

          {
            Header: "Observaciones",
            accessor: "observaciones",
            filter: "fuzzyText",
          },
        ];

        const newDataReporte = { data: newDataPorVinedos, columns: columns };

        if (todos) {
          dataTodo.push(newDataReporte);
          reporteMonitoreo();
        } else {
          setNoHayDatos(false);
          setDataReporte(newDataReporte);
          cargando = false;
        }
      } else {
        if (todos) {
          reporteMonitoreo();
        } else {
          setNoHayDatos(true);
        }
      }
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))
  };

  ///////MONITOREO del Viñedo
  const reporteMonitoreo = () => {
    const request = " /api/reportes/monitoreosPorUP?idUP=" + unidadProductiva;

    axios.get(request).then(function (response) {
      //  ;
      const newDataPorVinedos = [];
      if (response.data.length > 0) {
        response.data.map((unMonitoreo) => {
          const fechaRegistro = unMonitoreo.fechaRegistro
            ? unMonitoreo.fechaRegistro.split("T")[0]
            : "";
          const nroVinedo = unMonitoreo.vinedo;
          const cuadro = JSON.stringify(unMonitoreo.cuadro);
          const plaga = unMonitoreo.plaga;
          const numeroTrampa = unMonitoreo.numeroTrampa;
          const nroCapturas = unMonitoreo.nroCapturas;
          const observaciones = unMonitoreo.observaciones;
          const key = unMonitoreo.id + Math.random();
          newDataPorVinedos.push({
            key: key?key:Math.random(),
            fechaRegistro: fechaRegistro?fechaRegistro:"",
            nroVinedo: nroVinedo?nroVinedo:"",
            cuadro: cuadro?cuadro:"",
            numeroTrampa: numeroTrampa?numeroTrampa:"",
            nroCapturas: nroCapturas?nroCapturas:"",
            plaga: plaga?plaga:"",
            observaciones: observaciones?observaciones:"",
          });
        });

        const columns = [
          {
            Header: "Fecha de Registro",
            accessor: "fechaRegistro",
          },
          {
            Header: "Numero de Viñedo",
            accessor: "nroVinedo",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Cuadro",
            accessor: "cuadro",
            // Use our custom `fuzzyText` filter on this column
            filter: "fuzzyText",
          },

          {
            Header: "plaga",
            accessor: "plaga",
            filter: "fuzzyText",
          },
          {
            Header: "Número de trampa",
            accessor: "numeroTrampa",
            filter: "fuzzyText",
          },
          {
            Header: "Cantidad de Capturas",
            accessor: "nroCapturas",
            filter: "fuzzyText",
          },

          {
            Header: "Observaciones",
            accessor: "observaciones",
            filter: "fuzzyText",
          },
        ];

        const newDataReporte = { data: newDataPorVinedos, columns: columns };

        if (todos) {
          dataTodo.push(newDataReporte);
          reporteLluviaYRiego();
        } else {
          setNoHayDatos(false);

          setDataReporte(newDataReporte);

          cargando = false;
        }
      } else {
        if (todos) {
          reporteLluviaYRiego();
        } else {
          setNoHayDatos(true);
        }
      }
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))
  };

  const reporteLluviaYRiego = () => {
    const request = " /api/reportes/lluviaYRiegoPorUP?idUP=" + unidadProductiva;

    axios.get(request).then(function (response) {
      //  ;
      const newDataPorVinedos = [];
      if (response.data.length > 0) {
        response.data.map((unLluviaYriego) => {
          unLluviaYriego.cuadrosAplicados.map((unaListaVinedoCuadros) => {
            const fechaAplicacion =
              unLluviaYriego.fechaAplicacion.split("T")[0];
            const nroVinedo = unaListaVinedoCuadros.numeroInscripcion;
            var cuadrosInavi = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro?.nombreCuadro)
              .map((unCuadro) => unCuadro?.nombreCuadro);

            if (cuadrosInavi.length > 0) {
              cuadrosInavi = JSON.stringify(cuadrosInavi);
            } else {
              cuadrosInavi = JSON.stringify(unaListaVinedoCuadros.cuadros);
            }
            var cuadrosInternos = unaListaVinedoCuadros.cuadros
              .filter((unCuadro) => unCuadro.nroInterno)
              .map((unCuadro) => unCuadro.nroInterno);

            if (cuadrosInternos.length > 0) {
              cuadrosInternos = JSON.stringify(cuadrosInternos);
            } else {
              cuadrosInternos = cuadrosInavi;
            }
            const horasDeRiego = unLluviaYriego.horasDeRiego;
            const nroEmisoresPorHa = unLluviaYriego.nroEmisoresPorHa;
            const litrosPorMinutoPorEmisor =
              unLluviaYriego.litrosPorMinutoPorEmisor;
            const lluviaEnmm = unLluviaYriego.lluviaEnmm
            const observaciones = unLluviaYriego.observaciones;
            const key = unLluviaYriego.id + Math.random();
            newDataPorVinedos.push({
              key: key? key : Math.random(),
              fechaAplicacion: fechaAplicacion? fechaAplicacion : "",
              nroVinedo: nroVinedo? nroVinedo : "",
              cuadrosInavi: cuadrosInavi
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              cuadrosInternos: cuadrosInternos
                .replace(/\"/g, "")
                .replace(/\[/g, "")
                .replace(/\]/g, "")
                .replace(/\,/g, ", "),
              horasDeRiego: horasDeRiego? horasDeRiego : "",
              nroEmisoresPorHa: nroEmisoresPorHa? nroEmisoresPorHa : "",
              litrosPorMinutoPorEmisor: litrosPorMinutoPorEmisor? litrosPorMinutoPorEmisor : "",
              lluviaEnmm: lluviaEnmm? lluviaEnmm : "",
              observaciones: observaciones? observaciones : "",
            });
          });
        });

        const columns = [
          {
            Header: "Fecha de Aplicación",
            accessor: "fechaAplicacion",
          },
          {
            Header: "Numero de Viñedo",
            accessor: "nroVinedo",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Cuadros Inavi",
            accessor: "cuadrosInavi",
            // Use our custom `fuzzyText` filter on this column
            filter: "fuzzyText",
          },

          {
            Header: "Cuadros Interno",
            accessor: "cuadrosInternos",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Lluvias en mm",
            accessor: "lluviaEnmm",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Horas de Riego",
            accessor: "horasDeRiego",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "nro Emisores/Há",
            accessor: "nroEmisoresPorHa",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "l/min por Emisor",
            accessor: "litrosPorMinutoPorEmisor",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Observaciones",
            accessor: "observaciones",
            filter: "fuzzyText",
          },
        ];

        const newDataReporte = { data: newDataPorVinedos, columns: columns };
        if (todos) {
          dataTodo.push(newDataReporte);
          tieneDataArmarReporteTodos();
        } else {
          setNoHayDatos(false);
          setDataReporte(newDataReporte);
          cargando = false;
        }
      } else {
        if (todos) {
          tieneDataArmarReporteTodos();
        } else {
          setNoHayDatos(true);
        }
      }
    }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))
  };

  const reporteTodos = () => {
    todos = true;
    //activa cadena
    reporteTratamientosSanitarios();
  };

  const tieneDataArmarReporteTodos = () => {
    var dataTodoxColData = [];

    if (dataTodo.length > 0) {
      dataTodoxColData = { data: [], columns: [] };
      dataTodo.map((unaEntrada) => {
        //fechaAplicacion, nroVinedo, cuadros, principioActivo, unNombreComercial, observaciones
        unaEntrada.columns.map((unaColumna, index) => {
          var repetido = false;
          dataTodoxColData.columns.map((otraColumnaTabla, otroIndex) => {
            if (unaColumna?.accessor == otraColumnaTabla?.accessor) {
              repetido = true;
            }
          });
          if (!repetido) {
            dataTodoxColData.columns.push(unaColumna);
          }
        });
        dataTodoxColData.data = [...dataTodoxColData.data, ...unaEntrada.data];
        /*if (!repetido) {
          dataTodoxColData.data = [
            ...dataTodoxColData.data,
            ...unaEntrada.data,
          ];
          dataTodoxColData.columns = [
            ...dataTodoxColData.columns,
            ...unaEntrada.columns,
          ];
        }*/
      });

      setNoHayDatos(false);
      setDataReporte(dataTodoxColData);
      cargando = false;
    }
  };

  return (
    <>
      <Box>
        <Stack direction={"row"} justify={"flex-end"} spacing={2}>
          <Select
            labelId="reportes-select-label"
            onChange={handleSelectedReporte}
            label="reportes"
            defaultValue={1}
          >
            <MenuItem disabled={true} value={"1"}>
              {"Seleccione un Reporte"}
            </MenuItem>
            {reportesPosibles.map((unReporte, index) => (
              <MenuItem key={index} value={unReporte}>
                {unReporte}
              </MenuItem>
            ))}
          </Select>

          {dataReporte.data?.length > 0 ? (
            <Button sx={{ width: "20%" }} xs={1} onClick={muestraField}>
              Descargar Excel
            </Button> ) : null}
          {nombreArchivo ? (
            <TextField
              size="medium"
              placeholder="Nombre del Archivo"
              onChange={escribeNombreArchivo}
              type="text"
            ></TextField>
          ) : null}
          {nombreArchivoField ? (
            <div onClick={resetearArchivo}>
              <MyTableToExcel
                sx={{ width: "20%" }}
                xs={1}
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-success mb-3"
                table="myTableID"
                filename={nombreArchivoField}
                sheet="tablexls"
                buttonText="Guardar Archivo"
              />{" "}
            </div>
          ) : null}{" "}
       
   
        </Stack>
        {cargando ? (
          <Typography className="hidden">Cargando datos...</Typography>
        ) : null}
        {noHayDatos ? (
          <Typography className="hidden">No hay datos ingresados...</Typography>
        ) : (
          <ReportesPorVinedo
            data={dataReporte.data}
            columns={dataReporte.columns}
          />
        )}
      </Box>
    </>
  );
}
