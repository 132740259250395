import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) { 
  return <Box component="img" src="/static/prodintico.png" sx={{display:'flex',justifyContent:'center',  width: 100, height: 100}} />;
}
