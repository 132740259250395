import { useState, useEffect } from "react";
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Link as RouterLink, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
axios.defaults.timeout === 5000;
export default function NuevoUsuarioForm() {
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting } = useForm();


  const onSubmit1 = (values) => {
    const datos = { user: values.user, up: values.up, password:values.password, password2:values.password2 }
    /* console.log(datos)*/
if(datos.password ===datos.password2){
    try {
      axios
        .post(
          `api/user/nuevoUsuario/`, datos
        )
        .then((response) => {

          if (response.status == "200") {
           /* window.localStorage.setItem("user", values.user);*/
            /* console.log(response)*/
           /* window.localStorage.setItem("listaUPs", response.data);*/
            console.log(response.data)
            
            window.alert("Usuario creado exitosamente. Ya puede ingresar usando sus credenciales.");
            navigate(`/login`, { replace: true });
          } else {

            console.log(JSON.stringify(response))
            window.alert("No existe el usuario");
          }
        }).catch((err) =>  window.alert("No se pudo establecer la conexión con el servidor"))
    } catch (error) {
      console.error(error.response.data);

     ;// NOTE - use "error.response.data` (not "error")
    }}
    else {
      
      window.alert("Las constraseñas no coinciden");
    }




  }; //setResult el que cambia el state

  return (
    <Stack sx={{ mb: 6 }}>
      <Box>
        <form onSubmit={handleSubmit(onSubmit1)}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type={"text"}
              label="Usuario"
              {...register("user")}
              placeholder="usuario"
              required={true}
              //validateStatus={errors  ? "error" : "success"}
              help={
                errors 
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />
                 <TextField
              fullWidth
              type={"text"}
              label="Unidad Productiva"
              {...register("up")}
              placeholder="unidad productiva"
              required={true}
              //validateStatus={errors  ? "error" : "success"}
              help={
                errors 
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />
     
            <TextField
              fullWidth
              type={"password"}
              label="Contraseña"
              {...register("password")}
              placeholder="contraseña"
              required={true}
              //validateStatus={errors  ? "error" : "success"}
              help={
                errors 
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />
              <TextField
              fullWidth
              type={"password"}
              label="Repita su Contraseña"
              {...register("password2")}
              placeholder="repita su contraseña"
              required={true}
              //validateStatus={errors  ? "error" : "success"}
              help={
                errors 
                  ? "Este campo no puede estar vacío"
                  : ""
              }
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Registrar Nuevo Usuario
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
}
