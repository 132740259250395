// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import ReporteGeneral from "../components/reportes/ReporteGeneral";
import {useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  const currentUser= window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  useEffect(() => {
  if ( currentUser== null) {
     ;
    navigate(`/login`, { replace: true });
  } else if (selectedUP == null) {
     ;
    navigate(`/unidades-productivas`, { replace: true });
  }
}, []);
  

  return (
    <Page title="Cuaderno Digital Frutícola">
      <Container component="div" justify="flex-end">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h6">
             Reportes - Unidad Productiva {selectedUP}
          </Typography>
        </Box>
        <ReporteGeneral  />

      </Container>
    </Page>
  );
}
