import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CuadrosSelector from "./CuadrosSelector";
import { useState } from "react";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function VinedosCuadrosSelector({ mySelectedVinedosCuadros }) {
  //vinedos unidad no está funcionando

  const losVinedos = JSON.parse(window.localStorage.getItem("vinedos"));
  const [vinedos, setVinedos] = useState([...losVinedos]);
  const usarInterno = true;
  vinedos.map(function (unVinedo, index) {
    unVinedo.cuadros.map(function (cuadroData, index) {
      cuadroData.selected = false;
    });
  });

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function addRemoveCuadro(unVinedoNroInscripcion, unCuadro) {
    /* console.log(unCuadro);*/
    if (mySelectedVinedosCuadros.length > 0) {
      const daVinedo = mySelectedVinedosCuadros.find(
        (iteraVinedo) =>
          iteraVinedo.numeroInscripcion === unVinedoNroInscripcion
      );
      if (daVinedo) {
        const daCuadro = daVinedo.cuadros.find(
          (iteraCuadro) => iteraCuadro === unCuadro
        );
        if (daCuadro) {
          const index = daVinedo.cuadros.indexOf(daCuadro);
          if (index > -1) {
            daVinedo.cuadros.splice(index, 1);
            if (!daVinedo.cuadros.length > 0) {
              const index2 = mySelectedVinedosCuadros.indexOf(daVinedo);
              if (index2 > -1) {
                mySelectedVinedosCuadros.splice(daVinedo, 1);
              }
            }
          }
        } else {
          daVinedo.cuadros.push(unCuadro);
          /*daVinedo.cuadrosInternos.push()*/
        }
      } else {
        mySelectedVinedosCuadros.push({
          numeroInscripcion: unVinedoNroInscripcion,
          cuadros: [unCuadro],
        });
      }
    } else {
      /*  mySelectedVinedosCuadros = new Array();*/
      mySelectedVinedosCuadros.push({
        numeroInscripcion: unVinedoNroInscripcion,
        cuadros: [unCuadro],
      });
    }
  }

  const saveAgain = () => {};

  return (
    <Box>
      {vinedos.map(function (unVinedo, index) {
        return (
          <CuadrosSelector
            unVinedo={unVinedo}
            addRemoveCuadro={addRemoveCuadro}
            key={index}
          />
        );
      })}
    </Box>
  );
}
