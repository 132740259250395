import { useEffect, useState } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from '@mui/material/TableContainer';
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Typography from "@mui/material/Typography";
import { forEach, keysIn } from "lodash";
import "./NuevaTabla.css"
import { Button, TextField } from "@mui/material";
import { Stack } from "@mui/material";

var stylingObject = {
    myCell: {
        color: "Black",
        border: "1px solid red",
        fontSize: "1.25rem"
    }, input: {
        margin: "2px",
        padding: "5px"
    }
}

export default function NuevaTabla({ tableData, updateTableData, editable,
    guardarCambioFila, guardarNuevo, eliminarFila, crearflag, funcionCrear, queDatos }) {
    if (!tableData) return null
    crearflag = true;
    var column = Object.keys(tableData[0]);
    if (editable) column.unshift("Editar");
    var tableDataAux = tableData;
    const [editando, setEditando] = useState(new Array(tableData.length).fill(false));




    const clickEditar = (rowIndex) => (event) => {
        if (editando[rowIndex]) {
            /*   console.log(unaTablaVinedo);
               setTablaVinedo(unaTablaVinedo);*/
            var elda = tableDataAux[rowIndex]
            console.log(elda);
            guardarCambioFila(elda);
        }
        var newArray = [...editando];
        newArray[rowIndex] = !newArray[rowIndex];
        setEditando([...newArray]);
    };

    const changeValueInterno = (index, keyName) => (event) => {
        tableDataAux = [...tableData];
        tableDataAux[index][keyName] = event.target.value;
        //  updateTableData(tableDataAux);
    };



    const tableHeader = () => {
        return column.map((unKeyName, index) => {
            if (!(unKeyName === "_id" || unKeyName === "__v" || unKeyName === "id")) {
                return (
                    <TableCell align={"center"} key={unKeyName + index}>

                        {unKeyName}</TableCell>)
            } else return null
        })
    }
    // get table row data
    const tableBody = () => {

        return (tableData.map((unRow, rowIndex) => {
            return (

                <TableRow hover role="checkbox" tabIndex={-1} key={"row" + rowIndex}>
                    {editable ?
                        <TableCell align={"center"}>
                            <Button onClick={clickEditar(rowIndex)} key={"editButton" + rowIndex}>
                                {" "}
                                {editando[rowIndex] ? "Guardar" : "Editar"}
                            </Button>
                        </TableCell> : null}
                    {column.map((unKeyName, colIndex) => {
                        var unValor;
                        if (!(unKeyName === "_id" || unKeyName === "__v" || unKeyName === "Editar" || unKeyName === "id")) {
                            if (Array.isArray(unRow[unKeyName])) {
                                unValor = ""
                                forEach(unRow[unKeyName], function (value, key) {
                                    unValor += value + ", "
                                })
                            } else {
                                unValor = unRow[unKeyName]
                            }
                            return (
                                <TableCell sx={{ fontSize: "2rem" }} className="myCell" align={"center"} key={unKeyName + colIndex}>
                                    {editando[rowIndex] ? (
                                        <TextField
                                            key={rowIndex + unValor}
                                            name={rowIndex + unValor}
                                            type={"text"}
                                            
                                            placeholder={unValor}
                                            size="small"
                                            onChange={changeValueInterno(rowIndex, unKeyName)}
                                            inputProps={{
                                                style: {
                                                    paddingTop: 2.5,
                                                    paddingBottom: 2.5,
                                                    paddingLeft: 6.5,
                                                    paddingRight: 6.5,

                                                    fontSize: "1em",
                                                },
                                            }}
                                        />
                                    ) : (
                                        unValor
                                    )}
                                </TableCell>
                            )

                        } else return null
                    })}
                </TableRow>
            )
        }))
    }

    var nuevoDato = {
        user: "", ups: ""
    }
    const creaNuevoDato = (index, unKeyName) => (event) => {

        nuevoDato[unKeyName] = event.target.value;
        console.log (nuevoDato)
    }

    const clickCrear = () => (event) => {
        if (nuevoDato.user!="" && nuevoDato.ups!="") {
            funcionCrear(nuevoDato)
        }
        
    }


const secciónCrear = () => {
    if (crearflag) {
        return ([
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            
                
                {column.map((unKeyName, index) => {

                    if (!(unKeyName === "_id" || unKeyName === "__v" || unKeyName === "Editar" || unKeyName === "id")) {
                        return (
                            <div  key={unKeyName+index+"damelaki"}>

                                <Typography key={unKeyName+index+"label"} variant="h8" mr="1">{unKeyName=="user"?"Nombre Usuario":unKeyName=="ups"?"Nro UP":unKeyName}</Typography>
                                <TextField
                                    key={index + unKeyName + "editar"}
                                    name={index + unKeyName}
                                    type={"text"}
                                    placeholder={"nuevo"}
                                    size="small"
                                    onChange={creaNuevoDato(index, unKeyName)}
                                    inputProps={{
                                        style: {
                                            paddingTop: 2.5,
                                            paddingBottom: 2.5,
                                            paddingLeft: 7.5,
                                            paddingRight: 6.5,
                                            marginTop: 3.5,
                                            marginBottom: 3.5,
                                            fontSize: "1em",
                                            marginLeft:"1em"
                                        },
                                    }}
                                />

                            </div>
                        )
                    }

                })}
                    <Button key={"crearButton"} variant="contained" onClick={clickCrear()}>
                    {"Crear"}

                </Button>
            </Stack>])

    } else return null
}
return (




    <Paper sx={{ width: '100%' }}>

        {secciónCrear()}

        <TableContainer >
            <Table stickyHeader aria-label="sticky table" >
                <TableHead >
                    <TableRow>
                        {tableHeader()}</TableRow>
                </TableHead>
                <TableBody>

                    {tableBody()}
                </TableBody>

            </Table>
        </TableContainer>
    </Paper>
);
}

