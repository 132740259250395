import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { Card } from "@material-ui/core";
import Cuadro from "./Cuadro";
import { FormControlLabel } from "@mui/material";
import { Switch } from "@mui/material";

export default function CuadrosSelector({ unVinedo, addRemoveCuadro }) {
  /* ;*/

  const [unVinedoSelectedCuadros, setSelectedCuadros] = useState([
    ...unVinedo.cuadros,
  ]);

  const [allSelected, setAllSelected] = useState(false);

  const [unColor, setColor] = useState("primary");
  const [usarInterno, setUsarInterno] = useState(false)
  // ;

  const selectAllCuadros = () => {
    if (!allSelected) {
      unVinedoSelectedCuadros.map(function (unCuadro, index) {
        unCuadro.selected = true;
        addRemoveCuadro(unVinedo.numeroInscripcion, unCuadro);
      });
      setSelectedCuadros([...unVinedoSelectedCuadros]);
      unVinedo.cuadros = unVinedoSelectedCuadros;
      setAllSelected(true);
    } else {
      unVinedoSelectedCuadros.map(function (unCuadro, index) {
        unCuadro.selected = false;
        addRemoveCuadro(unVinedo.numeroInscripcion, unCuadro);
      });
      setSelectedCuadros([...unVinedoSelectedCuadros]);
      unVinedo.cuadros = unVinedoSelectedCuadros;
      setAllSelected(false);

    }

  };

  const meClickearon = (unCuadro) => {
    unCuadro.selected = !unCuadro.selected;
    setSelectedCuadros([...unVinedoSelectedCuadros]);
    addRemoveCuadro(unVinedo.numeroInscripcion, unCuadro);
    /*

    
    niuarrei.map((unCuadro) => {
      if (
        unCuadro.nombreCuadro == cuadro.nombreCuadro &&
        unCuadro.nombreUva == cuadro.nombreUva 
      ) {
        unCuadro.selected = !unCuadro.selected;
        addRemoveCuadro(unVinedo.numeroInscripcion, cuadro.nombreCuadro);
      }
    });
    setSelectedCuadros([...niuarrei]);
    */
  };

  const vinedosCuadrosButtons = [];

  const changeInterno = ()=>{
    var toggle = !usarInterno;
    setUsarInterno(toggle)
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Button onClick={selectAllCuadros} style={allSelected?{color:"white", backgroundColor:"green"}:{}}>
        Viñedo Nro: {unVinedo?.numeroInscripcion} {unVinedo?.nombre} - Marcar
        todos los cuadros
      </Button>

      <FormControlLabel  fontSize="small" control={<Switch size="small" checked={usarInterno} onChange={changeInterno}/>} label="Usar Nro Interno" />
   
      <Box>
        <Grid container columns={{ xs: 20, sm: 20, md: 20 }} sx={{ mt: 1 }}>
          {unVinedoSelectedCuadros.map(function (unCuadro, index) {
            //   ;
            return (
              <Grid item key={index}>
                <Cuadro
                  unCuadro={unCuadro}
                  meClickearon={meClickearon}
                  key={index}
                  usarInterno={usarInterno}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}
