
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
import { useNavigate } from 'react-router-dom';
// components
import Page from "../components/Page";
import { useState, useEffect } from "react";
import axios from "axios";
import { MHidden } from "../components/@material-extend";
import { Button } from "@mui/material";
import { Stack, TextField } from "@mui/material";
import NuevaTabla from "src/components/nuevaTabla/NuevaTabla";
// ----------------------------------------------------------------------
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



export default function TableroAdmin() {

  // ----------------------------------------------------------------------
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.target);
    console.log(event.target)
    console.log("llama a open")
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
    console.log("llama a close")
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.target);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.target);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const open4 = Boolean(anchorEl4);
  const handleClick4 = (event) => {
    setAnchorEl4(event.target);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };





  const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  }));

  const SectionStyle = styled(Card)(({ theme }) => ({
    width: "100%",
    maxWidth: 464,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 2, 2),
  }));

  const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
  }));

  // ----------------------------------------------------------------------

  // ;


  const navigate = useNavigate();

  const currentUser = window.localStorage.getItem("userAdmin");

  const [enviadoExito, setEnviadoExito] = useState(false);

  const [resultado, setResultado] = useState([])

  const [editable, setEditable] = useState(false)

  const [queDatos, setQueDatos] = useState("")

  const [crear, setCrear] = useState(false)

  useEffect(() => {
    if (currentUser == null) {
      navigate(`/login`, { replace: true });
    }
  }, []);

  const updateTableData = (tableAux) => {
    setResultado(tableAux)
  }

  const guardarCambioFila = (fila) => {
    try {
      if (queDatos == "usuarios") {
        console.log(fila)
        axios
          .put(` /api/admin/cambioUsuario`, fila)
          .then((response) => {
            console.log(response)
            alert("Modificación de usuario guardada exitosamente, yupi!")
            mostrarUsuarios()
          }).catch((err) => {
            console.log(err)
            window.alert("No se pudo establecer la conexión con el servidor")
          })
      }
    } catch (err) {
      console.log(err)
      window.alert("No se pudo establecer la conexión con el servidor")
    }
  }

  const funcionCrear = (nuevoUser) => {

    nuevoUser.ups = nuevoUser.ups.split(",")
    console.log(nuevoUser.ups)
    axios
      .post(` /api/admin/nuevoUsuario`, nuevoUser)
      .then((response) => {

        console.log(response)
        if (response.status == 201) {
          alert("Creación de usuario exitosa, a descorchar!")
          mostrarUsuarios()
        }
      }).catch((err) => {
        console.log(err)
        window.alert("No se pudo establecer la conexión con el servidor")
      })
  }

  const guardarNuevo = (fila) => { }
  const eliminarFila = (fila) => { }


  const cargarProductosDesdeExcel = () => {
    axios
      .get(` /api/admin/popularProductosFitosanitarios`)
      .then((response) => {
        console.log(response)
        setResultado(response.data)
        setCrear(false)
      }).catch((err) => {
        console.log(err)
        window.alert("No se pudo establecer la conexión con el servidor")
      })

  }

  const mostrarProductos = () => {
    axios
      .get(` /api/admin/traerProductosFitosanitarios`)
      .then((response) => {
        console.log(response)
        setResultado(response.data)
        setCrear(false)
        setEditable(true)
        setQueDatos("productos")
      }).catch((err) => {
        console.log(err)
        window.alert("No se pudo establecer la conexión con el servidor")
      })

  }

  const cargarUpsDesdeExcel = () => {
    axios
      .get(` /api/admin/popularUPS`)
      .then((response) => {
        console.log(response)
        var aux = response.data.map(dato => ({ ups: dato.id, vinedos: dato.vinedos }));
        setResultado(aux)
        setEditable(false)
        setCrear(false)
        setQueDatos("ups")
      }).catch((err) => {
        console.log(err)
        window.alert("No se pudo establecer la conexión con el servidor")
      })

  }

  const mostrarUPS = () => {
    axios
      .get(` /api/admin/traerUPS`)
      .then((response) => {
        console.log(response)
        var aux = response.data.map(dato => ({ ups: dato.id, vinedos: dato.vinedos }));
        setResultado(aux)
        setEditable(false)
        setCrear(false)
        setQueDatos("ups")
      }).catch((err) => {
        console.log(err)
        window.alert("No se pudo establecer la conexión con el servidor")
      })
  }

  const mostrarUsuarios = () => {
    axios
      .get(` /api/admin/traerUsersParaAdmin`)
      .then((response) => {

        console.log(response)
        setResultado(response.data)
        setEditable(true)
        setCrear(true)
        setQueDatos("usuarios")
      }).catch((err) => {
        console.log(err)
        window.alert("No se pudo establecer la conexión con el servidor")
      })

  }

  const cargarVinedos = () => {

    setResultado([{ CARGANDODATOS: "CARGANDO DATOS" }])
    axios
      .get(`api/vinedos/getVinedosFromINAVI`)
      .then((response) => {
        console.log(response)
        setResultado(response.data)
        setEditable(false)
        setCrear(false)
        setQueDatos("vinedos")
      }).catch((err) => {
        console.log(err)
        console.log("aca error")
        window.alert("No se pudo establecer la conexión con el servidor")
      })
  }

  const verVinedos = () => {

    setResultado([{ CARGANDODATOS: "CARGANDO DATOS" }])
    axios
      .get(`api/vinedos/getVinedos`)
      .then((response) => {

        setResultado(response.data)
        setEditable(false)
        setCrear(false)
        setQueDatos("vinedos")
      }).catch((err) => {
        console.log(err)
        window.alert("No se pudo establecer la conexión con el servidor")
      })
  }


  return (
    
    <div title="Tablero Admin| Cuaderno Digital Vitícola" sx={{ backgroundColor: "#fcf1f2" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>


      <span id="menuno" style={{display:"block !important"}}>
        <Button size="large" sx={{ whiteSpace: "nowrap" }} id="basic-button1" aria-controls={open1 ? 'basic-menu1' : undefined} aria-haspopup="true" aria-expanded={open1 ? 'true' : undefined}
          onClick={handleClick1}
        >
          Productos Fitosanitarios
        </Button>
        <Menu id="basic-menu1" anchorEl={anchorEl1} open={open1} onClose={handleClose1} MenuListProps={{ 'aria-labelledby': 'basic-button1' }} sx={{ pb: 4 }}       >

          <MenuItem onClick={()=>{cargarProductosDesdeExcel; handleClose1}}>
          <Button size="large" sx={{ whiteSpace: "nowrap" }}>
              <Link
                href="https://docs.google.com/spreadsheets/d/1dUwDo_yd3t8Xi-gwEOEFw_rT0Yi6KyFqxxUB26IaK4M/edit#gid=1705146819
" target="_blank" rel="noopener noreferrer" >
                <Typography variant="h8">
                  Abrir Planilla con los Productos 
                </Typography>
              </Link>
              </Button>
          </MenuItem>

          <MenuItem onClick={handleClose1}>
            <Button size="large" sx={{ whiteSpace: "nowrap" }} onClick={cargarProductosDesdeExcel}>
              <Typography variant="h8" >
                Copiar Productos Fitosanitarios de la Planilla al Cuaderno
              </Typography>
            </Button>
          </MenuItem>

          <MenuItem onClick={handleClose1}>
            <Button size="large" sx={{ whiteSpace: "nowrap" }} onClick={mostrarProductos} >
              <Typography variant="h8" >
                Ver productos existentes en el Cuaderno
              </Typography>
            </Button>
          </MenuItem>

        </Menu>
      </span>

      <span id="menudos"> {/* UPS */}
        <Button size="large" sx={{ whiteSpace: "nowrap" }} id="basic-button2" aria-controls={open2 ? 'basic-menu2' : undefined} aria-haspopup="true" aria-expanded={open2 ? 'true' : undefined}
          onClick={handleClick2}>
          Viñedos y Unidades Productivas
        </Button>
        <Menu id="basic-menu2" anchorEl={anchorEl2} open={open2} onClose={handleClose2} MenuListProps={{
          'aria-labelledby': 'basic-button2',
        }} sx={{ pb: 4 }}
        >
          <MenuItem onClick={handleClose2}>
            <Button size="large" sx={{ whiteSpace: "nowrap" }}>
              <Link
                href="https://docs.google.com/spreadsheets/d/1xkKZP7N-RH7OW6YTNOH374Zj1GyAdVrQqZUCDfxp2xo/edit#gid=208431123" target="_blank" rel="noopener noreferrer" >
                <Typography variant="h8" >
                  Abrir Planilla con las UPS y Viñedos
                </Typography>
              </Link>
            </Button>
          </MenuItem>

          <MenuItem onClick={handleClose2}>
            <Button size="large" sx={{ whiteSpace: "nowrap" }} onClick={cargarUpsDesdeExcel} >
              <Typography variant="h8" >
                Copiar UPS y Viñedos de la Planilla al Cuaderno
              </Typography>
            </Button>
          </MenuItem>

          <MenuItem onClick={handleClose2}>
            <Button size="large" sx={{ whiteSpace: "nowrap" }} onClick={mostrarUPS} >
              <Typography variant="h8" >
             Ver UPS y Viñedos existentes en el Cuaderno
              </Typography>
            </Button>
          </MenuItem>
        </Menu>
      </span>

      <span id="menters"> {/* Usuarios */}
        <Button size="large" sx={{ whiteSpace: "nowrap" }} id="basic-button3" aria-controls={open3 ? 'basic-menu3' : undefined} aria-haspopup="true" aria-expanded={open3 ? 'true' : undefined}
          onClick={handleClick3}>
          Usuarios
        </Button>
        <Menu id="basic-menu3" anchorEl={anchorEl3} open={open3} onClose={handleClose3} MenuListProps={{
          'aria-labelledby': 'basic-button3',
        }} sx={{ pb: 4 }}
        >
          <MenuItem onClick={handleClose3}>
            <Button size="large" sx={{ whiteSpace: "nowrap" }} onClick={mostrarUsuarios} >
              <Typography variant="h8" >
              Gestionar Usuarios y sus UPs existentes en el Cuaderno
              </Typography>
            </Button>
          </MenuItem>
        </Menu>
      </span>

      <span id="menu4"> {/* Registro de viñedos */}
        <Button size="large" sx={{ whiteSpace: "nowrap" }} id="basic-button4" aria-controls={open4 ? 'basic-menu4' : undefined} aria-haspopup="true" aria-expanded={open4 ? 'true' : undefined}
          onClick={handleClick4}  >
          Registro de viñedos
        </Button>
        <Menu id="basic-menu4" anchorEl={anchorEl4} open={open4} onClose={handleClose4}
          MenuListProps={{ 'aria-labelledby': 'basic-button4' }} sx={{ pb: 4 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}


        >
          <MenuItem onClick={handleClose4}>
            <Button size="large" sx={{ whiteSpace: "nowrap" }} onClick={cargarVinedos} >
              <Typography variant="h8" >
                Copiar viñedos desde la base de INAVI al Cuaderno
              </Typography>
            </Button>
          </MenuItem>

          <MenuItem onClick={handleClose4}>
            <Button size="large" sx={{ whiteSpace: "nowrap" }} onClick={verVinedos} >
              <Typography variant="h8" >
                Ver viñedos de la App
              </Typography>
            </Button>
          </MenuItem>
        </Menu>
      </span>


      </div>


      <RootStyle title="Tablero Admin| Cuaderno Digital Vitícola" sx={{ backgroundColor: "#fcf1f2" }}>


      <Container>
        <Stack sx={{ mb: 5, mt: 5 }} >


          <Stack sx={{ mb: 1, mt: 3 }}>
            <Card sx={{ fontWeight: "bold" }}>


              {resultado.length > 0 ? <NuevaTabla tableData={resultado} updateTableData={updateTableData} editable={editable} guardarCambioFila={guardarCambioFila} guardarNuevo={guardarNuevo} eliminarFila={eliminarFila} crear={crear} funcionCrear={funcionCrear} /> : null}


            </Card>
          </Stack>
        </Stack>
      </Container>

      </RootStyle>

    </div >
  );
}
