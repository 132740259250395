import { useState } from "react";
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VinedosCuadrosSelector from "./VinedosCuadrosSelector";
import DatePickerField from "./DatePickerField";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import {Card} from "@mui/material";
import { Button } from "@mui/material";
// ----------------------------------------------------------------------
//React.forwardRef(({ onClick, href }, ref) => {
export default function HerbicidasForm({
  vinedosUnidad,
  selectedDate,
  mySelectedVinedosCuadros,
  enviarForm,
  enviadoExito
}) {
  //vinedos unidad no está funcionando
  const navigate = useNavigate();
  const { register, handleSubmit, errors, isSubmitting } = useForm();
  const [result, setResult] = useState("");
const [polvitoDelAmor, setPolvitoDelAmor] = useState(["1"]);

  const [enviando, setEnviando] = useState(false);

  const onSubmit = (data) => {
    if (!enviando) {
      
      if (!mySelectedVinedosCuadros.length > 0) {
        window.alert("debe seleccionar al menos un cuadro");
      } else {
        setEnviando(true);
        enviarForm(data);
      }
    }
  }; //setResult el que cambia el state


  const envio = () => {
    if (enviadoExito && enviando) {
      setEnviando(false);
      setTimeout(() => {
        navigate(`/dashboard/Herbicida`, { replace: true });
        reset()
      }, 3000);
    }
  };
  envio();


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <DatePickerField selectedDate={selectedDate} />

          {polvitoDelAmor.map((unPolvitoDelAmor, index) => {
            return(
            <Card key={index}>
              <TextField
                key={"ppoactivo" + index}
                fullWidth
                label="Principio Activo"
                {...register("principioActivo" + index)}
                placeholder="Principio Activo"
                required={true}
                //validateStatus={errors  ? "error" : "success"}
                help={
                  errors
                    ? "Este campo no puede estar vacío"
                    : ""
                }
              />
              <TextField
                key={"nombreComer" + index}
                fullWidth
                label="Nombre Comercial - Marca"
                {...register("nombreComercial" + index)}
                placeholder="Nombre Comercial"
                required={true}
                // validateStatus={errors && errors.nombreComercial ? "error" : "success"}
                help={
                  errors && errors.htmlText ? "Este campo no puede estar vacío" : ""
                }
              />

              <TextField

                key={"dosis" + index}
                fullWidth
                label="Concentración g ó cc cada 100 litros de agua"
                {...register("dosisPorHectarea" + index)}
                placeholder="Dósis por Hectárea"
                required={true}
                // validateStatus={errors && errors.dosis ? "error" : "success"}
                help={
                  errors && errors.dosis ? "Este campo no puede estar vacío" : ""
                }
              />
            </Card> )
          })}   

<Button onClick={() => setPolvitoDelAmor([...polvitoDelAmor, "1"])}>Agregar otro producto</Button>

          <TextField
            fullWidth
            label="Gasto de agua por Hectárea Tratada en Litros"
            {...register("gasto")}
            name="gasto"
            required={true}
            // validateStatus={errors && errors.dosis ? "error" : "success"}
            help={
              errors && errors.dosis ? "Este campo no puede estar vacío" : ""
            }
          />
          <TextField
            fullWidth
            label="Pulverizadora"
            {...register("pulverizadora")}
            name="pulverizadora"
            required={true}
            // validateStatus={errors && errors.picos ? "error" : "success"}
            help={
              errors && errors.pulverizado ? "Este campo no puede estar vacío" : ""
            }
          />
          <TextField
            fullWidth
            label="Tipo de Ficha"
            {...register("tipoFicha")}
            name="tipoFicha"
            required={true}
            // validateStatus={errors && errors.picos ? "error" : "success"}
            help={
              errors && errors.ficha ? "Este campo no puede estar vacío" : ""
            }
          />
          <TextField
            fullWidth
            label="Observaciones"
            {...register("observaciones")}
            placeholder="observaciones"

            // validateStatus={errors && errors.observaciones ? "error" : "success"}
            help={
              errors && errors.observaciones
                ? "Este campo no puede estar vacío"
                : ""
            }
          />
        </Stack>
        {/* */}
        <VinedosCuadrosSelector
          vinedosUnidad={vinedosUnidad}
          mySelectedVinedosCuadros={mySelectedVinedosCuadros}
        // ref={(input) => { this.VinCuadSel = input; }} 
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={enviando}
        >
          {!enviadoExito ?
            "Guardar Herbicida" : "Datos Guardados"}
        </LoadingButton>
      </Stack>
    </form>
  );
}
