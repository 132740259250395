
import { useEffect, useState } from "react";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
import { useNavigate } from 'react-router-dom';
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import ManejosCulturalesForm from "../components/forms/ManejosCulturalesForm";

import axios from "axios";

//import { Card } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function Manejo() {

  const navigate = useNavigate();
  const currentUser = window.localStorage.getItem("user");
  const selectedUP = window.localStorage.getItem("UP");

  const [enviadoExito, setEnviadoExito] = useState(false);

  useEffect(() => {
    if (currentUser == null) {
      navigate(`/login`, { replace: true });
    } else if (selectedUP == null) {
      navigate(`/unidades-productivas`, { replace: true });
    }
  }, []);

  function enviarForm(values) {

    if (mySelectedVinedosCuadros.length > 0) {

      var manejoSobrePlanta = new Array();
      values.poda ? manejoSobrePlanta.push("Poda") : null
      values.desbrotadoTronco ? manejoSobrePlanta.push("Desbrotado Tronco") : null
      values.deschuponado ? manejoSobrePlanta.push("Deschuponado") : null
      values.deshojado ? manejoSobrePlanta.push("Deshojado") : null
      values.roñadoLateral ? manejoSobrePlanta.push("Roñado Lateral") : null
      values.roñadoApical ? manejoSobrePlanta.push("Roñado Apical") : null
      values.raleoRacimos ? manejoSobrePlanta.push("Raleo de Racimos") : null

      var manejoSobreSuelo = new Array();
      values.pasteraYPicarama ? manejoSobreSuelo.push("Pastera y Picarama") : null
      values.laboreoEnLaEntrefila ? manejoSobreSuelo.push("Laboreo En La Entrefila") : null
      values.siembra ? manejoSobreSuelo.push("Siembra") : null
      values.controlMecánicoDeLasMalezasEnLaFila ? manejoSobreSuelo.push("Control Mecánico De Las Malezas En La Fila") : null

      var myObjectManejo = {
        idUnidadProductiva: selectedUP,
        fechaRegistro: theDate,
        cuadrosAplicados: mySelectedVinedosCuadros,
        manejoSobrePlanta: manejoSobrePlanta,
        manejoSobreSuelo: manejoSobreSuelo,
        observaciones: values.observaciones,
      };

      const request = JSON.parse(JSON.stringify(myObjectManejo));
      ;
      axios
        .post(
          ` /api/trabajos/manejo/nuevo`,
          request
        ).then((response) => {
          if (response.status == "201") {
            setEnviadoExito(true)
            setTimeout(() => {
              setEnviadoExito(false)
            }, 3000);
          } else {
            setEnviadoExito(false)
          }
        }).catch((err) => window.alert("No se pudo establecer la conexión con el servidor"))



    } else {
      // ver que hacer
    }
  }
  var theDate = new Date();
  const selectedDate = (aDate) => {
    theDate = aDate;
  };
  var mySelectedVinedosCuadros = new Array();

  return (
    <RootStyle title="Manejos del Viñedo| Cuaderno Digital Vitícola">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Registrar un nuevo trabajo
            </Typography>

          </Box>

          <ManejosCulturalesForm
            selectedDate={selectedDate}
            mySelectedVinedosCuadros={mySelectedVinedosCuadros}
            enviarForm={enviarForm}
            enviadoExito={enviadoExito}
          />
        </ContentStyle>
      </Container>
      <Card sx={{ maxWidth: "40%" }}>
        <Box sx={{ p: 5 }}>
          <Typography variant="body2">

            MANEJO DE LA ENTREFILA <br/>
            OBLIGATORIO 1 <br/>
            Se debe mantener las entrefilas empastadas, ya sea con empastado espontáneo o sembrado (gramíneas, leguminosas, etc.).
            En caso de realizar laboreos en la entrefila a los efectos de realizar una siembra o resiembra, con el objetivo de instalar o mejorar la cobertura vegetal; el mismo deberá realizarse en el periodo de poscosecha (febrero a mayo) y restringirse el período de suelo desnudo a un máximo de 30 días, dejando registro en el Cuaderno de Campo.
            La interrupción temporal del empastado en la entrefila solo podrá estar permitida en un único periodo dentro de la temporada y bajo las modalidades y condiciones que se especifican en la norma.
            No se permiten tratamientos fitosanitarios en el empastado sembrado.
            No se permite la fertilización química nitrogenada de las pasturas sembradas en viñedos con excesivo desarrollo vegetativo (en espaldera si el peso de poda es mayor a 0,65 kg/m de fila; en lira si es mayor a 1,20 Kg/m de fila; en parral si es mayor a 60 gr/brote).
            <br/>
            OBLIGATORIO 2 <br/>
            Se debe realizar un mantenimiento de la franja empastada (entrefila) rebajándola periódicamente mediante cortes o aplastado.
            <br/>
            QUIEBRE DE DORMANCIA <br/>
            OBLIGATORIO 1
            Se prohíben tratamientos con el fin de quiebre de dormancia excepto los siguientes: Compuestos nitrogenados+Nitrato de calcio, cianamida hidrogenada sola o con aceite (uso restringido en formación de nuevos cordones o renovación de los mismos. Para la poda a vara solo se permite su uso para la variedad Cabernet Sauvignon).
            <br/>
            MANEJO EN VERDE <br/>
            <br/>
            OBLIGATORIO 1
            <br/>
            Colocar los brotes en una correcta posición (empalizada)
            Desbrote de tronco
            RECOMENDADO
            Desbrote, deschuponado o desfeminelado
            deshojado manual, mecánico o térmico desde prefloracion a envero.
            GESTIÓN DEL RENDIMIENTO   <br/>
            <br/>
Raleo de racimos se permite durante todo el ciclo vegetativo. Definir el momento en función del vigor:
Viñedos con exceso de vigor realizar raleo entre cierre de racimo y envero. 
A menor vigor, se recomienda hacerlo más temprano.


          </Typography>
        </Box>
      </Card>



    </RootStyle>
  );
}
