import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import NuevoUsuario from "./pages/NuevoUsuario";
import DashboardApp from "./pages/DashboardApp";
import TratamientosSanitarios from "./pages/TratamientosSanitarios";
import Hormiguicida from "./pages/Hormiguicida";
import NotFound from "./pages/Page404";
import SeleccionUP from "./components/Viñedos/SeleccionUP";
import LluviayRiego from "./pages/LluviayRiego";
import InformeTecnico from "./pages/InformeTecnico"; 
import InformeCoordinador from "./pages/InformeCoordinador"; 
import IndiceEquilibrio from "./pages/IndiceEquilibrio";

import Herbicida from "./pages/Herbicidas"
import Fenologia from "./pages/Fenologia";
import Manejo from "./pages/Manejo";
import Fertilizante from "./pages/Fertilizante";
import Monitoreos from "./pages/Monitoreos";
import CalibracionEquipos from "./pages/CalibracionEquipos";
import LoginAdmin from "./pages/LoginAdmin";
import TableroAdmin from "./pages/TableroAdmin";
import ComposicionDeVinedos from "./pages/ComposicionDeVinedos";
import ReportesPage from "./pages/ReportesPage";
import HomeResumen from "./pages/HomeResumen";
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        
        { path: "homeResumen", element: <HomeResumen /> },
        { path: "reportesPage", element: <ReportesPage /> },
        { path: "hormiguicida", element: <Hormiguicida /> },
        { path: "tratamientossanitarios", element: <TratamientosSanitarios /> },
        { path: "herbicida", element: <Herbicida /> },
        { path: "fenologia", element: <Fenologia /> },
        { path: "manejo", element: <Manejo /> },
        { path: "fertilizante", element: <Fertilizante /> },        
        { path: "monitoreo", element: <Monitoreos /> },
        { path: "IndiceEquilibrio", element: <IndiceEquilibrio /> },
        { path: "LluviaYRiego", element: <LluviayRiego /> },  
        { path: "CalibracionEquipos", element: <CalibracionEquipos /> },               
        { path: "InformeTecnico", element: <InformeTecnico /> },       
        { path: "InformeCoordinador", element: <InformeCoordinador /> },  
        { path: "ComposicionDeVinedos", element: <ComposicionDeVinedos /> },               
        { path: "", element: <DashboardApp /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to="/login" replace /> },
        { path: "unidades-productivas", element: <SeleccionUP /> },
        { path: "login", element: <Login /> },
        { path: "nuevoUsuario", element: <NuevoUsuario /> },
        
        { path: "loginAdmin", element: <LoginAdmin /> },
        
        { path: "tableroAdmin", element: <TableroAdmin /> },
      /*  { path: "register", element: <Register /> },*/
      
        { path: "/", element: <Navigate to="/dashboard" /> },
        /*{ path: "404", element: <NotFound /> },*/
       /* { path: "*", element: <Navigate to="/404" /> }*/,
      ],
    },

   /* { path: "*", element: <Navigate to="/404" replace /> }*/,
  ]);
}
