import { Icon } from '@iconify/react';

import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import hormi from '@iconify/icons-twemoji/ant';

import PestControlIcon from '@mui/icons-material/PestControl';
import  {GoBug} from "react-icons/go/";
import  {GiDrop}  from "react-icons/gi";
import { MdOutlineDashboard } from "react-icons/md";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Resumen de Actividades',
    path: '/dashboard/homeResumen',
    icon: getIcon("fa-solid:list")
  },
  {
    title: 'Reportes',
    path: '/dashboard/reportesPage',
    icon: getIcon("fa-solid:table")
  },
  {
    title: 'Control de Hormigas',
    path: '/dashboard/hormiguicida',
    icon: getIcon(hormi)
  },
  
  {
    title: 'Tratamientos Sanitarios',
    path: '/dashboard/tratamientosSanitarios',
    icon: getIcon("fa-solid:bacteria")
  },
  {
    title: 'Herbicida',
    path: '/dashboard/Herbicida',
    icon: getIcon("mdi:grass")
  },
  {
    title: 'Fenología y Cosecha',
    path: '/dashboard/Fenologia',
    icon: getIcon("mdi:fruit-grapes")
  },
  {
    title: 'Manejo del Viñedo',
    path: '/dashboard/Manejo',
    icon: getIcon("typcn:scissors")
  },
  {
    title: 'Fertilizante',
    path: '/dashboard/Fertilizante',
    icon: getIcon("tabler:grain")
  },
  {
    title: 'Monitoreo',
    path: '/dashboard/Monitoreo',
    icon: getIcon("jam:bug")
  },
  {
    title: 'Lluvia y Riego',
    path: '/dashboard/LluviaYRiego',
    icon: getIcon("jam:water-drop")
  },
  {
    title: 'Indice de Equilibrio',
    path: '/dashboard/IndiceEquilibrio',
    icon: getIcon("fa-solid:balance-scale") 
  },
  {
    title: 'Calibración de Equipos',
    path: '/dashboard/CalibracionEquipos',
    icon: getIcon("mdi:tractor-variant")
  },
  {
    title: 'Composición de Viñedos',
    path: '/dashboard/ComposiciondeVinedos',
    icon: getIcon("ri:dashboard-line")
  },
  {
    title: 'Informe Técnico',
    path: '/dashboard/InformeTecnico',
    icon: getIcon("mdi:pen")
  },
  {
    title: 'Informe Coordinador',
    path: '/dashboard/InformeCoordinador',
    icon: getIcon("mdi:pencil")
  },

 /* {
    title: 'register',
    path: '/register',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon(alertTriangleFill)
  }*/
];

export default sidebarConfig;
