import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import NuevoUsuarioForm from "src/components/authentication/login/NuevoUsuarioForm";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Nuevo Usuario Cuaderno Digital Vitícola">
      {/* componente que pregunta si tienes cuenta y te lleva a registarte
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Get started
        </Link>
      </AuthLayout> */}

      {/* Componente de la imagen lateral */}
      <MHidden width="mdDown">
       
        <SectionStyle>
        
          <img src="/static/illustrations/logo.png" alt="tannat" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>             
            Bienvenido al Cuaderno Digital Vitícola
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Ingresa tu usuario, tu número de UP y tu nueva contraseña. <br />
              Si no recuerdas tu usuario o tu número de Unidad Productiva, contacta a INAVI.
            </Typography>
          </Stack>
          {/* Barra de medios sociales}
          <AuthSocial /> */}

          <NuevoUsuarioForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
